import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plan: "not selected",
  
}

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    addPlan: (state, action) => {
      state.plan = action.payload;
    },
    incrementByAmount: (state, action) => {
      state.plan += action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addPlan, incrementByAmount } = planSlice.actions;

export default planSlice.reducer;
