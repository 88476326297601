import React from "react";
import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";
import Chartpage from "../components/dashboardlanding/chartpage";

const Dashboardland = () => {
  return (
    <>
      <Navbar />
      <Chartpage />
      <Footer />
    </>
  );
};

export default Dashboardland;
