import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";
import Minussign from "../../assets/images/minus.svg";
import "./meetingoctaloop.scss";
import { Alert, Button, Dropdown, Form } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import StatusChart from "./StatusChart";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../app/context";
import DataDisplay from "./meetingoctaloop";

const MeetingAgenda = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const { user } = useContext(AuthContext);
  const [meetingName, setMeetingName] = useState("Meeting Name");
  const [isEditable, setIsEditable] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [statusCount, setStatusCount] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [data, setData] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [sheetTitles, setSheetTitles] = useState([]);
  const emailRef = useRef(null);

  const [sheetId, setSheetId] = useState("");

  const handleEditMeetingName = () => {
    setIsEditable(!isEditable);
  };
  const selectBoxStyle = {
    width: "100%",
    borderRadius: "11.165px",
    background: "var(--color-palette-neutral-05, #F3F3F3)",
    height: "47px",
    padding: "9.304px 14.887px",
    paddingRight: "120px", // corrected property name
    border: "none",
  };
  const getAllMembers = async () => {
    try {
      const resp = await axios.get(`${apiUrl}/members/${user.userId}`);
      setData(resp?.data?.members);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleEmailChange = (e) => {
    const selectedEmail = e.target.value;
    // Find the selected member from data array
    const selectedMember = data.find(
      (member) => member.email === selectedEmail
    );
    // Add the selected member to selectedMembers array
    if (selectedMember) {
      setSelectedMembers((prevState) => [
        ...prevState,
        {
          _id: selectedMember._id,
          userName: selectedMember.userName,
          email: selectedMember.email,
        },
      ]);
      setData((prevState) =>
        prevState.filter((member) => member._id !== selectedMember._id)
      );
    }
  };
  const handleChange = (event) => {
    setMeetingName(event.target.value);
  };

  const handleBlur = () => {
    setIsEditable(false);
  };

  const handleRemoveMember = (id) => {
    setSelectedMembers((prevState) =>
      prevState.filter((member) => member._id !== id)
    );
    const removedMember = selectedMembers.find((member) => member._id === id);
    if (removedMember) {
      setData((prevState) => [...prevState, removedMember]);
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/plan/user/${user?.userId}`);
      const data = await response.json();
      setPlans(data?.plans);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    getAllMembers();
  }, []);
  const handleSelect = async (plan) => {
    setSelectedPlan(plan);
    if (plan?._id) {
      const resp = await axios.get(
        // `http://localhost:3333/ganttest/getStatus/${plan?._id}`
        `${apiUrl}/ganttest/getStatus/${plan?._id}`
      );
      setStatusCount(resp?.data);
    }
  };

  const handleFetchTitles = async () => {
    try {
      if (sheetId !== "") {
        setFetchSuccess(false);
        const resp = await axios.post(
          // `http://localhost:3333/meeting/getSheetData`,
          `${apiUrl}/meeting/getSheetData`,
          { sheetId }
        );
        setSheetTitles(resp.data);
        // if (resp.status === 200) {
        setFetchSuccess(true);
        // }
      } else {
        return null;
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
      // setFetchSuccess(false);
      console.log(error.message);
    }
  };
  const controlCopyEmail = () => {
    navigator.clipboard.writeText(emailRef.current.value);
    toast.success("Copied");
  };
  return (
    <>
      <Navbar />
      <div className="meetingoctaloop">
        <div className="octaloop-content">
          <div className="content-header">
            <h6>
              {isEditable ? (
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={meetingName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                  <button
                    className="input-group-append btn btn-sm buttonAddStep add"
                    onClick={handleEditMeetingName}
                  >
                    save
                  </button>
                </div>
              ) : (
                <div className="d-flex gap-2">
                  <p onDoubleClick={handleEditMeetingName}>{meetingName}</p>
                  <FaEdit
                    cursor={PointerEvent}
                    onClick={handleEditMeetingName}
                  />
                </div>
              )}
            </h6>
          </div>
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle
              className="meetingAgendaDropdown"
              id="dropdown-autoclose-true"
            >
              {selectedPlan ? selectedPlan.planName : "Select plan"}
            </Dropdown.Toggle>

            <Dropdown.Menu className="meetingAgendadropdownMenu">
              {plans?.map((plan, index) => {
                return (
                  <>
                    <Dropdown.Item
                      className="meetingAgendaDropdownItem"
                      key={plan?._id}
                      onClick={() => handleSelect(plan)}
                    >
                      {plan?.planName}
                    </Dropdown.Item>

                    {index < plans.length - 1 && <Dropdown.Divider />}
                  </>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <div className="bottom-content mt-3">
            {statusCount[5]?.count > 0 ? (
              <StatusChart statusCount={statusCount} />
            ) : (
              <h2 className="mt-3 text-center">No task Added </h2>
            )}
          </div>
          <div className="content-firstsection mt-3">
            <div className="firstsection">
              <div className="leftside">
                <span>Add members</span>
              </div>
              <div className="rightside">
                <div className="inputfield">
                  <select
                    value={selectedMembers[selectedMembers.length - 1]?.email}
                    onChange={handleEmailChange}
                    style={selectBoxStyle}
                  >
                    <option value={"Select user email"} selected>
                      select user email
                    </option>
                    {data?.map((item) => (
                      <option key={item._id} value={item?.email}>
                        {item?.email}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="content-firstsection mt-3">
            <div className="firstsection">
              <div className="leftside">
                <span>Added members</span>
              </div>
              <div className="rightside">
                <div className="inputfield">
                  <table>
                    <tr>
                      <th>S.No</th>
                      <th>userName</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>

                    {selectedMembers?.map((item, index) => (
                      <tr key={item._id}>
                        <td>{index + 1}</td>
                        <td>{item.userName}</td>
                        <td>{item.email}</td>
                        <td>
                          <img
                            src={Minussign}
                            alt="..."
                            onClick={() => handleRemoveMember(item?._id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <Alert variant="success">
              <Alert.Heading>Important Notes:</Alert.Heading>
              <ol>
                <li>Open your Google Sheet.</li>
                <li>
                  Navigate to the right side of the sheet and locate the{" "}
                  <strong>Share</strong> button.
                </li>
                <li>Click on the Share button.</li>
                <li>
                  In the sharing settings, paste the link into the input box
                  provided.
                </li>
                <li>
                  Set the permission to <strong>Editor</strong> to allow full
                  access and editing capabilities.
                </li>
              </ol>
              <hr />
              <div className="d-flex justify-content-between gap-3">
                <Form className="w-full">
                  <Form.Group controlId="formIssueTitle">
                    <Form.Control
                      type="text"
                      value={
                        "readdatafromgooglesheet@sacred-vigil-415606.iam.gserviceaccount.com"
                      }
                      disabled
                      ref={emailRef}
                    />
                  </Form.Group>
                </Form>
                <Button variant="outline-success" onClick={controlCopyEmail}>
                  Copy
                </Button>
              </div>
            </Alert>
          </div>
          <div className="content-secondsection">
            <div className="firstsection">
              <div className="leftside">
                <span>Google Sheet Link</span>
              </div>
              <div className="rightside">
                <div className="inputfield">
                  <input
                    type="text"
                    placeholder="http//:"
                    onChange={(e) => {
                      setSheetId(e.target.value);
                    }}
                  />
                </div>
              </div>
              <button
                className="buttonAddStep"
                style={{ width: "8%" }}
                onClick={handleFetchTitles}
              >
                Fetch sheet
              </button>
            </div>
          </div>
          {fetchSuccess && (
            <DataDisplay initialSheetData={sheetTitles} sheetId={sheetId} />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MeetingAgenda;
