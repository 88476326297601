import React from "react";
import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";

const Startplan = () => {
  return (
    <>
      <Navbar />
      <div className="startplan">
        <div className="startplan-content">
          <div className="title">
            <h6>Strategic Plan </h6>
            <span>
              Start the process of filling up the business questionnaire
            </span>
            <div className="questionstart">
              {" "}
              <p>Let’s fill up the questionnaire now!</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Startplan;
