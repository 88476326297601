import React, { useContext, useEffect, useState } from "react";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";
import "./addteam.scss";
import Addtask from "../../assets/images/addtask.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthContext } from "../../app/context";

const Addteam = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const [email, setEmail] = useState("");
  const [members, setMembers] = useState([]);
  const [userCheckboxes, setUserCheckboxes] = useState({});
  const { user } = useContext(AuthContext);
  const handleCheckboxChange = (event, userId) => {
    const { name, checked } = event.target;
    setUserCheckboxes((prevState) => ({
      ...prevState,
      [userId]: {
        ...prevState[userId],
        [name]: checked,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = regex.test(email);
      if (!isValid) {
        toast.error("Invalid email");
      } else {
        try {
          const resp = await axios.post(`${apiUrl}/members/add-member`, {
            email,
            userId: user.userId,
          });
          // if(resp.data){
          toast.success("invitation link sent successfully");
          // }
        } catch (error) {
          toast.error(error.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllMembers = async () => {
    try {
      const resp = await axios.get(`${apiUrl}/members/${user.userId}`);

      setMembers(resp?.data?.members);

      // Update checkboxes state based on assigned plans of each member
      resp.data.members.forEach((member) => {
        const assignedPlans = member?.assignPlan || [];
        const updatedCheckboxesState = {
          checkbox1: assignedPlans.includes("90 days Plan"),
          checkbox2: assignedPlans.includes("Kanban Board"),
          checkbox3: assignedPlans.includes("One Page Plan"),
        };
        setUserCheckboxes((prevState) => ({
          ...prevState,
          [member._id]: updatedCheckboxesState,
        }));
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleAssignPlan = async (id) => {
    try {
      const resp = await axios.put(`${apiUrl}/members/assignPlan`, {
        assignPlan: userCheckboxes[id],
        userId: id,
      });
      // console.log(resp.data.member);
      if (resp.data.success) {
        toast.success(resp.data.message);
      }

      // setMembers(resp?.data?.members)
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllMembers();
  }, []);

  return (
    <>
      <Navbar />
      <div className="addteam-section">
        <div className="addteam-content">
          <div className="title">
            <div className="title-left">
              <h6>Add Team Members: </h6>
            </div>
            <div className="title-right">
              <input
                type="email"
                placeholder="Give your email @gmail.com"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="addtask" onClick={handleSubmit}>
                {" "}
                <img src={Addtask} alt=".." /> Add
              </button>
            </div>
          </div>
          <div className="team-table">
            <div className="table-content">
              <table>
                <thead>
                  <tr className="firstRow">
                    <th className="secondTh">ID</th>
                    <th className="thirdTh">Name</th>
                    <th className="thirdTh">Email</th>
                    <th className="fourthTh">Permissions</th>
                  </tr>
                </thead>
                <tbody>
                  {members?.map((item, index) => (
                    <tr className="secondRowP" key={item?._id}>
                      <td className="secondTh">{index + 1}.</td>
                      <td className="secondTh">{item?.userName}</td>
                      <td className="secondTh">{item?.email}</td>
                      <td className="secondTh">
                        <div className="last_div d-flex w-100">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`checkbox1_${item._id}`}
                              value="90 days plan"
                              name="checkbox1"
                              checked={
                                userCheckboxes[item._id]?.checkbox1 || false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(e, item._id)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`checkbox1_${item._id}`}
                            >
                              90 days plan
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`checkbox2_${item._id}`}
                              value="Kanban Board"
                              name="checkbox2"
                              checked={
                                userCheckboxes[item._id]?.checkbox2 || false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(e, item._id)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`checkbox2_${item._id}`}
                            >
                              Kanban Board
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`checkbox3_${item._id}`}
                              value="One page Plan"
                              name="checkbox3"
                              checked={
                                userCheckboxes[item._id]?.checkbox3 || false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(e, item._id)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`checkbox3_${item._id}`}
                            >
                              One Page Plan{" "}
                            </label>
                          </div>
                          <button
                            className="savePlanbtn"
                            onClick={() => handleAssignPlan(item?._id)}
                          >
                            <img src={Addtask} alt=".." /> Assign
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Addteam;
