import React, { useEffect, useState } from "react";
import { handleTaskBySVGMouseEvent } from "../../helpers/bar-helper";
import { isKeyboardEvent } from "../../helpers/other-helper";
import { TaskItem } from "../task-item/task-item";
import { Arrow } from "../other/arrow";

export const TaskGanttContent = ({
    tasks,
    dates,
    ganttEvent,
    selectedTask,
    rowHeight,
    columnWidth,
    timeStep,
    svg,
    taskHeight,
    arrowColor,
    arrowIndent,
    fontFamily,
    fontSize,
    rtl,
    setGanttEvent,
    setFailedTask,
    setSelectedTask,
    onDateChange,
    onProgressChange,
    onDoubleClick,
    onClick,
    onDelete,
}) => {
    // console.log(tasks);
    const point = svg?.current?.createSVGPoint();
    const [xStep, setXStep] = useState(0);
    const [initEventX1Delta, setInitEventX1Delta] = useState(0);
    const [isMoving, setIsMoving] = useState(false);

    // create xStep
    useEffect(() => {
        const dateDelta =
            dates[1].getTime() -
            dates[0].getTime() -
            dates[1].getTimezoneOffset() * 60 * 1000 +
            dates[0].getTimezoneOffset() * 60 * 1000;
        const newXStep = (timeStep * columnWidth) / dateDelta;
        setXStep(newXStep);
    }, [columnWidth, dates, timeStep]);

    useEffect(() => {
        const handleMouseMove = async (event) => {
            if (!ganttEvent.changedTask || !point || !svg?.current) return;
            event.preventDefault();

            point.x = event.clientX;
            const cursor = point.matrixTransform(
                svg?.current.getScreenCTM()?.inverse()
            );

            const { isChanged, changedTask } = handleTaskBySVGMouseEvent(
                cursor.x,
                ganttEvent.action,
                ganttEvent.changedTask,
                xStep,
                timeStep,
                initEventX1Delta,
                rtl
            );
            if (isChanged) {
                setGanttEvent({ action: ganttEvent.action, changedTask });
            }
        };

        const handleMouseUp = async (event) => {
            const { action, originalSelectedTask, changedTask } = ganttEvent;
            if (!changedTask || !point || !svg?.current || !originalSelectedTask)
                return;
            event.preventDefault();

            point.x = event.clientX;
            const cursor = point.matrixTransform(
                svg?.current.getScreenCTM()?.inverse()
            );
            const { changedTask: newChangedTask } = handleTaskBySVGMouseEvent(
                cursor.x,
                action,
                changedTask,
                xStep,
                timeStep,
                initEventX1Delta,
                rtl
            );

            const isNotLikeOriginal =
                originalSelectedTask.start !== newChangedTask.start ||
                originalSelectedTask.end !== newChangedTask.end ||
                originalSelectedTask.progress !== newChangedTask.progress;

            // remove listeners
            svg.current.removeEventListener("mousemove", handleMouseMove);
            svg.current.removeEventListener("mouseup", handleMouseUp);
            setGanttEvent({ action: "" });
            setIsMoving(false);

            // custom operation start
            let operationSuccess = true;
            if (
                (action === "move" || action === "end" || action === "start") &&
                onDateChange &&
                isNotLikeOriginal
            ) {
                try {
                    const result = await onDateChange(
                        newChangedTask,
                        newChangedTask.barChildren
                    );
                    if (result !== undefined) {
                        operationSuccess = result;
                    }
                } catch (error) {
                    operationSuccess = false;
                }
            } else if (onProgressChange && isNotLikeOriginal) {
                try {
                    const result = await onProgressChange(
                        newChangedTask,
                        newChangedTask.barChildren
                    );
                    if (result !== undefined) {
                        operationSuccess = result;
                    }
                } catch (error) {
                    operationSuccess = false;
                }
            }

            // If operation is failed - return old state
            if (!operationSuccess) {
                setFailedTask(originalSelectedTask);
            }
        };

        if (
            !isMoving &&
            (ganttEvent.action === "move" ||
                ganttEvent.action === "end" ||
                ganttEvent.action === "start" ||
                ganttEvent.action === "progress") &&
            svg?.current
        ) {
            svg.current.addEventListener("mousemove", handleMouseMove);
            svg.current.addEventListener("mouseup", handleMouseUp);
            setIsMoving(true);
        }
    }, [
        ganttEvent,
        xStep,
        initEventX1Delta,
        onProgressChange,
        timeStep,
        onDateChange,
        svg,
        isMoving,
        point,
        rtl,
        setFailedTask,
        setGanttEvent,
    ]);

    /**
     * Method is Start point of task change
     */
    const handleBarEventStart = async (
        action,
        task,
        event
    ) => {
        if (!event) {
            if (action === "select") {
                setSelectedTask(task.id);
            }
        }
        // Keyboard events
        else if (isKeyboardEvent(event)) {
            if (action === "delete") {
                if (onDelete) {
                    try {
                        const result = await onDelete(task);
                        if (result !== undefined && result) {
                            setGanttEvent({ action, changedTask: task });
                        }
                    } catch (error) {
                        console.error("Error on Delete. " + error);
                    }
                }
            }
        }
        // Mouse Events
        else if (action === "mouseenter") {
            if (!ganttEvent.action) {
                setGanttEvent({
                    action,
                    changedTask: task,
                    originalSelectedTask: task,
                });
            }
        } else if (action === "mouseleave") {
            if (ganttEvent.action === "mouseenter") {
                setGanttEvent({ action: "" });
            }
        } else if (action === "dblclick") {
            !!onDoubleClick && onDoubleClick(task);
        } else if (action === "click") {
            !!onClick && onClick(task);
        }
        // Change task event start
        else if (action === "move") {
            if (!svg?.current || !point) return;
            point.x = event.clientX;
            const cursor = point.matrixTransform(
                svg.current.getScreenCTM()?.inverse()
            );
            setInitEventX1Delta(cursor.x - task.x1);
            setGanttEvent({
                action,
                changedTask: task,
                originalSelectedTask: task,
            });
        } else {
            setGanttEvent({
                action,
                changedTask: task,
                originalSelectedTask: task,
            });
        }
    };

    let z = 150
    let planName = tasks[0]?.planName;
    let goalName = tasks[0]?.goalName

    // console.log(tasks);
    return (
        <g className="content">
            <g className="arrows" fill={arrowColor} stroke={arrowColor}>
                {tasks.map(task => {
                    return task.barChildren.map(child => {
                        return (
                            <Arrow
                                key={`Arrow from ${task.id} to ${tasks[child.index].id}`}
                                taskFrom={task}
                                taskTo={tasks[child.index]}
                                rowHeight={rowHeight}
                                taskHeight={taskHeight}
                                arrowIndent={arrowIndent}
                                rtl={rtl}
                            />
                        );
                    });
                })}
            </g>
            <g className="bar" fontFamily={fontFamily} fontSize={fontSize}>
                {tasks.map(task => {
                    if (task.planName === planName) {
                        if (task.goalName === goalName) {
                            z = z
                        } else {
                            z += 150;
                            goalName = task.goalName
                        }
                    } else {
                        z += 200;
                        planName = task.planName
                        goalName = task.goalName
                    }
                    return (
                        <TaskItem
                            z={z}
                            task={task}
                            arrowIndent={arrowIndent}
                            taskHeight={taskHeight}
                            isProgressChangeable={!!onProgressChange && !task.isDisabled}
                            isDateChangeable={!!onDateChange && !task.isDisabled}
                            isDelete={!task.isDisabled}
                            onEventStart={handleBarEventStart}
                            key={task.id}
                            isSelected={!!selectedTask && task.id === selectedTask.id}
                            rtl={rtl}
                        />
                    );
                })}
            </g>
        </g>
    );
};
