import React, { useState, useEffect, useRef, useContext } from "react";
import Toggle2 from "../../shared/Toggle/Toggle2";
import Toggle3 from "../../shared/Toggle/Toggle3";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { PlanContext } from "../../../app/context";

const Generic = ({
  data,
  status,
  category,
  title,
  activeTab,
  setActiveTab,
  find,
  setfetch,
  allAnswers,
  setAllAnswers,
  foundedAnswer,
  setFoundedAnswer,
  inputValues,
  setInputValues,
  formDataState,
  setFormData,
  values,
  setValues,
  searchParams,
  setSearchParams,
  setActiveTabInDb,
  activeTabinDb,
  // fetchData
}) => {
  const { plan } = useContext(PlanContext);
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  // const apiUrl = "http://localhost:3333";
  const inputRefs = useRef({});
  const [parentQuestions, setParentQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [show, setShow] = useState(false);
  const [specificQuestionKey, setSpecificQuestionKey] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const [answer, setAnswer] = useState({});
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(false);
  const [changeQuestion, setChangeQuestion] = useState(1);
  const [selectedSwot, setSelectedSwot] = useState({});

  const previousbtn = () => {
    const currentTabNumber = parseInt(activeTab.slice(3), 10);
    const nextTabNumber = currentTabNumber - 1;

    if (nextTabNumber >= 1) {
      const nextTab = `tab${nextTabNumber}`;
      setSearchParams({ activeTab: nextTab });
      setActiveTab(nextTab);
    }
  };
  const nextbtn = () => {
    localStorage.removeItem("formDataState");
    const currentTabNumber = parseInt(activeTab.slice(3), 10);
    const nextTabNumber = currentTabNumber + 1;
    const nextTab = `tab${nextTabNumber}`;
    setSearchParams({ activeTab: nextTab });
    if (
      Number(nextTab.slice(3, nextTab.length)) >
      Number(activeTabinDb.slice(3, activeTabinDb.length))
    ) {
      setActiveTabInDb(nextTab);
    }

    setActiveTab(nextTab);

    if (activeTab === "tab30") {
      navigate("/result");
    }
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const resp = await axios.get(`${apiUrl}/input/${plan._id}`)

  //       setInputValues(resp.data)
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   }
  //   fetchData()
  // }, [activeTab])

  const handleAddInput = (parentQuestionId, questionData) => {
    // console.log(questionData)

    const {
      key = "",
      question = "",
      comment_yes = "",
      comment_no = "",
      comment_third = "",
      comment_fourth = "",
      swot = "",
      qNo = 999,
      category = "",
      section = "",
    } = questionData;
    setInputValues((prevState) => ({
      ...prevState,
      [parentQuestionId]: [
        ...(prevState[parentQuestionId] || []),
        {
          answer: "",
          key,
          question,
          comment_yes,
          comment_no,
          comment_third,
          comment_fourth,
          swot,
          category,
          section,
          qNo,
        },
      ], // Add an empty string as a new input value
    }));
    setTimeout(() => {
      if (inputRefs.current[parentQuestionId]) {
        const newIndex = (inputRefs.current[parentQuestionId]?.length || 1) - 1;
        inputRefs.current[parentQuestionId][newIndex]?.focus();
      }
    }, 0);
  };

  // Function to handle input value changes
  // const handleInputChangee = (parentQuestionId, inputId, value) => {
  //   setInputValues(prevState => ({
  //     ...prevState,
  //     [parentQuestionId]: prevState[parentQuestionId].map((item, index) =>
  //       index === inputId ? value : item
  //     )
  //   }));
  // };
  const handleInputChangee = (parentQuestionId, inputId, answer) => {
    setInputValues((prevState) => {
      const updatedParentQuestion = (prevState[parentQuestionId] || []).map(
        (item, index) => (index === inputId ? { ...item, answer } : item)
      );
      return {
        ...prevState,
        [parentQuestionId]: updatedParentQuestion,
      };
    });
  };

  // Function to handle deleting input fields
  const handleDeleteInput = async (parentQuestionId, inputIndex) => {
    console.log(parentQuestionId, inputIndex);
    setInputValues((prevState) => ({
      ...prevState,
      [parentQuestionId]: prevState[parentQuestionId].filter(
        (item, index) => index !== inputIndex
      ),
    }));
    const dataObj = {
      key: parentQuestionId,
      index: inputIndex,
      planId: plan?._id,
    };
    try {
      const resp = await axios.post(`${apiUrl}/input/delete`, dataObj);
    } catch (error) {
      console.log(error.message);
    }
  };

  // useEffect(() => {
  //   console.log("The plan is:", plan);
  // }, [plan]);
  // const filterAnswer = (key) => {
  //   if (foundedAnswer && foundedAnswer.answer.length > 0) {
  //     const answer = foundedAnswer.answer.filter((e) => e.key === key);
  //     return answer;
  //   } else {
  //   }
  // };
  // console.log(foundedAnswer);

  useEffect(() => {
    if (foundedAnswer && foundedAnswer[0].answer) {
      foundedAnswer?.map((e) => {
        setAllAnswers((prev) => ({
          ...prev,
          [e.key]: {
            answer: e.answer,
            swot: e.swot,
          },
        }));
      });
    }
  }, [foundedAnswer]);

  useEffect(() => {
    if (
      answer &&
      answer.specificQuestion &&
      answer.specificQuestion[0] &&
      answer.specificQuestion[0].key
    ) {
      // console.log(Object.values(answer)[0])
      if (
        Object.values(answer)[0] === "yes" ||
        Object.values(answer)[0] === "no" ||
        Object.values(answer)[0] === "notNeeded"
      ) {
        setAllAnswers((prev) => ({
          ...prev,
          [answer.specificQuestion[0].key]: {
            answer: answer.answer || answer.Answer,
            type: answer.specificQuestion[0].type,
            type1: answer.specificQuestion[0].type1,
          },
        }));
        handleSubmit();
      }

      // handleSubmit();
    }
  }, [answer]);

  // useEffect(() => {
  //   // if (
  //   //   parentQuestions.length > 0 &&
  //   //   parentQuestions[0].key === "living_it_purpose"
  //   // ) {
  //   //   setParentQuestions([...parentQuestions].reverse());
  //   // }

  //   if (parentQuestions.type === 'yes' || parentQuestions.type === 'no' && parentQuestions.length > 0) {

  //     window.scrollTo(0, 0);
  //   }

  // }, [parentQuestions]);

  useEffect(() => {
    if (data) {
      // const PESTLE_ORDER = [
      //   "Political",
      //   "Economic:",
      //   "Social:",
      //   "Technological:",
      //   "Legal:",
      //   "Environmental:",
      //   "opportunities4",
      //   "Threats:"
      // ];
      // const newQuestion = PESTLE_ORDER.map((category) =>
      //   data.filter((e) => e.key === category)
      //     .map((question) => ({
      //       ...question,
      //       // category,
      //     }))
      // ).flat();

      // if (data[0].category !== "Pestle Analysis") {
      setParentQuestions(data.filter((e) => e.type));
      // } else {
      //   setParentQuestions(newQuestion);
      // }
    }
  }, [data]);

  // useEffect(() => {
  //   const findAnswer = async () => {
  //     setLoading(true)

  //     try {
  //       const response = await fetch(
  //         `${apiUrl}/answer/findAnswer`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({ category: find, plan: plan._id }),
  //         }
  //       );

  //       if (response.ok) {
  //         const data = await response.json();

  //         setFormData(data?.answer)
  //         setFoundedAnswer(data);
  //         // setLoading(false)
  //         // window.scrollTo({
  //         //   top: document.documentElement.scrollHeight,
  //         //   left: 0,
  //         //   behavior: 'smooth'
  //         // });

  //         const ValueData = data?.answer?.filter(d => d.comment_yes === "We successfully live the value of ")

  //         if (ValueData?.length > 0) {
  //           setValues(ValueData)
  //         }
  //         return data;
  //       } else {
  //         throw new Error("Error retrieving answer");
  //       }
  //     } catch (error) {
  //       console.error("An error occurred:", error.message);
  //     }
  //   };
  //   findAnswer(); // Call the function
  // }, [changeQuestion, activeTab]);
  let strengthCount = 0;
  let weaknessCount = 0;
  const handleAnswerChange = (question, value) => {
    const specificQuestion = questionsByKeys(question);
    if (!specificQuestion) {
      console.error(`No question found for key ${question}.`);
      return; // Exit early to avoid further errors
    }
    let swot = "";
    if (specificQuestion[0].swot !== "") {
      swot = specificQuestion[0].swot;
    } else if (selectedSwot[specificQuestion[0]?._id] !== undefined) {
      swot = selectedSwot[specificQuestion[0]?._id];
    }
    setAnswer((prevAnswers) => ({
      ...prevAnswers,
      Answer: value,
      Swot: swot,
      specificQuestion,
      //"section":singleQuestion.section
    }));

    setAllAnswers((prev) => ({
      ...prev,
      [specificQuestion[0].key]: {
        answer: value,
        type: specificQuestion[0].type,
      },
    }));
  };

  const handleToggleChange = (questionKey, selectedOption) => {
    const specificQuestion = data.filter((e) => e.key === questionKey);
    // console.log(specificQuestion)

    if (selectedOption === "notNeeded") {
      setAnswer((prevAnswers) => ({
        ...prevAnswers,
        Answer: selectedOption,
        Swot: specificQuestion[0].swot_third,
        specificQuestion,
      }));
    }
    if (selectedOption === "yes") {
      setAnswer((prevAnswers) => ({
        ...prevAnswers,
        Answer: selectedOption,
        Swot: specificQuestion[0].swot_yes || "",
        specificQuestion,
      }));
    }
    if (selectedOption === "no") {
      setAnswer((prevAnswers) => ({
        ...prevAnswers,
        Answer: selectedOption,
        Swot: specificQuestion[0].swot_no || "",
        specificQuestion,
      }));
    }
  };

  function organizeInDependentKeys(questions) {
    const inDependentKeysByQuestion = {};

    questions?.forEach((question) => {
      const inDependentKeys = [];

      for (let i = 1; i <= 5; i++) {
        const inDependentKey = question[`independent${i}`];
        if (inDependentKey) {
          inDependentKeys.push(inDependentKey);
        }
      }

      inDependentKeysByQuestion[question.key] = inDependentKeys;
    });

    return inDependentKeysByQuestion;
  }

  function organizeDependentKeys(questions) {
    const dependentKeysByQuestion = {};

    questions?.forEach((question) => {
      const dependentKeys = [];
      for (let i = 1; i <= 15; i++) {
        const dependentKey = question[`dependent${i}`];
        if (dependentKey) {
          dependentKeys.push(dependentKey);
        }
      }

      dependentKeysByQuestion[question.key] = dependentKeys;
    });

    return dependentKeysByQuestion;
  }

  const dependentKeysByQuestion = organizeDependentKeys(parentQuestions);
  const inDependentKeysByQuestion = organizeInDependentKeys(parentQuestions);

  const questionsByKeys = (keys) => {
    const matchingQuestions = [];
    const keyArray = Array.isArray(keys) ? keys : [keys];
    if (data?.length > 0) {
      for (const question of data) {
        if (keyArray.includes(question.key)) {
          matchingQuestions.push(question);
        }
      }
    }

    return matchingQuestions.length > 0 ? matchingQuestions : null;
  };

  const allQuestions = {};

  for (const key in dependentKeysByQuestion) {
    const values = dependentKeysByQuestion[key];
    for (const value of values) {
      if (!allQuestions[key]) {
        allQuestions[key] = [];
      }
      allQuestions[key] = allQuestions[key].concat(questionsByKeys(value));
    }
  }
  //Independentkeys
  const allIndependentQuestion = {};
  for (const key in inDependentKeysByQuestion) {
    const values = inDependentKeysByQuestion[key];
    for (const value of values) {
      if (!allIndependentQuestion[key]) {
        allIndependentQuestion[key] = [];
      }
      allIndependentQuestion[key] = allIndependentQuestion[key].concat(
        questionsByKeys(value)
      );
    }
  }

  const formDataSetterFunction = (formData) => {
    // console.log(formData)
    setFormData((prevSelected) => {
      prevSelected = Array.isArray(prevSelected) ? prevSelected : [];
      const existingIndex = prevSelected.findIndex(
        (card) => card?.key === formData.key
      );
      if (existingIndex !== -1) {
        prevSelected[existingIndex] = formData;
        return prevSelected.slice();
      } else {
        return [...prevSelected, formData];
      }
    });
    setFoundedAnswer((prevSelected) => {
      prevSelected = Array.isArray(prevSelected) ? prevSelected : [];
      const existingIndex = prevSelected.findIndex(
        (card) => card?.key === formData.key
      );
      if (existingIndex !== -1) {
        prevSelected[existingIndex] = formData;
        return prevSelected.slice();
      } else {
        return [...prevSelected, formData];
      }
    });
  };
  const handleSubmit = () => {
    if (!answer || !answer.specificQuestion) {
      console.error("Answer or specificQuestion is undefined.");
      return;
    }

    const formData = {
      section: answer.specificQuestion[0].section,
      category: answer.specificQuestion[0].category,
      question: answer.specificQuestion[0].question,
      answer: Object.values(answer)[0],
      input: answer.specificQuestion[0].input || false,
      // swot_yes: answer.specificQuestion[0].swot_yes,
      // swot_no: answer.specificQuestion[0].swot_no,
      // swot_third: answer.specificQuestion[0].swot_third,
      // swot_fourth: answer.specificQuestion[0].swot_fourth,
      comment_fourth: answer.specificQuestion[0].comment_fourth,
      comment_yes: answer.specificQuestion[0].comment_yes,
      comment_no: answer.specificQuestion[0].comment_no,
      comment_third: answer.specificQuestion[0].comment_third,
      model: answer.specificQuestion[0].model,
      key: answer.specificQuestion[0].key,
      swot: answer.Swot,
      qNo: answer?.specificQuestion[0]?.qNo,
      questionType: answer.specificQuestion[0].questionType,
      plan: plan?._id,
    };

    formDataSetterFunction(formData);

    // try {
    //   const result = await createAnswer(formData);
    //   // console.log("Answer stored successfully:", result);
    // } catch (error) {
    //   console.error("Error storing answer:", error.message);
    // }
    // console.log("THe form Data: ", formData)
  };

  const handleSaveAnswer = async () => {
    const filteredData = formDataState?.filter(
      (item) => item?.category === find
    );
    const notSwotCount = filteredData?.filter(
      (item) => item?.swot === "" && item?.questionType === "AddedQuestion"
    );
    console.log(notSwotCount);
    if (notSwotCount?.length > 0) {
      toast.info("Please Select S, W, O, T for Added Question");
      return;
    }
    nextbtn();
    if (filteredData?.length > 0) {
      try {
        const response = await fetch(`${apiUrl}/answer/createAnswer`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(filteredData),
        });
        if (response.ok) {
          const data = await response.json();
          return data;
        } else {
          throw new Error("Error storing answer");
        }
      } catch (error) {
        console.error("Internal server error:", error.message);
        // throw new Error("Error storing answer");
      }
    }
  };
  // Add Question
  const handleQuestion = async () => {
    if (newQuestion === "") {
      toast.error("please Add question");
      return;
    }
    const formData = {
      question: newQuestion,
      section: data[0].section,
      category: data[0].category,
      type: "none",
      input: selectedOption || true,
      plan: plan,
      qNo: 2003,
      questionType: "AddedQuestion",
      comment_yes: " ",
      comment_no: " ",
      comment_third: " ",
      comment_fourth: " ",
    };

    try {
      const response = await fetch(`${apiUrl}/question`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      handleClose();
      if (response.ok) {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          left: 0,
          behavior: "smooth",
        });

        const newQuestionData = await response.json();

        // setAddedQuestion((prevAddedQuestions) => [
        //   ...(Array.isArray(prevAddedQuestions) ? prevAddedQuestions : []),
        //   formData,
        // ]);
        setfetch((e) => e + 1);
        setChangeQuestion((prev) => prev + 1);
        handleClose();
        setNewQuestion("");
      } else {
        // throw new Error("Error storing question");
      }
    } catch (error) {
      console.error("Internal server error:", error.message);
      // throw new Error("Error storing question");
    }
  };
  const elderQuestions = data?.filter((e) => e.dependentAll === true);

  const handleDelete = async (key, id) => {
    try {
      console.log(key, id);
      const resp = await axios.delete(`${apiUrl}/question/${key}`);
      const res = await axios.delete(`${apiUrl}/input/${id}/${plan._id}`);
    } catch (error) {
      console.log(error.message);
    } finally {
      setfetch((e) => e + 1);
    }
  };

  for (const key in answer) {
    if (answer.hasOwnProperty(key)) {
      if (answer.Swot === "Strength") {
        strengthCount++;
      }
      if (answer.Swot === "Weakness") {
        weaknessCount++;
      }
    }
  }

  // values section

  const handleAddValue = () => {
    const newValues = [
      ...values,
      {
        section: "What We Stand For",
        category: "Values",
        // question: `Enter your Values. ${values.length + 1}`,
        question: `Enter your Values.`,
        swot_yes: "Strength",
        swot_no: "Weakness",
        // swot_third: "",
        // swot_fourth: "",
        comment_fourth: "",
        comment_yes: "We successfully live the value of ",
        comment_no: "We do not successfully live the value of ",
        comment_third: "",
        // model: "AnalysisValue",
        key: `values_not_living_it${values.length + 1}`,
        input: true,
        swot: "Strength",
        answer: "",
        isChecked: null,
        qNo: 525 + values.length + 1,
        plan: plan?._id,
      },
    ];
    setValues(newValues);
  };
  const handleChange = (valueIndex, newText) => {
    const updatedValues = values.map((value, index) =>
      index === valueIndex ? { ...value, answer: newText } : value
    );
    setValues(updatedValues);
  };

  const handleRadioChange = (valueIndex, isChecked) => {
    const updatedValues = values.map((value, index) =>
      index === valueIndex
        ? {
            ...value,
            isChecked: isChecked,
            swot: isChecked ? "Strength" : "Weakness",
          }
        : value
    );
    setValues(updatedValues);
  };

  const filterByCategory = (dataArray, comment_yes) => {
    // console.log(dataArray, comment_yes)
    return dataArray.filter((item) => item.comment_yes !== comment_yes);
  };

  const handleDeleteValue = async (val, valueIndex) => {
    const filtered = filterByCategory(
      formDataState,
      "We successfully live the value of "
    );
    // console.log(filtered)
    setFormData(filtered);
    const filteredValues = values.filter(
      (value, index) => index !== valueIndex
    );
    setValues(filteredValues);
    if (val?._id) {
      const res = await axios.delete(`${apiUrl}/answer/val/${val?._id}`);
    }
  };
  // console.log(formDataState)

  // Function to handle saving the input field values to the modal
  const handleSaveToModal = async () => {
    // Iterate over inputValues and save them to the modal
    try {
      const resp = await axios.post(
        `${apiUrl}/input/create/${plan?._id}/${activeTab}`,
        inputValues
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSaveValues = async (value) => {
    try {
      const response = await fetch(`${apiUrl}/answer/createAnswer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Error storing answer");
      }
    } catch (error) {
      console.error("Internal server error:", error.message);
      // throw new Error("Error storing answer");
    }
  };
  //Function to store user values in the db
  const handleSaveValue = () => {
    if (
      values?.length > 0 &&
      values[0]?.answer !== "" &&
      category === "Our Values"
    ) {
      handleSaveValues(values);
    }
  };
  const handleSwotOptionChange = (question, value) => {
    console.log(question, value);

    setSelectedSwot({ ...selectedSwot, [question?._id]: value });
    setFormData(
      formDataState.map((item) => {
        if (item.key === question.key) {
          return { ...item, swot: value };
        }
        return item;
      })
    );
    setFoundedAnswer(
      formDataState.map((item) => {
        if (item.key === question.key) {
          return { ...item, swot: value };
        }
        return item;
      })
    );
  };
  // console.log(formDataState);

  const notShowKey = [
    "purpose_statement",
    "vision_statement",
    "mission_statement",
    "purpose",
    "csat_score",
    "staff_level",
    "team_productivity_percentage",
  ];
  // console.log(elderQuestions)
  // console.log(allAnswers)
  // console.log(foundedAnswer)
  // console.log(allQuestions["distinguish_segments"])
  // console.log(allQuestions)
  // console.log(parentQuestions)
  // console.log(data);
  // console.log(plan)
  // console.log(category)
  // console.log(allIndependentQuestion)
  // console.log(formDataState)
  // console.log(formDataState.length)

  return (
    <>
      {
        // loading ? <div class="spinner"></div> :
        data && data.length > 0 && (
          <>
            <div className="Purpose">
              <div className="purposeContent">
                <div className="purposeTitle">
                  <h6>{title}</h6>
                  {/* <hr /> */}
                  <span>
                    {/* {data.category} */}
                    {category}
                  </span>
                </div>
                {category === "Additional Threat & Opportunities" && (
                  <div>
                    <hr />
                    <p>
                      In addition to the specific areas of business, there are
                      often dynamics in the external environment that can affect
                      the business and its operations: Technological change,
                      Mergers/acquisitions, Legislative or regulatory change,
                      changes in the competitive landscape, in the state of the
                      economy, in labour markets, in the supply chain, in
                      interest rates, in capital availability, in customer
                      behavior/preferences and more. Below, identify any
                      external circumstances, locally or beyond, that either
                      present opportunities for you & your business, or that
                      pose threats:
                    </p>
                    <hr />
                    <h4 className="text-center mb-2 purposeTitle">
                      Pestle Analysis
                    </h4>
                    <hr />
                    <p>
                      Identify trends, developments or changes in each of these
                      areas that might effect your business:
                    </p>
                  </div>
                )}
                {plan !== null && plan !== "not selected" ? (
                  <div className="purposeField">
                    <div className="FieldContent">
                      {elderQuestions?.length > 0 && (
                        <div className="firstfield">
                          <span>{elderQuestions[0]?.question}</span>
                          <Toggle2
                            setSpecificQuestionKey={setSpecificQuestionKey}
                            questionKey={elderQuestions[0].key}
                            onToggleChange={handleToggleChange}
                            foundedAnswer={foundedAnswer}
                          />
                        </div>
                      )}
                      {allQuestions["has_quality"] &&
                        allQuestions["has_quality"][0] &&
                        allQuestions["has_quality"][0].category ===
                          "Selling Physical Goods" && (
                          <>
                            <div className="purposebutton">
                              <div className="buttndiv">
                                <button className="save" onClick={nextbtn}>
                                  Skip
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      {elderQuestions?.length > 0 ? (
                        <>
                          <div>
                            {parentQuestions &&
                            parentQuestions.length > 0 &&
                            elderQuestions[0].question &&
                            allAnswers[elderQuestions[0]?.key]?.answer
                              ? parentQuestions?.map(
                                  (parentQuestion, parentIndex) => {
                                    return (
                                      <div key={parentIndex}>
                                        {/* {console.log("The Parent Question:", parentQuestion)} */}
                                        {allAnswers &&
                                          allAnswers[parentQuestion?.key]
                                            ?.answer === parentQuestion?.type &&
                                          parentQuestion?.type &&
                                          allQuestions[
                                            parentQuestion?.key
                                          ]?.map(
                                            (childQuestion, childIndex) => {
                                              return childQuestion?.input &&
                                                childQuestion?.key !==
                                                  "how_deliver" &&
                                                childQuestion?.key !==
                                                  "missing_part" &&
                                                childQuestion?.key !==
                                                  "not_living_it" &&
                                                childQuestion?.key !==
                                                  "not_living_it_purpose" ? (
                                                <>
                                                  {childQuestion?.hr ===
                                                    "true" && (
                                                    <>
                                                      <hr />
                                                      <p className="text-center fw-bold">
                                                        {childQuestion?.title}
                                                      </p>
                                                      <hr />
                                                    </>
                                                  )}
                                                  {childQuestion?.comment_yes !==
                                                    "Our Values has been achieved as follows:" && (
                                                    <>
                                                      <div
                                                        className="secondField mt-3"
                                                        key={childIndex}
                                                      >
                                                        <span>
                                                          {
                                                            childQuestion.question
                                                          }
                                                        </span>
                                                        <br />
                                                        <div className="borderTextfield">
                                                          <input
                                                            type="text"
                                                            value={
                                                              allAnswers[
                                                                childQuestion
                                                                  ?.key
                                                              ]?.answer
                                                            }
                                                            placeholder="Type here"
                                                            className="textField mb-3"
                                                            onChange={(e) => {
                                                              handleAnswerChange(
                                                                childQuestion?.key,
                                                                e.target.value
                                                              );
                                                            }}
                                                            onBlur={
                                                              handleSubmit
                                                            }
                                                          />
                                                        </div>
                                                        {!notShowKey.includes(
                                                          childQuestion.key
                                                        ) && (
                                                          <>
                                                            {inputValues[
                                                              childQuestion?._id
                                                            ]?.map(
                                                              (
                                                                inputValue,
                                                                inputIndex
                                                              ) => (
                                                                <div
                                                                  key={
                                                                    inputIndex
                                                                  }
                                                                  className="d-flex gap-5 mb-2"
                                                                >
                                                                  <input
                                                                    className="newInputBox"
                                                                    ref={(
                                                                      input
                                                                    ) => {
                                                                      if (
                                                                        !inputRefs
                                                                          .current[
                                                                          childQuestion
                                                                            ._id
                                                                        ]
                                                                      ) {
                                                                        inputRefs.current[
                                                                          childQuestion._id
                                                                        ] = [];
                                                                      }
                                                                      inputRefs.current[
                                                                        childQuestion._id
                                                                      ][
                                                                        inputIndex
                                                                      ] = input;
                                                                    }}
                                                                    key={
                                                                      inputIndex
                                                                    }
                                                                    type="text"
                                                                    value={
                                                                      inputValue?.answer
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleInputChangee(
                                                                        childQuestion._id,
                                                                        inputIndex,
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                  />

                                                                  <button
                                                                    style={{
                                                                      backgroundColor:
                                                                        "red",
                                                                      color:
                                                                        "white",
                                                                      width:
                                                                        "40px",
                                                                      borderRadius:
                                                                        "3px",
                                                                    }}
                                                                    onClick={() =>
                                                                      handleDeleteInput(
                                                                        childQuestion._id,
                                                                        inputIndex
                                                                      )
                                                                    }
                                                                  >
                                                                    <RiDeleteBin6Line size="30px" />
                                                                  </button>
                                                                </div>
                                                              )
                                                            )}
                                                            {childQuestion.category ===
                                                              "USP" &&
                                                            childQuestion.question ===
                                                              "What is your USP/What are your USP's?" ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#327EB9",
                                                                    paddingRight:
                                                                      "140px",
                                                                  }}
                                                                >
                                                                  click add for
                                                                  each USP
                                                                </span>
                                                                <div className="addAnswer-btn mt-2">
                                                                  <button
                                                                    onClick={() => {
                                                                      if (
                                                                        allAnswers[
                                                                          childQuestion
                                                                            ?.key
                                                                        ] &&
                                                                        allAnswers[
                                                                          childQuestion
                                                                            ?.key
                                                                        ]
                                                                          ?.answer !==
                                                                          ""
                                                                      ) {
                                                                        handleAddInput(
                                                                          childQuestion._id,
                                                                          childQuestion
                                                                        );
                                                                      } else {
                                                                        toast.info(
                                                                          "First filled the answer"
                                                                        );
                                                                        // <span style={{ marginLeft: "10px", color: "red" }}>First filled the answer</span>
                                                                      }
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    Add Answer
                                                                  </button>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <div className="addAnswer-btn mt-2 d-flex justify-content-end">
                                                                <button
                                                                  onClick={() => {
                                                                    if (
                                                                      allAnswers[
                                                                        childQuestion
                                                                          ?.key
                                                                      ] &&
                                                                      allAnswers[
                                                                        childQuestion
                                                                          ?.key
                                                                      ]
                                                                        ?.answer !==
                                                                        ""
                                                                    ) {
                                                                      handleAddInput(
                                                                        childQuestion._id,
                                                                        childQuestion
                                                                      );
                                                                    } else {
                                                                      toast.info(
                                                                        "First filled the answer"
                                                                      );
                                                                      // <span style={{ marginLeft: "10px", color: "red" }}>First filled the answer</span>
                                                                    }
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  Add Answer
                                                                </button>
                                                              </div>
                                                            )}
                                                          </>
                                                        )}
                                                      </div>
                                                    </>
                                                  )}
                                                  {childQuestion?.question ===
                                                    "Enter your Values." && (
                                                    <div className="secondField mt-3">
                                                      <h6>Enter Your Values</h6>
                                                      <div>
                                                        {values?.map(
                                                          (
                                                            value,
                                                            valueIndex
                                                          ) => (
                                                            <div
                                                              key={valueIndex}
                                                              className="mt-3"
                                                            >
                                                              <div className="d-flex justify-content-between mb-3">
                                                                <textarea
                                                                  className="form-control"
                                                                  rows="1"
                                                                  style={{
                                                                    width:
                                                                      "95%",
                                                                  }}
                                                                  placeholder={`Value ${
                                                                    valueIndex +
                                                                    1
                                                                  }`}
                                                                  value={
                                                                    value.answer
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChange(
                                                                      valueIndex,
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                ></textarea>
                                                                {valueIndex >
                                                                  0 && (
                                                                  <div className="d-flex align-items-center">
                                                                    <button
                                                                      className="btn btn-danger"
                                                                      onClick={() =>
                                                                        handleDeleteValue(
                                                                          value,
                                                                          valueIndex
                                                                        )
                                                                      }
                                                                    >
                                                                      Delete
                                                                    </button>
                                                                  </div>
                                                                )}
                                                              </div>
                                                              <div className="d-flex justify-content-between align-items-center">
                                                                <p>
                                                                  Is it lived in
                                                                  your business?
                                                                </p>
                                                                <div className="d-flex gap-5">
                                                                  <div className="d-flex align-items-center gap-2 ">
                                                                    <label
                                                                      className="mr-3"
                                                                      htmlFor={`radio-${valueIndex}`}
                                                                    >
                                                                      Yes
                                                                    </label>
                                                                    <input
                                                                      c
                                                                      type="radio"
                                                                      name={`radio-${valueIndex}`}
                                                                      checked={
                                                                        value.isChecked ===
                                                                          true ||
                                                                        value?.swot ===
                                                                          "Strength"
                                                                      }
                                                                      onChange={() =>
                                                                        handleRadioChange(
                                                                          valueIndex,
                                                                          true
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                  <div className="d-flex align-items-center gap-2 ">
                                                                    <label
                                                                      className="mr-3"
                                                                      htmlFor={`radio-${valueIndex}`}
                                                                    >
                                                                      No
                                                                    </label>
                                                                    <input
                                                                      className="custom-radio"
                                                                      type="radio"
                                                                      name={`radio-${valueIndex}`}
                                                                      checked={
                                                                        value.isChecked ===
                                                                          false ||
                                                                        value?.swot ===
                                                                          "Weakness"
                                                                      }
                                                                      onChange={() =>
                                                                        handleRadioChange(
                                                                          valueIndex,
                                                                          false
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <hr />
                                                            </div>
                                                          )
                                                        )}
                                                        <div className="d-flex justify-content-between mt-3 addAnswer-btn">
                                                          <p className="text-primary">
                                                            Click Add for each
                                                            new value
                                                          </p>

                                                          <button
                                                            className=""
                                                            onClick={
                                                              handleAddValue
                                                            }
                                                          >
                                                            Add new value
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {childQuestion &&
                                                    childQuestion?.key !==
                                                      "how_deliver" &&
                                                    childQuestion?.key !==
                                                      "missing_part" &&
                                                    childQuestion?.key !==
                                                      "not_living_it" &&
                                                    childQuestion?.key !==
                                                      "not_living_it_purpose" && (
                                                      <div
                                                        className="firstfield mt-3"
                                                        key={childIndex}
                                                      >
                                                        <span>
                                                          {
                                                            childQuestion?.question
                                                          }
                                                        </span>
                                                        {childQuestion?.notNeeded ===
                                                          "true" ||
                                                        childQuestion?.notKnow ===
                                                          "true" ||
                                                        childQuestion?.notApplicable ===
                                                          "true" ? (
                                                          <Toggle3
                                                            setSpecificQuestionKey={
                                                              setSpecificQuestionKey
                                                            }
                                                            questionKey={
                                                              childQuestion?.key
                                                            }
                                                            onToggleChange={
                                                              handleToggleChange
                                                            }
                                                            foundedAnswer={
                                                              foundedAnswer
                                                            }
                                                            question={
                                                              childQuestion
                                                            }
                                                          />
                                                        ) : (
                                                          <Toggle2
                                                            setSpecificQuestionKey={
                                                              setSpecificQuestionKey
                                                            }
                                                            questionKey={
                                                              childQuestion?.key
                                                            }
                                                            onToggleChange={
                                                              handleToggleChange
                                                            }
                                                            foundedAnswer={
                                                              foundedAnswer
                                                            }
                                                          />
                                                        )}
                                                      </div>
                                                    )}
                                                  {allAnswers &&
                                                    allAnswers[
                                                      childQuestion?.key
                                                    ]?.answer ===
                                                      childQuestion?.type &&
                                                    allQuestions[
                                                      childQuestion?.key
                                                    ]?.map(
                                                      (
                                                        grandChild,
                                                        gChildIndex
                                                      ) => {
                                                        return (
                                                          <>
                                                            <div
                                                              className="secondField mt-3"
                                                              key={gChildIndex}
                                                            >
                                                              <span>
                                                                {
                                                                  grandChild?.question
                                                                }
                                                              </span>
                                                              <br />
                                                              <div className="borderTextfield">
                                                                <input
                                                                  type="text"
                                                                  value={
                                                                    allAnswers[
                                                                      grandChild
                                                                        ?.key
                                                                    ]?.answer
                                                                  }
                                                                  placeholder="Type here"
                                                                  className="textField mb-3"
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAnswerChange(
                                                                      grandChild?.key,
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                  onBlur={
                                                                    handleSubmit
                                                                  }
                                                                />
                                                              </div>
                                                              {!notShowKey.includes(
                                                                grandChild?.key
                                                              ) && (
                                                                <>
                                                                  {inputValues[
                                                                    grandChild
                                                                      ._id
                                                                  ]?.map(
                                                                    (
                                                                      inputValue,
                                                                      inputIndex
                                                                    ) => (
                                                                      <div
                                                                        key={
                                                                          inputIndex
                                                                        }
                                                                        className="d-flex gap-5 mb-2"
                                                                      >
                                                                        <input
                                                                          className="newInputBox"
                                                                          ref={(
                                                                            input
                                                                          ) => {
                                                                            if (
                                                                              !inputRefs
                                                                                .current[
                                                                                grandChild
                                                                                  ._id
                                                                              ]
                                                                            ) {
                                                                              inputRefs.current[
                                                                                grandChild._id
                                                                              ] =
                                                                                [];
                                                                            }
                                                                            inputRefs.current[
                                                                              grandChild._id
                                                                            ][
                                                                              inputIndex
                                                                            ] =
                                                                              input;
                                                                          }}
                                                                          key={
                                                                            inputIndex
                                                                          }
                                                                          type="text"
                                                                          value={
                                                                            inputValue?.answer
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleInputChangee(
                                                                              grandChild._id,
                                                                              inputIndex,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                        />
                                                                        <button
                                                                          style={{
                                                                            backgroundColor:
                                                                              "red",
                                                                            color:
                                                                              "white",
                                                                            width:
                                                                              "40px",
                                                                            borderRadius:
                                                                              "3px",
                                                                          }}
                                                                          onClick={() =>
                                                                            handleDeleteInput(
                                                                              grandChild._id,
                                                                              inputIndex
                                                                            )
                                                                          }
                                                                        >
                                                                          <RiDeleteBin6Line size="30px" />
                                                                        </button>
                                                                      </div>
                                                                    )
                                                                  )}

                                                                  <div className="addAnswer-btn mt-2 d-flex justify-content-end">
                                                                    {/* <button onClick={() => handleAddInput(grandChild._id, grandChild)}>Add Answer</button> */}
                                                                    <button
                                                                      onClick={() => {
                                                                        if (
                                                                          allAnswers[
                                                                            grandChild
                                                                              ?.key
                                                                          ] &&
                                                                          allAnswers[
                                                                            grandChild
                                                                              ?.key
                                                                          ]
                                                                            ?.answer !==
                                                                            ""
                                                                        ) {
                                                                          handleAddInput(
                                                                            grandChild._id,
                                                                            grandChild
                                                                          );
                                                                        } else {
                                                                          toast.info(
                                                                            "First filled the answer"
                                                                          );
                                                                          // <span style={{ marginLeft: "10px", color: "red" }}>First filled the answer</span>
                                                                        }
                                                                      }}
                                                                    >
                                                                      {" "}
                                                                      Add Answer
                                                                    </button>
                                                                  </div>
                                                                </>
                                                              )}
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              );
                                            }
                                          )}
                                      </div>
                                    );
                                  }
                                )
                              : null}
                          </div>
                          <div>
                            {parentQuestions &&
                              parentQuestions.length > 0 &&
                              parentQuestions.map(
                                (parentQuestion, parentIndex) => {
                                  return (
                                    <React.Fragment key={parentIndex}>
                                      {parentQuestion.plan === plan._id &&
                                        parentQuestion.questionType ===
                                          "AddedQuestion" && (
                                          <div key={parentIndex}>
                                            {parentQuestion.input === true ? (
                                              <div className="secondField mt-3">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <span>
                                                    {parentQuestion.question}
                                                  </span>
                                                  <div className="d-flex gap-2">
                                                    <div className="container d-flex gap-1">
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Strength"
                                                        >
                                                          Strength
                                                        </label>
                                                        <input
                                                          type="radio"
                                                          name={`swot-${parentQuestion?._id}`}
                                                          className="form-check-input"
                                                          id={`Strength-${parentQuestion?._id}`}
                                                          value="Strength"
                                                          defaultChecked
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] === "Strength" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot ===
                                                              "Strength"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Strength"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Opportunity"
                                                        >
                                                          Weakness
                                                        </label>
                                                        <input
                                                          type="radio"
                                                          name={`swot-${parentQuestion?._id}`}
                                                          className="form-check-input"
                                                          id={`Weakness-${parentQuestion?._id}`}
                                                          value="Weakness"
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] === "Weakness" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot ===
                                                              "Weakness"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Weakness"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Opportunity"
                                                        >
                                                          Opportunity
                                                        </label>
                                                        <input
                                                          name={`swot-${parentQuestion?._id}`}
                                                          type="radio"
                                                          className="form-check-input"
                                                          id={`Opportunity-${parentQuestion?._id}`}
                                                          value="Opportunity"
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] ===
                                                              "Opportunity" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot ===
                                                              "Opportunity"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Opportunity"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Threat"
                                                        >
                                                          Threat
                                                        </label>
                                                        <input
                                                          name={`swot-${parentQuestion?._id}`}
                                                          type="radio"
                                                          className="form-check-input"
                                                          id={`Threat-${parentQuestion?._id}`}
                                                          value="Threat"
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] === "Threat" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot === "Threat"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Threat"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <p
                                                      style={{
                                                        color: "red",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        handleDelete(
                                                          parentQuestion.key,
                                                          parentQuestion._id
                                                        )
                                                      }
                                                    >
                                                      X
                                                    </p>
                                                  </div>
                                                </div>

                                                <div className="borderTextfield">
                                                  <input
                                                    type="text"
                                                    placeholder="Type here"
                                                    className="textField mb-3"
                                                    value={
                                                      allAnswers[
                                                        parentQuestion?.key
                                                      ]?.answer
                                                    }
                                                    onChange={(e) => {
                                                      handleAnswerChange(
                                                        parentQuestion.key,
                                                        e.target.value
                                                      );
                                                    }}
                                                    onBlur={handleSubmit}
                                                  />
                                                </div>
                                                {/* {!notShowKey.includes(childQuestion.key) && (
                                              <> */}
                                                {inputValues[
                                                  parentQuestion._id
                                                ]?.map(
                                                  (inputValue, inputIndex) => (
                                                    <div
                                                      key={inputIndex}
                                                      className="d-flex gap-5 mb-2"
                                                    >
                                                      <input
                                                        className="newInputBox"
                                                        key={inputIndex}
                                                        ref={(input) => {
                                                          if (
                                                            !inputRefs.current[
                                                              parentQuestion._id
                                                            ]
                                                          ) {
                                                            inputRefs.current[
                                                              parentQuestion._id
                                                            ] = [];
                                                          }
                                                          inputRefs.current[
                                                            parentQuestion._id
                                                          ][inputIndex] = input;
                                                        }}
                                                        type="text"
                                                        value={
                                                          inputValue?.answer
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangee(
                                                            parentQuestion._id,
                                                            inputIndex,
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                      <button
                                                        style={{
                                                          backgroundColor:
                                                            "red",
                                                          color: "white",
                                                          width: "40px",
                                                          borderRadius: "3px",
                                                        }}
                                                        onClick={() =>
                                                          handleDeleteInput(
                                                            parentQuestion._id,
                                                            inputIndex
                                                          )
                                                        }
                                                      >
                                                        <RiDeleteBin6Line size="30px" />
                                                      </button>
                                                    </div>
                                                  )
                                                )}
                                                {parentQuestion?.questionType !==
                                                  "AddedQuestion" && (
                                                  <div className="addAnswer-btn mt-2 d-flex justify-content-end">
                                                    {/* <button onClick={() => handleAddInput(parentQuestion._id, parentQuestion)}>Add Answer</button> */}
                                                    <button
                                                      onClick={() => {
                                                        if (
                                                          allAnswers[
                                                            parentQuestion?.key
                                                          ] &&
                                                          allAnswers[
                                                            parentQuestion?.key
                                                          ]?.answer !== ""
                                                        ) {
                                                          handleAddInput(
                                                            parentQuestion._id,
                                                            parentQuestion
                                                          );
                                                        } else {
                                                          toast.info(
                                                            "First filled the answer"
                                                          );
                                                          // <span style={{ marginLeft: "10px", color: "red" }}>First filled the answer</span>
                                                        }
                                                      }}
                                                    >
                                                      {" "}
                                                      Add Answer
                                                    </button>
                                                  </div>
                                                )}
                                                {/* </>
                                            )} */}
                                              </div>
                                            ) : (
                                              <div className="firstfield mt-3">
                                                <span>
                                                  {parentQuestion.question}
                                                </span>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                  }}
                                                >
                                                  <Toggle2
                                                    setSpecificQuestionKey={
                                                      setSpecificQuestionKey
                                                    }
                                                    questionKey={
                                                      parentQuestion.key
                                                    }
                                                    onToggleChange={
                                                      handleToggleChange
                                                    }
                                                    foundedAnswer={
                                                      foundedAnswer
                                                    }
                                                  />
                                                  {allAnswers[
                                                    parentQuestion?.key
                                                  ]?.answer && (
                                                    <div
                                                      className="container d-flex gap-1"
                                                      style={{ width: "62%" }}
                                                    >
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Strength"
                                                        >
                                                          Strength
                                                        </label>
                                                        <input
                                                          type="radio"
                                                          name={`swot-${parentQuestion?._id}`}
                                                          className="form-check-input"
                                                          id={`Strength-${parentQuestion?._id}`}
                                                          value="Strength"
                                                          defaultChecked
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] === "Strength" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot ===
                                                              "Strength"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Strength"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Opportunity"
                                                        >
                                                          Weakness
                                                        </label>
                                                        <input
                                                          type="radio"
                                                          name={`swot-${parentQuestion?._id}`}
                                                          className="form-check-input"
                                                          id={`Weakness-${parentQuestion?._id}`}
                                                          value="Weakness"
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] === "Weakness" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot ===
                                                              "Weakness"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Weakness"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Opportunity"
                                                        >
                                                          Opportunity
                                                        </label>
                                                        <input
                                                          name={`swot-${parentQuestion?._id}`}
                                                          type="radio"
                                                          className="form-check-input"
                                                          id={`Opportunity-${parentQuestion?._id}`}
                                                          value="Opportunity"
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] ===
                                                              "Opportunity" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot ===
                                                              "Opportunity"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Opportunity"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Threat"
                                                        >
                                                          Threat
                                                        </label>
                                                        <input
                                                          name={`swot-${parentQuestion?._id}`}
                                                          type="radio"
                                                          className="form-check-input"
                                                          id={`Threat-${parentQuestion?._id}`}
                                                          value="Threat"
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] === "Threat" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot === "Threat"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Threat"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                  <p
                                                    style={{
                                                      color: "red",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleDelete(
                                                        parentQuestion.key,
                                                        parentQuestion._id
                                                      )
                                                    }
                                                  >
                                                    X
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                          </div>
                        </>
                      ) : (
                        <div>
                          <div>
                            {parentQuestions &&
                              parentQuestions.length > 0 &&
                              parentQuestions.map(
                                (parentQuestion, parentIndex) => (
                                  <div key={parentIndex}>
                                    {parentQuestion.input === true ? (
                                      parentQuestion?.question ==
                                        "Do you have the right number of staff to deliver the current level of work you are experiencing?" &&
                                      (allAnswers["have_enough_staff"]
                                        .answer === "yes" ||
                                        allAnswers["have_enough_staff"]
                                          ?.answer === "no") ? (
                                        <></>
                                      ) : (
                                        <>
                                          {parentQuestion.plan === plan._id ? (
                                            <div className="secondField mt-3">
                                              {parentQuestion.questionType ===
                                              "AddedQuestion" ? (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <span>
                                                    {parentQuestion.question}
                                                  </span>
                                                  <div className="d-flex gap-2">
                                                    <div className="container d-flex gap-1">
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Strength"
                                                        >
                                                          Strength
                                                        </label>
                                                        <input
                                                          type="radio"
                                                          name={`swot-${parentQuestion?._id}`}
                                                          className="form-check-input"
                                                          id={`Strength-${parentQuestion?._id}`}
                                                          value="Strength"
                                                          defaultChecked
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] === "Strength" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot ===
                                                              "Strength"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Strength"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Opportunity"
                                                        >
                                                          Weakness
                                                        </label>
                                                        <input
                                                          type="radio"
                                                          name={`swot-${parentQuestion?._id}`}
                                                          className="form-check-input"
                                                          id={`Weakness-${parentQuestion?._id}`}
                                                          value="Weakness"
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] === "Weakness" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot ===
                                                              "Weakness"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Weakness"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Opportunity"
                                                        >
                                                          Opportunity
                                                        </label>
                                                        <input
                                                          name={`swot-${parentQuestion?._id}`}
                                                          type="radio"
                                                          className="form-check-input"
                                                          id={`Opportunity-${parentQuestion?._id}`}
                                                          value="Opportunity"
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] ===
                                                              "Opportunity" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot ===
                                                              "Opportunity"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Opportunity"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="form-check">
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="Threat"
                                                        >
                                                          Threat
                                                        </label>
                                                        <input
                                                          name={`swot-${parentQuestion?._id}`}
                                                          type="radio"
                                                          className="form-check-input"
                                                          id={`Threat-${parentQuestion?._id}`}
                                                          value="Threat"
                                                          checked={
                                                            selectedSwot[
                                                              parentQuestion
                                                                ?._id
                                                            ] === "Threat" ||
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.swot === "Threat"
                                                          }
                                                          onChange={() =>
                                                            handleSwotOptionChange(
                                                              parentQuestion,
                                                              "Threat"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <p
                                                      style={{
                                                        color: "red",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        handleDelete(
                                                          parentQuestion.key,
                                                          parentQuestion._id
                                                        )
                                                      }
                                                    >
                                                      X
                                                    </p>
                                                  </div>
                                                </div>
                                              ) : (
                                                <span>
                                                  {parentQuestion.question}
                                                </span>
                                              )}

                                              <div className="borderTextfield">
                                                <input
                                                  type="text"
                                                  placeholder="Type here"
                                                  className="textField mb-3"
                                                  value={
                                                    allAnswers[
                                                      parentQuestion?.key
                                                    ]?.answer
                                                  }
                                                  onChange={(e) =>
                                                    handleAnswerChange(
                                                      parentQuestion.key,
                                                      e.target.value
                                                    )
                                                  }
                                                  onBlur={handleSubmit}
                                                />
                                              </div>
                                              {!notShowKey.includes(
                                                parentQuestion.key
                                              ) && (
                                                <>
                                                  {inputValues[
                                                    parentQuestion._id
                                                  ]?.map(
                                                    (
                                                      inputValue,
                                                      inputIndex
                                                    ) => (
                                                      <div
                                                        key={inputIndex}
                                                        className="d-flex gap-5 mb-2"
                                                      >
                                                        <input
                                                          className="newInputBox"
                                                          ref={(input) => {
                                                            if (
                                                              !inputRefs
                                                                .current[
                                                                parentQuestion
                                                                  ._id
                                                              ]
                                                            ) {
                                                              inputRefs.current[
                                                                parentQuestion._id
                                                              ] = [];
                                                            }
                                                            inputRefs.current[
                                                              parentQuestion._id
                                                            ][inputIndex] =
                                                              input;
                                                          }}
                                                          key={inputIndex}
                                                          type="text"
                                                          value={
                                                            inputValue?.answer
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChangee(
                                                              parentQuestion._id,
                                                              inputIndex,
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                        <button
                                                          style={{
                                                            backgroundColor:
                                                              "red",
                                                            color: "white",
                                                            width: "40px",
                                                            borderRadius: "3px",
                                                          }}
                                                          onClick={() =>
                                                            handleDeleteInput(
                                                              parentQuestion._id,
                                                              inputIndex
                                                            )
                                                          }
                                                        >
                                                          <RiDeleteBin6Line size="30px" />
                                                        </button>
                                                      </div>
                                                    )
                                                  )}

                                                  {/* Button to add new input field */}
                                                  {parentQuestion?.questionType !==
                                                    "AddedQuestion" && (
                                                    <div className="addAnswer-btn mt-2 d-flex justify-content-end">
                                                      {/* <button onClick={() => handleAddInput(parentQuestion._id, parentQuestion)}>Add Answer</button> */}
                                                      <button
                                                        onClick={() => {
                                                          if (
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ] &&
                                                            allAnswers[
                                                              parentQuestion
                                                                ?.key
                                                            ]?.answer !== ""
                                                          ) {
                                                            handleAddInput(
                                                              parentQuestion._id,
                                                              parentQuestion
                                                            );
                                                          } else {
                                                            toast.info(
                                                              "First filled the answer"
                                                            );
                                                            // <span style={{ marginLeft: "10px", color: "red" }}>First filled the answer</span>
                                                          }
                                                        }}
                                                      >
                                                        {" "}
                                                        Add Answer
                                                      </button>
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          ) : (
                                            parentQuestion.questionType !==
                                              "AddedQuestion" && (
                                              <>
                                                {parentQuestion?.hr ===
                                                  "true" && (
                                                  <>
                                                    <hr />
                                                    <p className="text-center fw-bold">
                                                      {parentQuestion?.title}
                                                    </p>
                                                    <hr />
                                                  </>
                                                )}
                                                <div className="secondField mt-3">
                                                  <span>
                                                    {parentQuestion.question}
                                                  </span>
                                                  <br />
                                                  <div className="borderTextfield">
                                                    <input
                                                      type="text"
                                                      placeholder="Type here"
                                                      className="textField mb-3"
                                                      value={
                                                        allAnswers[
                                                          parentQuestion?.key
                                                        ]?.answer
                                                      }
                                                      onChange={(e) => {
                                                        handleAnswerChange(
                                                          parentQuestion.key,
                                                          e.target.value
                                                        );
                                                      }}
                                                      onBlur={handleSubmit}
                                                    />
                                                  </div>
                                                  {!notShowKey.includes(
                                                    parentQuestion.key
                                                  ) && (
                                                    <>
                                                      {inputValues[
                                                        parentQuestion._id
                                                      ]?.map(
                                                        (
                                                          inputValue,
                                                          inputIndex
                                                        ) => (
                                                          <div
                                                            key={inputIndex}
                                                            className="d-flex gap-5 mb-2"
                                                          >
                                                            <input
                                                              className="newInputBox"
                                                              ref={(input) => {
                                                                if (
                                                                  !inputRefs
                                                                    .current[
                                                                    parentQuestion
                                                                      ._id
                                                                  ]
                                                                ) {
                                                                  inputRefs.current[
                                                                    parentQuestion._id
                                                                  ] = [];
                                                                }
                                                                inputRefs.current[
                                                                  parentQuestion._id
                                                                ][inputIndex] =
                                                                  input;
                                                              }}
                                                              key={inputIndex}
                                                              type="text"
                                                              value={
                                                                inputValue?.answer
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChangee(
                                                                  parentQuestion._id,
                                                                  inputIndex,
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                            <button
                                                              style={{
                                                                backgroundColor:
                                                                  "red",
                                                                color: "white",
                                                                width: "40px",
                                                                borderRadius:
                                                                  "3px",
                                                              }}
                                                              onClick={() =>
                                                                handleDeleteInput(
                                                                  parentQuestion._id,
                                                                  inputIndex
                                                                )
                                                              }
                                                            >
                                                              <RiDeleteBin6Line size="30px" />
                                                            </button>
                                                          </div>
                                                        )
                                                      )}

                                                      {/* Button to add new input field */}
                                                      <div className="addAnswer-btn mt-2 d-flex justify-content-end">
                                                        <button
                                                          onClick={() => {
                                                            if (
                                                              allAnswers[
                                                                parentQuestion
                                                                  ?.key
                                                              ] &&
                                                              allAnswers[
                                                                parentQuestion
                                                                  ?.key
                                                              ]?.answer !== ""
                                                            ) {
                                                              handleAddInput(
                                                                parentQuestion._id,
                                                                parentQuestion
                                                              );
                                                            } else {
                                                              toast.info(
                                                                "First filled the answer"
                                                              );
                                                              // <span style={{ marginLeft: "10px", color: "red" }}>First filled the answer</span>
                                                            }
                                                          }}
                                                        >
                                                          {" "}
                                                          Add Answer
                                                        </button>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </>
                                            )
                                          )}
                                        </>
                                      )
                                    ) : (
                                      <>
                                        {parentQuestion.key !==
                                          "sub_inconsistent" &&
                                          parentQuestion.key !==
                                            "metrics_determine" &&
                                          parentQuestion.key !==
                                            "have_unprofitable_ranges" &&
                                          parentQuestion?.key !==
                                            "measures_csat" &&
                                          parentQuestion?.key !==
                                            "distinguish_segments" &&
                                          parentQuestion?.key !==
                                            "specifies_tactics" && (
                                            <div className="firstfield mt-3">
                                              <span>
                                                {parentQuestion.question}
                                              </span>
                                              {parentQuestion.questionType ===
                                              "AddedQuestion" ? (
                                                <div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      gap: "10px",
                                                    }}
                                                  >
                                                    <Toggle2
                                                      setSpecificQuestionKey={
                                                        setSpecificQuestionKey
                                                      }
                                                      questionKey={
                                                        parentQuestion.key
                                                      }
                                                      onToggleChange={
                                                        handleToggleChange
                                                      }
                                                      foundedAnswer={
                                                        foundedAnswer
                                                      }
                                                    />
                                                    {allAnswers[
                                                      parentQuestion?.key
                                                    ]?.answer && (
                                                      <div
                                                        className="container d-flex gap-1"
                                                        style={{ width: "62%" }}
                                                      >
                                                        <div className="form-check">
                                                          <label
                                                            className="form-check-label"
                                                            htmlFor="Strength"
                                                          >
                                                            Strength
                                                          </label>
                                                          <input
                                                            type="radio"
                                                            name={`swot-${parentQuestion?._id}`}
                                                            className="form-check-input"
                                                            id={`Strength-${parentQuestion?._id}`}
                                                            value="Strength"
                                                            defaultChecked
                                                            checked={
                                                              selectedSwot[
                                                                parentQuestion
                                                                  ?._id
                                                              ] ===
                                                                "Strength" ||
                                                              allAnswers[
                                                                parentQuestion
                                                                  ?.key
                                                              ]?.swot ===
                                                                "Strength"
                                                            }
                                                            onChange={() =>
                                                              handleSwotOptionChange(
                                                                parentQuestion,
                                                                "Strength"
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                        <div className="form-check">
                                                          <label
                                                            className="form-check-label"
                                                            htmlFor="Opportunity"
                                                          >
                                                            Weakness
                                                          </label>
                                                          <input
                                                            type="radio"
                                                            name={`swot-${parentQuestion?._id}`}
                                                            className="form-check-input"
                                                            id={`Weakness-${parentQuestion?._id}`}
                                                            value="Weakness"
                                                            checked={
                                                              selectedSwot[
                                                                parentQuestion
                                                                  ?._id
                                                              ] ===
                                                                "Weakness" ||
                                                              allAnswers[
                                                                parentQuestion
                                                                  ?.key
                                                              ]?.swot ===
                                                                "Weakness"
                                                            }
                                                            onChange={() =>
                                                              handleSwotOptionChange(
                                                                parentQuestion,
                                                                "Weakness"
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                        <div className="form-check">
                                                          <label
                                                            className="form-check-label"
                                                            htmlFor="Opportunity"
                                                          >
                                                            Opportunity
                                                          </label>
                                                          <input
                                                            name={`swot-${parentQuestion?._id}`}
                                                            type="radio"
                                                            className="form-check-input"
                                                            id={`Opportunity-${parentQuestion?._id}`}
                                                            value="Opportunity"
                                                            checked={
                                                              selectedSwot[
                                                                parentQuestion
                                                                  ?._id
                                                              ] ===
                                                                "Opportunity" ||
                                                              allAnswers[
                                                                parentQuestion
                                                                  ?.key
                                                              ]?.swot ===
                                                                "Opportunity"
                                                            }
                                                            onChange={() =>
                                                              handleSwotOptionChange(
                                                                parentQuestion,
                                                                "Opportunity"
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                        <div className="form-check">
                                                          <label
                                                            className="form-check-label"
                                                            htmlFor="Threat"
                                                          >
                                                            Threat
                                                          </label>
                                                          <input
                                                            name={`swot-${parentQuestion?._id}`}
                                                            type="radio"
                                                            className="form-check-input"
                                                            id={`Threat-${parentQuestion?._id}`}
                                                            value="Threat"
                                                            checked={
                                                              selectedSwot[
                                                                parentQuestion
                                                                  ?._id
                                                              ] === "Threat" ||
                                                              allAnswers[
                                                                parentQuestion
                                                                  ?.key
                                                              ]?.swot ===
                                                                "Threat"
                                                            }
                                                            onChange={() =>
                                                              handleSwotOptionChange(
                                                                parentQuestion,
                                                                "Threat"
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                    <p
                                                      style={{
                                                        color: "red",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        handleDelete(
                                                          parentQuestion.key,
                                                          parentQuestion._id
                                                        )
                                                      }
                                                    >
                                                      X
                                                    </p>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div>
                                                  {parentQuestion?.notNeeded ===
                                                    "true" ||
                                                  parentQuestion?.notKnow ===
                                                    "true" ||
                                                  parentQuestion?.notApplicable ===
                                                    "true" ? (
                                                    <Toggle3
                                                      setSpecificQuestionKey={
                                                        setSpecificQuestionKey
                                                      }
                                                      questionKey={
                                                        parentQuestion?.key
                                                      }
                                                      onToggleChange={
                                                        handleToggleChange
                                                      }
                                                      foundedAnswer={
                                                        foundedAnswer
                                                      }
                                                      question={parentQuestion}
                                                    />
                                                  ) : (
                                                    <Toggle2
                                                      setSpecificQuestionKey={
                                                        setSpecificQuestionKey
                                                      }
                                                      questionKey={
                                                        parentQuestion?.key
                                                      }
                                                      onToggleChange={
                                                        handleToggleChange
                                                      }
                                                      foundedAnswer={
                                                        foundedAnswer
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                      </>
                                    )}
                                    {allAnswers &&
                                    allAnswers[parentQuestion?.key] &&
                                    allAnswers[parentQuestion?.key].answer &&
                                    parentQuestion?.type ===
                                      allAnswers[parentQuestion?.key].answer
                                      ? allQuestions[parentQuestion?.key]?.map(
                                          (childQuestion, childIndex) =>
                                            childQuestion?.input &&
                                            childQuestion?.key !==
                                              "inconsistent_ways" &&
                                            childQuestion?.key !==
                                              "specifies_tactics" &&
                                            childQuestion?.key !==
                                              "includes_budget" &&
                                            childQuestion?.key !==
                                              "evaluate_impacts" &&
                                            childQuestion?.key !==
                                              "csat_score" ? (
                                              <div
                                                className="secondField"
                                                key={childIndex}
                                              >
                                                {childQuestion?.hr ===
                                                  "true" && (
                                                  <>
                                                    <hr />
                                                    <p className="text-center fw-bold">
                                                      {childQuestion?.title}
                                                    </p>
                                                    <hr />
                                                  </>
                                                )}
                                                <span>
                                                  {childQuestion.question}
                                                </span>

                                                <br />
                                                <div className="borderTextfield">
                                                  <textarea
                                                    type="text"
                                                    value={
                                                      allAnswers[
                                                        childQuestion?.key
                                                      ]?.answer
                                                    }
                                                    placeholder="Type here"
                                                    className="textField mb-3"
                                                    onChange={(e) => {
                                                      handleAnswerChange(
                                                        childQuestion?.key,
                                                        e.target.value
                                                      );
                                                    }}
                                                    onBlur={handleSubmit}
                                                  />
                                                  {!notShowKey.includes(
                                                    childQuestion.key
                                                  ) && (
                                                    <>
                                                      {inputValues[
                                                        childQuestion._id
                                                      ]?.map(
                                                        (
                                                          inputValue,
                                                          inputIndex
                                                        ) => (
                                                          <div
                                                            key={inputIndex}
                                                            className="d-flex gap-5 mb-2"
                                                          >
                                                            <input
                                                              className="newInputBox"
                                                              ref={(input) => {
                                                                if (
                                                                  !inputRefs
                                                                    .current[
                                                                    childQuestion
                                                                      ._id
                                                                  ]
                                                                ) {
                                                                  inputRefs.current[
                                                                    childQuestion._id
                                                                  ] = [];
                                                                }
                                                                inputRefs.current[
                                                                  childQuestion._id
                                                                ][inputIndex] =
                                                                  input;
                                                              }}
                                                              key={inputIndex}
                                                              type="text"
                                                              value={
                                                                inputValue?.answer
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChangee(
                                                                  childQuestion._id,
                                                                  inputIndex,
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                            <button
                                                              style={{
                                                                backgroundColor:
                                                                  "red",
                                                                color: "white",
                                                                width: "40px",
                                                                borderRadius:
                                                                  "3px",
                                                              }}
                                                              onClick={() =>
                                                                handleDeleteInput(
                                                                  childQuestion._id,
                                                                  inputIndex
                                                                )
                                                              }
                                                            >
                                                              <RiDeleteBin6Line size="30px" />
                                                            </button>
                                                          </div>
                                                        )
                                                      )}
                                                      <div className="addAnswer-btn mt-2 d-flex justify-content-end">
                                                        <button
                                                          onClick={() => {
                                                            if (
                                                              allAnswers[
                                                                childQuestion
                                                                  ?.key
                                                              ] &&
                                                              allAnswers[
                                                                childQuestion
                                                                  ?.key
                                                              ]?.answer !== ""
                                                            ) {
                                                              handleAddInput(
                                                                childQuestion._id,
                                                                childQuestion
                                                              );
                                                            } else {
                                                              toast.info(
                                                                "First filled the answer"
                                                              );
                                                              // <span style={{ marginLeft: "10px", color: "red" }}>First filled the answer</span>
                                                            }
                                                          }}
                                                        >
                                                          {" "}
                                                          Add Answer
                                                        </button>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                {
                                                  // childQuestion.key !== "sub_inconsistent" &&
                                                  childQuestion &&
                                                    childQuestion?.key !==
                                                      "inconsistent_ways" &&
                                                    childQuestion?.key !==
                                                      "specifies_tactics" &&
                                                    childQuestion?.key !==
                                                      "includes_budget" &&
                                                    childQuestion?.key !==
                                                      "evaluate_impacts" && (
                                                      <div className="firstfield mt-3">
                                                        <span>
                                                          {
                                                            childQuestion?.question
                                                          }
                                                        </span>
                                                        {childQuestion?.notNeeded ===
                                                          "true" ||
                                                        childQuestion?.notKnow ===
                                                          "true" ||
                                                        childQuestion?.notApplicable ===
                                                          "true" ? (
                                                          <Toggle3
                                                            setSpecificQuestionKey={
                                                              setSpecificQuestionKey
                                                            }
                                                            questionKey={
                                                              childQuestion?.key
                                                            }
                                                            onToggleChange={
                                                              handleToggleChange
                                                            }
                                                            foundedAnswer={
                                                              foundedAnswer
                                                            }
                                                            question={
                                                              childQuestion
                                                            }
                                                          />
                                                        ) : (
                                                          <Toggle2
                                                            setSpecificQuestionKey={
                                                              setSpecificQuestionKey
                                                            }
                                                            questionKey={
                                                              childQuestion?.key
                                                            }
                                                            onToggleChange={
                                                              handleToggleChange
                                                            }
                                                            foundedAnswer={
                                                              foundedAnswer
                                                            }
                                                          />
                                                        )}
                                                      </div>
                                                    )
                                                }
                                                {allAnswers &&
                                                  allAnswers[childQuestion?.key]
                                                    ?.answer ===
                                                    childQuestion?.type &&
                                                  allQuestions[
                                                    childQuestion?.key
                                                  ]?.map(
                                                    (
                                                      grandChild,
                                                      gChildIndex
                                                    ) => {
                                                      return (
                                                        <>
                                                          {grandChild?.input ? (
                                                            <div
                                                              className="secondField mt-3"
                                                              key={gChildIndex}
                                                            >
                                                              <span>
                                                                {
                                                                  grandChild.question
                                                                }
                                                              </span>
                                                              <br />
                                                              <div className="borderTextfield">
                                                                <input
                                                                  type="text"
                                                                  value={
                                                                    allAnswers[
                                                                      grandChild
                                                                        ?.key
                                                                    ]?.answer
                                                                  }
                                                                  placeholder="Type here"
                                                                  className="textField mb-3"
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAnswerChange(
                                                                      grandChild?.key,
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                  onBlur={
                                                                    handleSubmit
                                                                  }
                                                                />
                                                              </div>
                                                              {!notShowKey.includes(
                                                                grandChild.key
                                                              ) && (
                                                                <>
                                                                  {inputValues[
                                                                    grandChild
                                                                      ._id
                                                                  ]?.map(
                                                                    (
                                                                      inputValue,
                                                                      inputIndex
                                                                    ) => (
                                                                      <div
                                                                        key={
                                                                          inputIndex
                                                                        }
                                                                        className="d-flex gap-5 mb-2"
                                                                      >
                                                                        <input
                                                                          className="newInputBox"
                                                                          ref={(
                                                                            input
                                                                          ) => {
                                                                            if (
                                                                              !inputRefs
                                                                                .current[
                                                                                grandChild
                                                                                  ._id
                                                                              ]
                                                                            ) {
                                                                              inputRefs.current[
                                                                                grandChild._id
                                                                              ] =
                                                                                [];
                                                                            }
                                                                            inputRefs.current[
                                                                              grandChild._id
                                                                            ][
                                                                              inputIndex
                                                                            ] =
                                                                              input;
                                                                          }}
                                                                          key={
                                                                            inputIndex
                                                                          }
                                                                          type="text"
                                                                          value={
                                                                            inputValue?.answer
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleInputChangee(
                                                                              grandChild._id,
                                                                              inputIndex,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                        />
                                                                        <button
                                                                          style={{
                                                                            backgroundColor:
                                                                              "red",
                                                                            color:
                                                                              "white",
                                                                            width:
                                                                              "40px",
                                                                            borderRadius:
                                                                              "3px",
                                                                          }}
                                                                          onClick={() =>
                                                                            handleDeleteInput(
                                                                              grandChild._id,
                                                                              inputIndex
                                                                            )
                                                                          }
                                                                        >
                                                                          <RiDeleteBin6Line size="30px" />
                                                                        </button>
                                                                      </div>
                                                                    )
                                                                  )}

                                                                  <div className="addAnswer-btn mt-2 d-flex justify-content-end">
                                                                    <button
                                                                      onClick={() => {
                                                                        if (
                                                                          allAnswers[
                                                                            grandChild
                                                                              ?.key
                                                                          ] &&
                                                                          allAnswers[
                                                                            grandChild
                                                                              ?.key
                                                                          ]
                                                                            ?.answer !==
                                                                            ""
                                                                        ) {
                                                                          handleAddInput(
                                                                            grandChild._id,
                                                                            grandChild
                                                                          );
                                                                        } else {
                                                                          toast.info(
                                                                            "First filled the answer"
                                                                          );
                                                                          // <span style={{ marginLeft: "10px", color: "red" }}>First filled the answer</span>
                                                                        }
                                                                      }}
                                                                    >
                                                                      {" "}
                                                                      Add Answer
                                                                    </button>
                                                                  </div>
                                                                </>
                                                              )}
                                                            </div>
                                                          ) : (
                                                            <>
                                                              {grandChild?.key !==
                                                                "csat_score" && (
                                                                <div className="firstfield mt-3">
                                                                  <span>
                                                                    {
                                                                      grandChild?.question
                                                                    }
                                                                  </span>
                                                                  {grandChild?.notNeeded ===
                                                                    "true" ||
                                                                  grandChild?.notKnow ===
                                                                    "true" ||
                                                                  grandChild?.notApplicable ===
                                                                    "true" ? (
                                                                    <Toggle3
                                                                      setSpecificQuestionKey={
                                                                        setSpecificQuestionKey
                                                                      }
                                                                      questionKey={
                                                                        grandChild?.key
                                                                      }
                                                                      onToggleChange={
                                                                        handleToggleChange
                                                                      }
                                                                      foundedAnswer={
                                                                        foundedAnswer
                                                                      }
                                                                      question={
                                                                        grandChild
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Toggle2
                                                                      setSpecificQuestionKey={
                                                                        setSpecificQuestionKey
                                                                      }
                                                                      questionKey={
                                                                        grandChild?.key
                                                                      }
                                                                      onToggleChange={
                                                                        handleToggleChange
                                                                      }
                                                                      foundedAnswer={
                                                                        foundedAnswer
                                                                      }
                                                                    />
                                                                  )}
                                                                </div>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                {allAnswers &&
                                                  allAnswers[childQuestion?.key]
                                                    ?.answer ===
                                                    childQuestion?.type1 &&
                                                  allIndependentQuestion[
                                                    childQuestion?.key
                                                  ]?.map(
                                                    (
                                                      grandChild,
                                                      gChildIndex
                                                    ) => {
                                                      return (
                                                        <>
                                                          {
                                                            <>
                                                              {grandChild?.key !==
                                                                "csat_score" && (
                                                                <div className="firstfield mt-3">
                                                                  <span>
                                                                    {
                                                                      grandChild?.question
                                                                    }
                                                                  </span>
                                                                  {grandChild?.notNeeded ===
                                                                    "true" ||
                                                                  grandChild?.notKnow ===
                                                                    "true" ||
                                                                  grandChild?.notApplicable ===
                                                                    "true" ? (
                                                                    <Toggle3
                                                                      setSpecificQuestionKey={
                                                                        setSpecificQuestionKey
                                                                      }
                                                                      questionKey={
                                                                        grandChild?.key
                                                                      }
                                                                      onToggleChange={
                                                                        handleToggleChange
                                                                      }
                                                                      foundedAnswer={
                                                                        foundedAnswer
                                                                      }
                                                                      question={
                                                                        grandChild
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Toggle2
                                                                      setSpecificQuestionKey={
                                                                        setSpecificQuestionKey
                                                                      }
                                                                      questionKey={
                                                                        grandChild?.key
                                                                      }
                                                                      onToggleChange={
                                                                        handleToggleChange
                                                                      }
                                                                      foundedAnswer={
                                                                        foundedAnswer
                                                                      }
                                                                    />
                                                                  )}
                                                                </div>
                                                              )}
                                                            </>
                                                          }
                                                        </>
                                                      );
                                                    }
                                                  )}
                                              </>
                                            )
                                        )
                                      : allAnswers[parentQuestion?.key]
                                          ?.answer === parentQuestion?.type1 &&
                                        allIndependentQuestion[
                                          parentQuestion?.key
                                        ]?.map((childQuestion, childIndex) =>
                                          childQuestion?.input ? (
                                            <div
                                              className="secondField"
                                              key={childIndex}
                                            >
                                              {childQuestion?.hr === "true" && (
                                                <>
                                                  <hr />
                                                  <p className="text-center fw-bold">
                                                    {childQuestion?.title}
                                                  </p>
                                                  <hr />
                                                </>
                                              )}
                                              <span>
                                                {childQuestion.question}
                                              </span>

                                              <br />
                                              <div className="borderTextfield">
                                                <textarea
                                                  type="text"
                                                  value={
                                                    allAnswers[
                                                      childQuestion?.key
                                                    ]?.answer
                                                  }
                                                  placeholder="Type here"
                                                  className="textField mb-3"
                                                  onChange={(e) => {
                                                    handleAnswerChange(
                                                      childQuestion?.key,
                                                      e.target.value
                                                    );
                                                  }}
                                                  onBlur={handleSubmit}
                                                />
                                                {!notShowKey.includes(
                                                  childQuestion.key
                                                ) && (
                                                  <>
                                                    {inputValues[
                                                      childQuestion._id
                                                    ]?.map(
                                                      (
                                                        inputValue,
                                                        inputIndex
                                                      ) => (
                                                        <div
                                                          key={inputIndex}
                                                          className="d-flex gap-5 mb-2"
                                                        >
                                                          <input
                                                            className="newInputBox"
                                                            ref={(input) => {
                                                              if (
                                                                !inputRefs
                                                                  .current[
                                                                  childQuestion
                                                                    ._id
                                                                ]
                                                              ) {
                                                                inputRefs.current[
                                                                  parentQuestion._id
                                                                ] = [];
                                                              }
                                                              inputRefs.current[
                                                                childQuestion._id
                                                              ][inputIndex] =
                                                                input;
                                                            }}
                                                            key={inputIndex}
                                                            type="text"
                                                            value={
                                                              inputValue?.answer
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangee(
                                                                childQuestion._id,
                                                                inputIndex,
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                          <button
                                                            style={{
                                                              backgroundColor:
                                                                "red",
                                                              color: "white",
                                                              width: "40px",
                                                              borderRadius:
                                                                "3px",
                                                            }}
                                                            onClick={() =>
                                                              handleDeleteInput(
                                                                childQuestion._id,
                                                                inputIndex
                                                              )
                                                            }
                                                          >
                                                            <RiDeleteBin6Line size="30px" />
                                                          </button>
                                                        </div>
                                                      )
                                                    )}
                                                    <div className="addAnswer-btn mt-2 d-flex justify-content-end">
                                                      <button
                                                        onClick={() => {
                                                          if (
                                                            allAnswers[
                                                              childQuestion?.key
                                                            ] &&
                                                            allAnswers[
                                                              childQuestion?.key
                                                            ]?.answer !== ""
                                                          ) {
                                                            handleAddInput(
                                                              childQuestion._id,
                                                              childQuestion
                                                            );
                                                          } else {
                                                            toast.info(
                                                              "First filled the answer"
                                                            );
                                                            // <span style={{ marginLeft: "10px", color: "red" }}>First filled the answer</span>
                                                          }
                                                        }}
                                                      >
                                                        {" "}
                                                        Add Answer
                                                      </button>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              {
                                                // childQuestion.key !== "sub_inconsistent" &&

                                                childQuestion &&
                                                  childQuestion.key !==
                                                    "specifies_tactics" &&
                                                  childQuestion?.key !==
                                                    "includes_budget" && (
                                                    <div
                                                      className="firstfield mt-3"
                                                      key={childIndex}
                                                    >
                                                      <span>
                                                        {
                                                          childQuestion?.question
                                                        }
                                                      </span>
                                                      {childQuestion?.notNeeded ===
                                                        "true" ||
                                                      childQuestion?.notKnow ===
                                                        "true" ||
                                                      childQuestion?.notApplicable ===
                                                        "true" ? (
                                                        <Toggle3
                                                          setSpecificQuestionKey={
                                                            setSpecificQuestionKey
                                                          }
                                                          questionKey={
                                                            childQuestion.key
                                                          }
                                                          onToggleChange={
                                                            handleToggleChange
                                                          }
                                                          foundedAnswer={
                                                            foundedAnswer
                                                          }
                                                          question={
                                                            childQuestion
                                                          }
                                                        />
                                                      ) : (
                                                        <Toggle2
                                                          setSpecificQuestionKey={
                                                            setSpecificQuestionKey
                                                          }
                                                          questionKey={
                                                            childQuestion.key
                                                          }
                                                          onToggleChange={
                                                            handleToggleChange
                                                          }
                                                          foundedAnswer={
                                                            foundedAnswer
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  )
                                              }
                                            </>
                                          )
                                        )}
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      )}

                      <span className="newreason">
                        <div
                          style={{ cursor: "pointer" }}
                          className="d-flex align-items-center gap-1"
                          onClick={handleShow}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                          >
                            <path
                              d="M7.25615 8.8V11.2C7.25615 11.4267 7.33282 11.6167 7.48615 11.77C7.63949 11.9233 7.82949 12 8.05615 12C8.28282 12 8.47282 11.9233 8.62615 11.77C8.77949 11.6167 8.85615 11.4267 8.85615 11.2V8.8H11.2562C11.4828 8.8 11.6728 8.72333 11.8262 8.57C11.9795 8.41667 12.0562 8.22667 12.0562 8C12.0562 7.77333 11.9795 7.58333 11.8262 7.43C11.6728 7.27667 11.4828 7.2 11.2562 7.2H8.85615V4.8C8.85615 4.57333 8.77949 4.38333 8.62615 4.23C8.47282 4.07667 8.28282 4 8.05615 4C7.82949 4 7.63949 4.07667 7.48615 4.23C7.33282 4.38333 7.25615 4.57333 7.25615 4.8V7.2H4.85615C4.62949 7.2 4.43949 7.27667 4.28615 7.43C4.13282 7.58333 4.05615 7.77333 4.05615 8C4.05615 8.22667 4.13282 8.41667 4.28615 8.57C4.43949 8.72333 4.62949 8.8 4.85615 8.8H7.25615ZM8.05615 16C6.94949 16 5.90949 15.79 4.93615 15.37C3.96282 14.95 3.11615 14.38 2.39615 13.66C1.67615 12.94 1.10615 12.0933 0.686152 11.12C0.266152 10.1467 0.0561523 9.10667 0.0561523 8C0.0561523 6.89333 0.266152 5.85333 0.686152 4.88C1.10615 3.90667 1.67615 3.06 2.39615 2.34C3.11615 1.62 3.96282 1.05 4.93615 0.63C5.90949 0.21 6.94949 0 8.05615 0C9.16282 0 10.2028 0.21 11.1762 0.63C12.1495 1.05 12.9962 1.62 13.7162 2.34C14.4362 3.06 15.0062 3.90667 15.4262 4.88C15.8462 5.85333 16.0562 6.89333 16.0562 8C16.0562 9.10667 15.8462 10.1467 15.4262 11.12C15.0062 12.0933 14.4362 12.94 13.7162 13.66C12.9962 14.38 12.1495 14.95 11.1762 15.37C10.2028 15.79 9.16282 16 8.05615 16ZM8.05615 14.4C9.84282 14.4 11.3562 13.78 12.5962 12.54C13.8362 11.3 14.4562 9.78667 14.4562 8C14.4562 6.21333 13.8362 4.7 12.5962 3.46C11.3562 2.22 9.84282 1.6 8.05615 1.6C6.26949 1.6 4.75615 2.22 3.51615 3.46C2.27615 4.7 1.65615 6.21333 1.65615 8C1.65615 9.78667 2.27615 11.3 3.51615 12.54C4.75615 13.78 6.26949 14.4 8.05615 14.4Z"
                              fill="#00A2D6"
                            />
                          </svg>
                          Add new Question
                        </div>
                      </span>
                      <div className="purposebutton">
                        <div className="buttndiv">
                          <button className="previous" onClick={previousbtn}>
                            Previous
                          </button>
                          {/* <button style={{ backgroundColor: "#00a2d6" }} onClick={()=>{nextbtn();handleSaveToModal}}>Save to Modal</button> */}
                          <button
                            className="save"
                            onClick={() => {
                              handleSaveToModal();
                              handleSaveValue();
                              handleSaveAnswer();
                            }}
                          >
                            Save & Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>Please Enter the Strategic Plan first</div>
                )}
              </div>
            </div>
            <Modal show={show} onHide={handleClose} size="xl" centered>
              <Modal.Header closeButton>
                <Modal.Title>Add new Question</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="modalContent">
                  <div className="mainModal">
                    <div className="addQuestion">
                      <div className="questionInput">
                        <input
                          type="text"
                          required
                          placeholder="add new question"
                          onChange={(e) => setNewQuestion(e.target.value)}
                        />
                        <button onClick={handleQuestion}>Add</button>
                      </div>
                      {/* <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        <label>
                          <input
                            type="radio"
                            value="false"
                            checked={selectedOption === false}
                            onChange={handleOptionChange}
                          />
                          yes / no
                        </label>
                        <label>
                          <input
                            type="radio"
                            value="true"
                            checked={selectedOption === true}
                            onChange={handleOptionChange}
                          />
                          Long answer
                        </label>

                      </div> */}
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )
      }
    </>
  );
};

export default Generic;
