export const dropdownOptions = {
  Priority: ["High", "Medium", "Low"],
  Status: [
    "Resolved",
    "On track",
    "Delayed but almost done",
    "Delayed",
    "Need help",
    "Past due",
    "Cancelled/Parked",
  ],
  "Closed?": ["Yes", "No"],
  "Nature of Need to Know": ["Supplied", "Customer", "Team", "Community"],
};
