import React from "react";
import "./style.css";
import Navbar from "../../components/shared/navbar";
import Footer from "../../components/shared/footer";
const Pagenotfound = () => {
  return (
    <>
      <Navbar />
      <section className="p-0 bg-img cover-background notfoundSection">
        <div className="container-fluid d-flex flex-column">
          <div className="row align-items-center justify-content-center min-vh-100">
            <div className="col-md-9 col-lg-6 my-5">
              <div className="text-center error-page">
                <h1 className="mb-0 text-secondary">404</h1>
                <h2 className="mb-4 text-blue">Sorry, something went wrong!</h2>
                <p className="w-sm-80 mx-auto mb-4 text-blue">
                  Oops! The page you are looking for does not exist.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Pagenotfound;
