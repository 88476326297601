import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Logos from "../assets/images/navlogo.png";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { FaEyeSlash } from "react-icons/fa";
import { IoArrowBackCircleOutline } from "react-icons/io5";
const Resetpassword = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  // const apiUrl = "http://localhost:3333";

  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isSending, setIsSending] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const generateOtp = async () => {
    if (!email) {
      setEmailError("Email is required");
      return;
    }
    try {
      setIsSending(true);
      const response = await axios.post(`${apiUrl}/user/forget`, { email });
      // console.log(response.data);

      if (response.data) {
        setStep(2);
      } else {
        setOtpError(response.data.message);
      }
      setIsSending(false);
    } catch (error) {
      setIsSending(false);
      console.error(error);
      toast.error(error?.response?.data?.message);
      setOtpError("Failed to verify OTP. Please try again.");
    }
  };

  const resetPassword = async () => {
    try {
      const response = await axios.post(`${apiUrl}/user/reset-password`, {
        email,
        otp,
        password,
      });
      console.log(response.data);

      toast.success(response.data.message);
      navigate("/signin");
      // setPasswordError(response.data.message);
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleOtpSubmit = () => {
    // verifyOtp();
  };

  const handleResetPassword = () => {
    if (!otp) {
      setOtpError("OTP is required");
      return;
    }
    if (!password) {
      setPasswordError("Password is required");
      return;
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
      return;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }
    resetPassword();
  };

  return (
    <div className="resetPassword">
      <div className="signin-content">
        <div className="signup-logo">
          <img src={Logos} alt="signuplogo" className="custom" />
        </div>
        <div className="signup-card">
          <div className="signupcard-content">
            <div className="main-card">
              <div className="cardtitle">
                {step === 1 ? (
                  <></>
                ) : (
                  <div
                    className="d-flex gap-1 align-items-center text-primary pointer"
                    onClick={() => setStep(1)}
                  >
                    <IoArrowBackCircleOutline size={30} className="" />{" "}
                    <h6>Back</h6>
                  </div>
                )}
                <div className="leftside">
                  <h6>{step === 1 ? "Enter Email" : "Reset Password"}</h6>
                </div>
                <div className="rightside">
                  <h6>
                    {step === 1
                      ? "Enter your email and OTP to verify."
                      : "Set the new password for your account so you can login and access all the features."}
                  </h6>
                </div>
              </div>
              {step === 1 ? (
                <div className="accountRecovery-middle">
                  <div className="firstfield">
                    <div className="label">
                      <label htmlFor="">Email</label>{" "}
                    </div>
                    <div className="inputfield">
                      <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailError(""); // Clear error when user starts typing
                        }}
                      />
                    </div>
                    <span style={{ color: "red" }} className="span">
                      {emailError}
                    </span>
                  </div>

                  <p></p>
                  <div className="firstfield d-none">
                    <div className="label">
                      <label htmlFor="">OTP</label>{" "}
                    </div>
                    <div className="inputfield">
                      <input
                        type="text"
                        placeholder="OTP"
                        value={otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                          setOtpError(""); // Clear error when user starts typing
                        }}
                      />
                    </div>
                    <span style={{ color: "red" }} className="span">
                      {otpError}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="accountRecovery-middle">
                  <div className="firstfield">
                    <div className="label">
                      <label htmlFor="">OTP</label>{" "}
                    </div>
                    <div className="inputfield">
                      <input
                        type="text"
                        placeholder="OTP"
                        value={otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                          setOtpError(""); // Clear error when user starts typing
                        }}
                        // readOnly
                      />
                      <span style={{ color: "red" }} className="span">
                        {otpError}
                      </span>
                    </div>
                  </div>
                  <div className="firstfield">
                    <div className="label">
                      <label htmlFor="">Password</label>{" "}
                    </div>
                    <div className="inputfield">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setPasswordError(""); // Clear error when user starts typing
                        }}
                      />

                      <div
                        className="eye-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <FaEyeSlash />
                        ) : (
                          <Icon
                            icon="octicon:eye-24"
                            color="gray"
                            width="18"
                            height="18"
                          />
                        )}
                      </div>
                    </div>
                    <span style={{ color: "red" }} className="span">
                      {passwordError}
                    </span>
                  </div>
                  <div className="firstfield">
                    <div className="label">
                      <label htmlFor="">Confirm Password</label>{" "}
                    </div>
                    <div className="inputfield">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          setConfirmPasswordError(""); // Clear error when user starts typing
                        }}
                      />
                      <div
                        className="eye-icon"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? (
                          <FaEyeSlash />
                        ) : (
                          <Icon
                            icon="octicon:eye-24"
                            color="gray"
                            width="18"
                            height="18"
                          />
                        )}
                      </div>
                    </div>
                    <span style={{ color: "red" }} className="span">
                      {confirmPasswordError}
                    </span>
                  </div>

                  <div className="firstfield">
                    <div className="label">
                      <label htmlFor="">Email</label>{" "}
                    </div>
                    <div className="inputfield">
                      <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="card-bottom">
                <div className="signin-btn">
                  {step === 1 ? (
                    <>
                      <button disabled={isSending} onClick={generateOtp}>
                        {isSending ? "Sending..." : "Send OTP"}
                      </button>
                      <h5 className="d-flex justify-content-end text-primary pointer mt-2">
                        <Link to="/signin">Sign In</Link>
                      </h5>
                    </>
                  ) : (
                    <button onClick={handleResetPassword}>
                      Reset Password
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
