export const formatDateMMDDYYYY = (dateString) => {
  if (!dateString) return "";
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
};
export const formatDate = (dateString) => {
  if (dateString === "") return "";
  const [day, month, year] = dateString?.split("/");
  const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
    2,
    "0"
  )}`;
  return formattedDate;
};
