import React, { useEffect, useState } from "react";
import "./Toggle.scss";

const Toggle3 = ({ questionKey, onToggleChange, setSpecificQuestionKey, foundedAnswer, question }) => {

  // console.log(question)
  // console.log("The Question key is :", questionKey)
  const [selectedOption, setSelectedOption] = useState("");
  const check = foundedAnswer?.filter((e) => e.key === questionKey);


  const handleToggle = (option) => {
    setSelectedOption(option);
    onToggleChange(questionKey, option);
    setSpecificQuestionKey((prev) => [...prev, questionKey]);
  };
  // console.log(selectedOption)
  // console.log(foundedAnswer)
  // console.log(check)
  // const option = () => {
  //   if (selectedOption === "yes") {
  //     return 0
  //   }
  //   else if (selectedOption === "no") {
  //     return 1
  //   }
  //   //return selectedOption === "yes" ? 0 : 1;
  // };

  useEffect(() => {
    if (foundedAnswer && check?.length > 0) {
      setSelectedOption(check[0]?.answer)
    }
  }, [foundedAnswer])


  // const handleToggle = (option) => {
  //   setSelectedOption(option === selectedOption ? null : option);
  //   onToggleChange(questionKey, option === selectedOption ? null : option);
  // };

  const option = () => {
    if (selectedOption === "") {
      return null;
    }
    if (selectedOption === "yes") {
      return 0;
    }
    if (selectedOption === "no") {
      return 1;
    } else if (selectedOption === "notNeeded") return 2;
  };
  return (
    <div className={`checkbox3 toggle-state-${option()}`}>
      {/* <input type="checkbox" id="checkbox_toggle" className="check" /> */}
      <label className="slide" htmlFor="checkbox_toggle">
        <div
          className={`toggle toggle-color-${option()} toggle-state-${option()}`}
          onClick={() => handleToggle("yes")}
        ></div>
        <div
          className={`toggle toggle-color-${option()} toggle-state-${option()}`}
          onClick={() => handleToggle("no")}
        ></div>
        <div
          className={`toggle toggle-color-${option()} toggle-state-${option()}`}
          onClick={() => handleToggle("notNeeded")}
        >
          {" "}
        </div>

        <div className="text" onClick={() => handleToggle("yes")}>
          Yes
        </div>
        <div className="text" onClick={() => handleToggle("no")}>
          No
        </div>
        <div className="text" onClick={() => handleToggle("notNeeded")}>
          {question && question?.notKnow === "true" && "I don’t know"}
          {question && question?.notNeeded === "true" && "Not Needed"}
          {question && question?.key === "receives_reports" ? "Not All" : (question?.notApplicable === "true" && "N/A")}
        </div>
      </label>
    </div>
  );
};

export default Toggle3;
