import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { Table } from "react-bootstrap";
import { AuthContext, PlanContext } from "../../../app/context";
import { useNavigate, useParams } from "react-router-dom";

const AdminDashboardUserPlansTable = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { setPlanData } = useContext(PlanContext);
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    const fetchUsers = async () => {
      const resp = await axios.get(`${apiUrl}/admin-dashboard/plan/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`, //auth token for admin
        },
      });
      setPlans(resp.data.plans);
    };
    fetchUsers();
  }, []);
  const handlePlanClick = (plan) => {
    setPlanData(plan);
    navigate("/questionnaire");
  };
  return (
    <AdminDashboardLayout>
      <>
        <h4 className="text-center p-2">Plans Lists</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Plan Id</th>
              <th>Plan Name</th>
              <th>Plan Owner</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {plans?.map((plan, index) => {
              return (
                <tr key={plan._id}>
                  <td>{plan._id}</td>
                  <td>{plan.planName}</td>
                  <td>{plan.planUser}</td>
                  <td
                    className="text-blue pointer"
                    onClick={() => handlePlanClick(plan)}
                  >
                    view plan
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <button
          className="btn btn-primary"
          onClick={() => navigate("/dashboard/admin")}
        >
          Back
        </button>
      </>
    </AdminDashboardLayout>
  );
};

export default AdminDashboardUserPlansTable;
