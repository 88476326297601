import React from "react";
import { getProgressPoint } from "../../../helpers/bar-helper";
import { BarDisplay } from "./bar-display";
import { BarDateHandle } from "./bar-date-handle";
import { BarProgressHandle } from "./bar-progress-handle";
import { TaskItemProps } from "../task-item";
import styles from "./bar.module.css";

export const Bar = ({
  z,
  task,
  isProgressChangeable,
  isDateChangeable,
  rtl,
  onEventStart,
  isSelected,
}) => {
  const progressPoint = getProgressPoint(
    +!rtl * task.progressWidth + task.progressX,
    task.y + z,
    task.height
  );
  // console.log(task);
  const handleHeight = task.height - 2;
  const { x1, x2, y, handleWidth, barCornerRadius, styles: taskStyles } = task;
  // console.log(y);
  return (
    <g className={styles.barWrapper} tabIndex={0}>
      <BarDisplay
        task={task}
        x={x1}
        y={y + z}
        width={x2 - x1}
        height={task.height}
        progressX={task.progressX}
        progressWidth={task.progressWidth}
        barCornerRadius={barCornerRadius}
        styles={taskStyles}
        isSelected={isSelected}
        onMouseDown={(e) => {
          isDateChangeable && onEventStart("move", task, e);
        }}
      />
      <g className="handleGroup">
        {isDateChangeable && (
          <g>
            {/* left */}
            <BarDateHandle
              x={x1 + 1}
              y={y + z + 1}
              width={handleWidth}
              height={handleHeight}
              barCornerRadius={barCornerRadius}
              onMouseDown={(e) => {
                onEventStart("start", task, e);
              }}
            />
            {/* right */}
            <BarDateHandle
              x={x2 - handleWidth - 1}
              y={y + z + 1}
              width={handleWidth}
              height={handleHeight}
              barCornerRadius={barCornerRadius}
              onMouseDown={(e) => {
                onEventStart("end", task, e);
              }}
            />
          </g>
        )}
        {isProgressChangeable && (
          <BarProgressHandle
            progressPoint={progressPoint}
            onMouseDown={(e) => {
              onEventStart("progress", task, e);
            }}
          />
        )}
      </g>
    </g>
  );
};
