import React, { useState } from "react";
import "./KanbanModal.css";
import { toast } from "react-toastify";
import axios from "axios";

const KanbanModal = (props) => {
    const { columns, setColumns } = props
    const apiUrl = process.env.REACT_APP_BACKEND_API
    const { pId, gId } = props.Ids
    const [inputValues, setInputValues] = useState({
        name: "Q1",
        tactic: "",
        start: Date.now(),
        end: Date.now(),
        status: props?.Ids?.status
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };
    const addTactics = (tactic) => {
        setColumns(prevData => {
            const newData = prevData.map(item => ({ ...item })); // Shallow copy the previous state
            const priority = newData.find(item => item._id == pId); // Access the priority using pId as the property key
            if (priority) {
                const goal = priority.goals.find(g => g._id == gId); // Find the goal within the priority
                if (goal) {
                    goal.tactics.push(tactic); // Add the new tactic to the goal
                }
            }
            console.log(newData)
            return newData; // Return the updated state
        });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const startDateObj = new Date(inputValues.start);
        const endDateObj = new Date(inputValues.end);

        const reqBody = { ...inputValues, start: startDateObj.toISOString(), end: endDateObj.toISOString(), gId: props.Ids.gId }

        try {
            const resp = await axios.post(`${apiUrl}/ganttest/addTactics/${props.Ids.pId}`, reqBody)
            if (resp.data.success) {
                toast.success("Added successfully")
                // props.incrementFetch();
                addTactics(resp.data.newTactics[0])
            }
        } catch (error) {
            toast.error("some thing went wrong")
            setInputValues({
                name: "Q1",
                tactic: "",
                start: Date.now(),
                end: Date.now()
            });
        }
        props.closeModal()
    };
    console.log(columns)

    return (
        <div className="KanbanModal">
            <section className="KanbanModal-content">
                <span
                    className="KanbanModal-close-btn"
                    onClick={props.closeModal}
                ></span>
                <form
                    className="w-100 mx-3"
                    onSubmit={handleSubmit}
                >
                    <div className="form-group mt-5">
                        <label htmlFor="tactic" className="KanbanModal-label">Task: </label>
                        <textarea className="form-control" name="tactic" required value={inputValues.tactic} onChange={handleInputChange} placeholder="Enter Tactics" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name" className="KanbanModal-label">Quarter </label>
                        <select
                            value={inputValues.name}
                            name="name"
                            style={{ width: "100%", padding: "10px" }}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        >
                            <option value="Q1">Quarter 1</option>
                            <option value="Q2">Quarter 2</option>
                            <option value="Q3">Quarter 3 </option>
                            <option value="Q4">Quarter 4 </option>
                        </select>
                    </div>
                    {/* <input type="text" name="personAccountable" value={inputValues.personAccountable} onChange={handleInputChange} placeholder="Person Accountable" /><br /> */}
                    <div className="form-group">
                        <label htmlFor="start" className="KanbanModal-label">Start Date: </label>
                        <input type="date" name="start" className="form-control" value={inputValues.start} onChange={handleInputChange} placeholder="start data" min={new Date().toISOString().split("T")[0]} /><br />
                    </div>
                    <div className="form-group">
                        <label htmlFor="end" className="KanbanModal-label">End Date: </label>
                        <input type="date" name="end" className="form-control" value={inputValues.end} onChange={handleInputChange} placeholder="end date" min={new Date().toISOString().split("T")[0]} /><br />
                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={props.closeModal}>Close</button>
                        <button className="btn btn-primary" type="submit">Save changes</button>
                    </div>

                </form>
            </section>
        </div>
    );
};

export default KanbanModal;