import React from "react";
import Navbar from "../components/shared/navbar";
import Tactic from "../components/tacticalplan/tactic";
import Footer from "../components/shared/footer";
import { useLocation } from 'react-router-dom';

const Tacticalplan = () => {

  const location = useLocation();
  return (
    <div>

      <Navbar />
      <Tactic location={location} />
      {/* <TacticTest /> */}
      <Footer />
    </div>
  );
};

export default Tacticalplan;
