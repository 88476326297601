import React, { useContext, useState } from "react";
import styles from "./task-list-table.module.css";
import { toast } from "react-toastify";
import axios from "axios";
import TaskForm from "./TaskForm";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DraggableDropdown from "./drag";
import { PlanContext } from "../../../app/context";
// import { Dropdown, Space } from "antd";
// import { Task } from "../../types/public-types";

export const TaskListTableDefault = ({
  rowHeight,
  rowWidth,
  tasks,
  fontFamily,
  fontSize,
  onExpanderClick,
  setGantData,
  setTasks
}) => {
  // console.log(tasks);

  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const { plan } = useContext(PlanContext);
  const [openForm, setOpenForm] = useState("");
  const handleAddMore = (id) => {
    setOpenForm(id);
  };
  const handeDragAndDrop = async (params) => {
    // console.log(params)
    const { source, destination, draggableId } = params;

    if (!destination || source.index === destination.index) {
      // console.log("not destia")
      return;
    }

    // Find the source and destination goals based on droppableIds
    const sourceGoalIndex = tasks.findIndex((task) =>
      task.goals.some((goal) =>
        goal.tactics.find((tactic) => tactic._id === draggableId)
      )
    );
    const destinationGoalIndex = tasks.findIndex((task) =>
      task.goals.some((goal) =>
        goal.tactics.find((tactic) => tactic._id === draggableId)
      )
    );
    // Find the source and destination goal objects
    const sourceGoal = tasks[sourceGoalIndex].goals.find((goal) =>
      goal.tactics.find((tactic) => tactic._id === draggableId)
    );
    const destinationGoal = tasks[destinationGoalIndex].goals.find(
      (goal) => goal._id === destination.droppableId
    );

    const draggedTacticIndex = sourceGoal.tactics.findIndex(
      (tactic) => tactic._id === draggableId
    );
    const draggedTactic = sourceGoal.tactics[draggedTacticIndex];
    // console.log(draggedTacticIndex, draggedTactic)
    // console.log(draggedTactic)

    // Remove the dragged tactic from the source goal
    sourceGoal.tactics.splice(draggedTacticIndex, 1);

    // Insert the dragged tactic into the destination goal at the specified index
    destinationGoal.tactics.splice(destination.index, 0, draggedTactic);
    if (tasks.length > 0) {
      try {
        const resp = await axios.post(`${apiUrl}/ganttest`, {
          data: tasks,
          planId: plan._id,
          type: "gantt",
        });

        if (resp.data.success) {
          toast.success("Order changed successfully");
          setGantData(resp?.data?.tacticData);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      toast.error("Please fill any tactic");
    }
  };

  const handlePersonAccountableChange = async (
    personAccountable,
    pId,
    tId,
    tactic
  ) => {
    if (tactic === personAccountable) {
      return;
    }
    try {
      const resp = await axios.put(`${apiUrl}/ganttest/update/${tId}/${pId}`, {
        personAccountable: personAccountable,
      });

      if (resp.data.success) {
        setGantData(resp.data.newGantt);
      }

      // console.log("Status updated successfully");
    } catch (error) {
      console.log("Error updating status:", error);
    }
  };
  // console.log(tasks[0].goals[0])

  return (
    <>
      {/* <input type="text" /> */}
      {tasks.map((tas, i) => (
        <div
          key={i}
          style={{
            paddingLeft: "20px",
          }}
        >
          <div
            className={styles.taskListTableRow}
            style={{ height: "48px" }}
          >
            <div
              className={styles.taskListCell}
              style={{
                minWidth: "470px",
                maxWidth: rowWidth,
                fontWeight: "800",
                backgroundColor: "#1572BD",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            // title={"qwdqd"}
            >
              &nbsp; Priority  {tas?.planName}
              <DraggableDropdown goals={tas?.goals} tasks={tasks} setTasks={setTasks} />
            </div>
          </div>
          {tas.goals?.map((task, index) => (
            <div key={index}>
              <div>
                <div
                  className={styles.taskListTableRow}
                  style={{ height: "48px" }}
                >
                  <div
                    className={styles.taskListCell}
                    style={{
                      minWidth: "470px",
                      maxWidth: rowWidth,
                      // textAlign: "center",
                      overflow: "hidden",
                      fontWeight: 400,
                      backgroundColor: "#0DCAF0",
                    }}
                  >
                    &nbsp; Goal {index + 1}: <b>&nbsp; {task.goalName}</b>
                  </div>
                </div>
                <div
                  style={{ height: rowHeight, backgroundColor: "#D64301", color: "white" }}
                >
                  <div
                    className={styles.taskListCell}
                    style={{ minWidth: rowWidth, maxWidth: rowWidth }}
                  >
                    <div className={styles.taskListNameWrapper}>
                      <div style={{ textAlign: "center", fontWeight: 500 }}>
                        &nbsp; Quarter
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.taskListCell}
                    style={{
                      minWidth: rowWidth,
                      maxWidth: rowWidth,
                      fontWeight: 600,
                    }}
                  >
                    &nbsp;Tactics
                  </div>
                  <div
                    className={styles.taskListCell}
                    style={{
                      minWidth: rowWidth,
                      maxWidth: rowWidth,
                      fontWeight: 600,
                    }}
                  >
                    Person Accountable
                  </div>
                </div>
                <DragDropContext
                  onDragEnd={(params) => {
                    handeDragAndDrop(params);
                  }}
                >
                  <div
                    className={styles.taskListWrapper}
                    style={{ fontFamily: fontFamily, fontSize: fontSize }}
                  >
                    <Droppable droppableId={task._id}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {task.tactics?.map((t, tId) => (
                            <Draggable
                              key={t._id}
                              draggableId={t._id}
                              index={tId}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    boxShadow: snapshot.isDragging
                                      ? "0 0 .4rem #666"
                                      : "none",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <div
                                    {...provided.dragHandleProps}
                                    className={styles.taskListTableRow}
                                    style={{ height: rowHeight - 1.2 }}
                                    key={`${t.id}row`}
                                  >
                                    <div
                                      className={styles.taskListCell}
                                      style={{
                                        minWidth: "50px",
                                        maxWidth: "50px",
                                        borderRight: "1px solid gray",
                                      }}
                                      title={t.name}
                                    >
                                      <div>
                                        <div style={{ overflowX: "auto" }}>
                                          {t.name}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={styles.taskListCell}
                                      style={{
                                        minWidth: "260px",
                                        maxWidth: "260px",
                                        overflowX: "auto",
                                        borderRight: "1px solid gray",
                                      }}
                                    >
                                      &nbsp;{t.tactic.split("|")[0]}
                                    </div>
                                    &nbsp;
                                    <div
                                      className={styles.taskListCell}
                                      style={{
                                        textAlign: "center",
                                        minWidth: rowWidth,
                                        maxWidth: rowWidth,
                                        overflowX: "auto",
                                        outline: "none",
                                        // backgroundColor: "blue", width: "100%"
                                      }}
                                      onClick={(e) => {
                                        e.target.setAttribute(
                                          "contentEditable",
                                          true
                                        );
                                      }}
                                      onBlur={(e) => {
                                        {
                                          e.target.setAttribute(
                                            "contentEditable",
                                            false
                                          );
                                          handlePersonAccountableChange(
                                            e.target.innerText,
                                            tas?._id,
                                            t?.id,
                                            t?.personAccountable
                                          );
                                        }
                                      }}
                                      // onInput={(e) => handleGoalNameChange(e.target.innerText, index, goalIndex)}
                                      dangerouslySetInnerHTML={{
                                        __html: t?.personAccountable,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
              </div>
              <div className={styles.addMorecontainer}>
                <button
                  className={styles.addMore}
                  onClick={() => handleAddMore(task._id)}
                >
                  Add More
                </button>
              </div>
              <></>
              {openForm === task._id && (
                <TaskForm
                  pId={tas?._id}
                  gId={task?._id}
                  setForm={setOpenForm}
                  setGantData={setGantData}
                />
              )}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
