import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import arrow from "../../src/assets/images/breadcrumbsArrow.png";
import { visitedPath } from "../components/shared/visitedPath";
import { AuthContext, PlanContext } from "../app/context";
const Onepageplan = () => {
  const location = useLocation();
  const strategicData = location.state?.strategicData;
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  // const apiUrl = "http://localhost:3333";
  // const userGoals = location?.state?.userGoals;
  const StrategicFocus = location?.state?.StrategicFocus;
  // const userPriorities = location?.state?.userPriorities;
  // console.log(StrategicFocus)
  // console.log(strategicData, " strategicData- ------------------------ ");
  // console.log(userGoals, " userGoals- ------------------------ ");
  // console.log(StrategicFocus, " StrategicFocus- ------------------------ ");
  // console.log(userPriorities, " userPriorities- ------------------------ ");

  const { plan } = useContext(PlanContext);
  const { user } = useContext(AuthContext);
  const [userGoals, setUserGoals] = useState();
  const [finalThemeData, setFinalThemeData] = useState([]);
  const [planData, setPlanData] = useState("");
  const [data, setData] = useState([]);
  const [vision, setvision] = useState("");
  const [mision, setmision] = useState("");
  const [value, setvalue] = useState([]);
  const [purpose, setpurpose] = useState("");
  const [tacticData, setTacticData] = useState([]);
  // console.log(plan);
  // console.log(planData);
  const navigate = useNavigate();
  useEffect(() => {
    visitedPath(plan?._id, {
      title: "One Page Plan",
      path: "/onepageplan",
    });
  }, [location.pathname]);

  const handleGetData = async () => {
    // console.log("first")
    try {
      const response = await axios.get(`${apiUrl}/ganttest/${plan._id}`);
      setTacticData(response.data);
      // console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleGetData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}/answer/getstatements`, {
          pk: plan._id,
        });
        // console.log(response.data);
        setData(response.data);
        const answers = response.data.answer;
        answers.map((item) => {
          if (item.category === "Purpose") {
            setpurpose(item.answer);
          } else if (item.category === "Vision") {
            setvision(item.answer);
          } else if (item.category === "Mission") {
            setmision(item.answer);
          } else if (item.category === "Values") {
            setvalue((prev) => [...prev, item.answer]);
          }
        });
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, []);

  let currentLink = [];
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink.push(crumb);
      return (
        <div className="crumb" key={crumb}>
          <p>{crumb}</p>
        </div>
      );
    });

  const permissionFunction = (crumb, navigate) => {
    const permission = JSON.parse(localStorage.getItem("Member"))?.permissions
      ? JSON.parse(localStorage.getItem("Member"))?.permissions
      : user.role === "user" || user.role === "coach";

    // Define allowed permissions
    const allowedPermissions = [
      "90 days Plan",
      "One Page Plan",
      "Kanban Board",
      user.role === "user",
      user.role === "coach",
    ];

    // Check if the user's permission is allowed
    if (allowedPermissions.includes(permission)) {
      // Navigate based on the user's permission
      switch (permission) {
        case "90 days Plan":
          if (user.role === "user" || user.role === "coach") {
            navigate("/ninetydayplan");
          }
          break;
        case "One Page Plan":
          if (user.role === "user" || user.role === "coach") {
            navigate("/onepageplan");
          }
          break;
        case "Kanban Board":
          if (user.role === "user" || user.role === "coach") {
            navigate("/kanban");
          }
          break;
        default:
          // Handle unexpected permissions
          console.error("Unexpected permission:", permission);
          break;
      }
    } else {
      // If the user's permission is not allowed, navigate back
      navigate(-1);
    }
  };
  // useEffect(() => {
  //   const currentRoute = window.location.pathname.split('/').pop();
  //   permissionFunction(currentRoute, navigate);
  // }, [navigate]);

  useEffect(() => {
    const getGoals = async () => {
      try {
        const response = await fetch(`${apiUrl}/goal/${plan._id}`);

        if (!response.ok) {
          console.log("Network response was not ok");
        }
        const json = await response.json();
        setUserGoals(json[0]?.selectedGoals);
      } catch (error) {
        console.log(error.message);
      }
    };
    getGoals();
  }, []);

  const getFinalData = async () => {
    try {
      const response = await fetch(`${apiUrl}/finalTheme/${plan._id}`);

      if (!response.ok) {
        console.log("Network response was not ok");
      }
      let json = await response.json();
      // console.log(json)
      setFinalThemeData(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getFinalData();
  }, []);

  return (
    <>
      <Navbar />
      <div className="onepageplan">
        <div className="planContent">
          <div
            className="main-breadcrumps px-4 py-3"
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/plan")}
            >
              Plans
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/questionnaire")}
            >
              Questionnaire
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/goal")}
            >
              Goals
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/swot")}
            >
              Swot
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/medical")}
            >
              Theme
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/finaltheme")}
            >
              FinalTheme
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/tacticalplan")}
            >
              TacticalPlan
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/ninetydayplan")}
            >
              90DaysPlan
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>

            <p className="text-black">OnePagePlan</p>
            {/* <a style={{ display: 'flex' }} onClick={() => navigate('/dashboardland')}>
            DashboardLand
            <Icon
              icon="fa6-solid:greater-than"
              color="#0d4d80"
              width="18"
              height="18"

              className="icon"

              style={{
                marginLeft: "5px",
                marginRight: "5px"
              }}
            />
            {crumbs}
          </a> */}
          </div>
          <div className="title">
            <h6>One Page Plan </h6>
            <span>Title of the Analysis</span>
          </div>
          <div className="planCard">
            <div className="firstCard">
              {/* <div className="firstCard"> */}
              <div className="cardTitle">
                <span>vision</span>
              </div>

              <p>
                {vision ? vision : "No Vision Found"}
                {/* Lorem ipsum dolor sit amet consectetur. Sit semper mattis
                suspendisse eget molestie ullamcorper nunc{" "} */}
              </p>
            </div>
            <div className="firstCard">
              <div className="cardTitle">
                <span>purpose</span>
              </div>
              <p>
                {purpose ? purpose : "No Purpose Found"}
                {/* Lorem ipsum dolor sit amet consectetur. Sit semper mattis
                suspendisse eget molestie ullamcorper nunc{" "} */}
              </p>
            </div>
            <div className="firstCard">
              <div className="cardTitle">
                <span>mission</span>
              </div>
              <p>
                {mision ? mision : "No Mission Found"}
                {/* Lorem ipsum dolor sit amet consectetur. Sit semper mattis
                suspendisse eget molestie ullamcorper nunc{" "} */}
              </p>
            </div>
            <div className="lastCard">
              <div className="cardTitle">
                <span>values (how to behave as we execute the plan)</span>
              </div>
              {value.length ? (
                value?.map((val) => {
                  return <p>{val}</p>;
                })
              ) : (
                <p>No Values Found</p>
              )}
              {/* <p>{value}</p> */}
            </div>
          </div>
          <div className="mainSection">
            <div className="pageGoal">
              <div className="goalheader">
                <span>goals</span>
              </div>

              {userGoals?.map((goal, goalIndex) => {
                if (goal == "") return;

                return (
                  <div className="ms-2 d-flex align-items-center goalmiddle rounded">
                    {goal}
                  </div>
                );
              })}
            </div>
            <div className="pageGoal2">
              <div className="goalheader">
                <span>strategic focus</span>
              </div>

              {/* {StrategicFocus &&
                StrategicFocus.map((StrFocus, StrFocusIndex) => {
                  if (StrFocus == "") return;

                  return ( */}
              <div className="ms-2 d-flex align-items-center goalmiddle rounded">
                {finalThemeData?.themes
                  ? finalThemeData?.themes[1000]
                  : StrategicFocus
                  ? StrategicFocus[0]
                  : ""}
              </div>
              <div className="ms-2 d-flex align-items-center goalmiddle rounded">
                {StrategicFocus ? StrategicFocus[1] : ""}
              </div>
              {/* );
                })} */}
            </div>
            <div className="pageGoal3">
              <div className="goalheader">
                <span>strategic proirities</span>
              </div>
              <div className="strategicPriorities">
                {/* {userPriorities &&
                  userPriorities.map((priority, priorityIndex) => {
                    if (priority == "") return; */}

                {/* return ( */}
                {tacticData?.map((data, index) => {
                  // console.log(data)
                  return (
                    <>
                      <div className="customerExperience">
                        <div className="cutomerHeader">
                          <span>{data?.planName}</span>
                        </div>

                        <div className="customerBottom">
                          {data?.goals?.map((goalData, ind) => {
                            return (
                              <div>
                                <div className="bottom-div d-flex">
                                  <div className="left">
                                    {/* {console.log(goalData)} */}

                                    <span>Goal Name</span>
                                  </div>
                                  <div className="right d-flex">
                                    <span>{goalData?.goalName}</span>
                                  </div>
                                </div>
                                <div className="bottom-div2">
                                  <div
                                    className="d-flex"
                                    style={{ background: "#b0d4f1" }}
                                  >
                                    <div className="left">
                                      {/* console.log(tacticData) */}
                                      <span>quarter </span>
                                    </div>

                                    <div className="right ">
                                      <span>tactics/steps</span>
                                    </div>
                                  </div>
                                  {goalData.tactics.map(
                                    (tacticData, tacticIndex) => {
                                      // console.log(tacticData);

                                      return (
                                        <>
                                          <div className="d-flex">
                                            <div className="left">
                                              <span>{tacticData.name}</span>
                                            </div>

                                            <div className="right ">
                                              {/* <span>{tacticData.name}</span> */}
                                              <div className="d-flex flex-column gap-2 p-1">
                                                {/* <span>{new Date(tacticData.status).toLocaleString()}</span> */}
                                                <span>{tacticData.tactic}</span>

                                                {tacticData.personAccountable ? (
                                                  <span>
                                                    Person Accountable :{" "}
                                                    {
                                                      tacticData.personAccountable
                                                    }
                                                  </span>
                                                ) : null}
                                              </div>
                                              {/* {tacticData.end} */}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </div>

                                {/* {strategicData[
                                  "Input" + priorityIndex + goalIndex
                                ] &&
                                  strategicData[
                                    "Input" + priorityIndex + goalIndex
                                  ].map((data, dataIndex) => {
                                    // if ([data])
                                    //   return
                                    let parts = data.split("|");

                                    return (
                                      // <div className="ms-2 d-flex align-items-center goalmiddle"  >{parts[0]}</div>

                                      <div className="">
                                        <div className="bottom-div2">
                                          <div className="left">
                                            <span> {parts[1]}</span>
                                          </div>
                                          <div className="right">
                                            <span> {parts[0]} </span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })} */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="pageGoal ">
                <div className="goalheader">
                  <span>Results</span>
                </div>
                <div className="goalmiddle d-flex">
                  <div className="qtr1">
                    {" "}
                    <span>qTR 1</span>
                  </div>
                  <input
                    className="onepageinp w-100"
                    type="text"
                    name=""
                    id=""
                  />
                </div>
                <div className="goalmiddle d-flex">
                  <div className="qtr1">
                    {" "}
                    <span>qTR 1</span>
                  </div>
                  <input
                    className="onepageinp w-100"
                    type="text"
                    name=""
                    id=""
                  />
                </div>
                <div className="goalmiddle d-flex">
                  <div className="qtr1">
                    {" "}
                    <span>qTR 1</span>
                  </div>
                  <input
                    className="onepageinp w-100"
                    type="text"
                    name=""
                    id=""
                  />
                </div>
                <div className="goalmiddle d-flex">
                  <div className="qtr1">
                    {" "}
                    <span>qTR 1</span>
                  </div>
                  <input
                    className="onepageinp w-100"
                    type="text"
                    name=""
                    id=""
                  />
                </div>
                <div className="goalmiddle d-flex rounded">
                  <div className="qtr1 rounded">
                    {" "}
                    <span>qTR 1</span>
                  </div>
                  <input
                    className="onepageinp w-100"
                    type="text"
                    name=""
                    id=""
                  />
                </div>
              </div>
            </div>
            <div className="saveButton">
              <button
                className="savesubmit"
                // onClick={() => {
                //   navigate("/tacticalPlan", {
                //     state: {
                //       strategicData,
                //       userGoals,
                //       StrategicFocus,
                //       userPriorities,
                //     },
                //   })
                // }}
              >
                save & submit
              </button>
              {/* <button className="print">Print</button> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Onepageplan;
