import React, { useRef, useEffect, useState } from "react";
import styles from "./tooltip.module.css";

export const Tooltip = ({
  tasks,
  task,
  rowHeight,
  rtl,
  svgContainerHeight,
  svgContainerWidth,
  scrollX,
  scrollY,
  arrowIndent,
  fontSize,
  fontFamily,
  headerHeight,
  taskListWidth,
  TooltipContent,
}) => {



  const tooltipRef = useRef(null);
  const [relatedY, setRelatedY] = useState(0);
  const [relatedX, setRelatedX] = useState(0);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltipHeight = tooltipRef.current.offsetHeight * 1.1;
      const tooltipWidth = tooltipRef.current.offsetWidth * 1.1;

      let newRelatedY = task.index * rowHeight - scrollY + headerHeight + 150;
      let newRelatedX;
      let z = 100
      let tooltipY = 100
      let planName = task?.planName;
      let goalName = task?.goalName
      let tacticId = task?._id


      tasks.forEach(t => {
        if (t._id === planName) {
          t.goals.forEach(ta => {
            if (ta.goalName === goalName) {
              ta.tactics.forEach(tactic => {
                if (tactic._id !== tacticId) {
                  tooltipY += 50;
                }
              });
              tooltipY += z;
            } else {
              z += 250;
            }
          });
        } else {
          z += 400;
        }
      });

      if (rtl) {
        newRelatedX =
          task.x1 - arrowIndent * 1.5 - tooltipWidth - scrollX;
        if (newRelatedX < 0) {
          newRelatedX = task.x2 + arrowIndent * 1.5 - scrollX;
        }
        const tooltipLeftmostPoint = tooltipWidth + newRelatedX;
        if (tooltipLeftmostPoint > svgContainerWidth) {
          newRelatedX = svgContainerWidth - tooltipWidth;
          newRelatedY += rowHeight;
        }
      } else {
        newRelatedX =
          task.x2 + arrowIndent * 1.5 + taskListWidth - scrollX;
        const tooltipLeftmostPoint = tooltipWidth + newRelatedX;
        const fullChartWidth = taskListWidth + svgContainerWidth;
        if (tooltipLeftmostPoint > fullChartWidth) {
          newRelatedX =
            task.x1 +
            taskListWidth -
            arrowIndent * 1.5 -
            scrollX -
            tooltipWidth;
        }
        if (newRelatedX < taskListWidth) {
          newRelatedX = svgContainerWidth + taskListWidth - tooltipWidth;
          newRelatedY += rowHeight;
        }
      }

      const tooltipLowerPoint = tooltipHeight + newRelatedY - scrollY;
      if (tooltipLowerPoint > svgContainerHeight - scrollY) {
        newRelatedY = svgContainerHeight - tooltipHeight;
      }
      setRelatedY(newRelatedY + tooltipY);
      setRelatedX(newRelatedX);
    }
  }, [
    tooltipRef,
    task,
    arrowIndent,
    scrollX,
    scrollY,
    headerHeight,
    taskListWidth,
    rowHeight,
    svgContainerHeight,
    svgContainerWidth,
    rtl,
  ]);

  return (
    <></>
    // <div
    //   ref={tooltipRef}
    //   className={
    //     relatedX
    //       ? styles.tooltipDetailsContainer
    //       : styles.tooltipDetailsContainerHidden
    //   }
    //   style={{ left: relatedX, top: relatedY }}
    // >
    //   <TooltipContent task={task} fontSize={fontSize} fontFamily={fontFamily} />
    // </div>
  );
};

export const StandardTooltipContent = ({ task, fontSize, fontFamily }) => {
  const style = {
    fontSize,
    fontFamily,
  };

  return (
    <div className={styles.tooltipDefaultContainer} style={style}>
      <b style={{ fontSize: fontSize + 6 }}>{`${task.name} ,${~~(
        (task.end.getTime() - task.start.getTime()) /
        (1000 * 60 * 60 * 24)
      )} Days`}</b>

      <p className={styles.tooltipDefaultContainerParagraph}>
        {!!task.progress && `Status: ${task.status}`}
      </p>
      <p className={styles.tooltipDefaultContainerParagraph}>
        {!!task.progress && `Person Responsible: ${task.personAccountable}`}
      </p>
      <p className={styles.tooltipDefaultContainerParagraph}>
        Start Date:
        {('0' + task.start.getDate()).slice(-2)}-
        {('0' + (task.start.getMonth() + 1)).slice(-2)}-
        {task.start.getFullYear()}
      </p>
      <p className={styles.tooltipDefaultContainerParagraph}>
        End Date:
        {('0' + task.end.getDate()).slice(-2)}-
        {('0' + (task.end.getMonth() + 1)).slice(-2)}-
        {task.end.getFullYear()}
      </p>
    </div>

  );
};
