import React, { useState, useEffect, useRef } from "react";
import "./priorityDropdown.scss";
import { Icon } from "@iconify/react";

const PriorityDropdown = ({ priorities, onPrioritySelect, currentQuestion }) => {
  const [isDropdownVisible, setDropdownVisibility] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(
    "Move to Strategic Priorities"
  );

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    // console.log("first");

    setDropdownVisibility(!isDropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisibility(false);
  };

  const handleThemeSelect = (theme, index) => {
    console.log("==================", theme, index)
    setSelectedTheme(theme);
    closeDropdown();
    // const index = 0;
    onPrioritySelect(theme, index, currentQuestion);

  };

  // useEffect(() => {
  //   console.log(selectedTheme);

  // }, [selectedTheme])
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Cleanup the event listener on component unmount
  return (
    <>
      <div className="priorityDropdown">
        <div className="first-goal">
          <div className="inputbox" onClick={toggleDropdown} ref={dropdownRef}>
            <div className="leftside">{selectedTheme}</div>
            <div className="rightside">
              {isDropdownVisible ? (
                <Icon
                  icon="fe:arrow-down"
                  color="white"
                  width="18"
                  height="18"
                  rotate={2}
                />
              ) : (
                <Icon
                  icon="fe:arrow-down"
                  color="white"
                  width="18"
                  height="18"
                />
              )}
            </div>
            {isDropdownVisible && (
              <div className="dropdown" onClick={closeDropdown}>
                {priorities.map((priority, index) => (
                  <>
                    <div
                      key={index}
                      className="firstdropdown"
                      onClick={() => {
                        handleThemeSelect(priority, index)
                      }}
                    >
                      <span>{priority}</span>
                    </div>
                  </>

                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PriorityDropdown;
