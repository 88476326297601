import React from 'react';
import "./drag.scss"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';
const DraggableDropdown = ({ goals, tasks, setTasks }) => {
    const apiUrl = process.env.REACT_APP_BACKEND_API

    const onGoalOrderChange = async (result) => {
        console.log(result)
        if (!result.destination) return; // Item was dropped outside the droppable area

        const { source, destination } = result;
        const sourceIndex = source.index;
        const destinationIndex = destination.index;
        let priorityId = ""
        // Find the task and goal whose order changed
        let updatedTasks = tasks.map(async task => { // Making the map function async
            // Check if the current task contains the source goal
            const sourceGoalIndex = task.goals.findIndex(goal => goal._id === result.draggableId);
            if (sourceGoalIndex !== -1) {
                priorityId = task?._id
                // Clone the task to prevent mutating the original state
                const updatedTask = { ...task };
                // Remove the goal from its previous position
                const [removed] = updatedTask.goals.splice(sourceIndex, 1);
                // Insert the goal into its new position
                updatedTask.goals.splice(destinationIndex, 0, removed);
                try {
                    const resp = await axios.post(`${apiUrl}/ganttest/reorderGoals`, {
                        // const resp = await axios.post("http://localhost:3333/ganttest/reorderGoals", {
                        priorityId, sourceIndex, destinationIndex
                    })

                    // console.log(resp)
                } catch (error) {
                    console.log(error.message)
                }
                return updatedTask;
            } else {
                return task;
            }
        });

        // Wait for all async tasks to complete
        updatedTasks = await Promise.all(updatedTasks);

        // console.log(updatedTasks)
        // Update the tasks state with the new order
        setTasks(updatedTasks);
    };
    return (
        <div className='abc'>
            <div className="dropdown">
                <button className="dropbtn text-white">Reorder Goals</button>
                <div className="dropdown-content">
                    {/* <p style={{ color: "black", fontWeight: "300" }}>Drag to Reorder goals</p> */}
                    <DragDropContext onDragEnd={onGoalOrderChange}>
                        <Droppable droppableId="goals">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {goals?.map(({ _id, goalName }, index) => (
                                        <Draggable key={_id} draggableId={_id} index={index}>
                                            {(provided, snapshot) => (

                                                <div ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="draggable"
                                                >

                                                    {goalName}
                                                </div>

                                            )}
                                        </Draggable>

                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                </div>
            </div>
        </div>

    );
};
export default DraggableDropdown;