import React from "react";
import "../../style/main.scss";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footerContent">
        <span>© {new Date().getFullYear()} BTM - All Rights Reserved </span>
      </div>
    </div>
  );
};

export default Footer;
