import React, { useEffect, useRef, useState } from "react";
import { Bar } from "./bar/bar";
import { BarSmall } from "./bar/bar-small";
import { Milestone } from "./milestone/milestone";
import { Project } from "./project/project";
import style from "./task-list.module.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const TaskItem = (props) => {
  const {
    z,
    task,
    arrowIndent,
    isDelete,
    taskHeight,
    isSelected,
    rtl,
    onEventStart,
  } = props;
  const textRef = useRef(null);
  const [taskItem, setTaskItem] = useState(<div />);
  const [isTextInside, setIsTextInside] = useState(true);

  useEffect(() => {
    switch (task.typeInternal) {
      case "milestone":
        setTaskItem(<Milestone {...props} />);
        break;
      case "project":
        setTaskItem(<Project {...props} />);
        break;
      case "smalltask":
        setTaskItem(<BarSmall {...props} />);
        break;
      default:
        setTaskItem(<Bar {...props} />);
        break;
    }
  }, [task, isSelected]);

  useEffect(() => {
    if (textRef.current) {
      setIsTextInside(textRef.current.getBBox().width < task.x2 - task.x1);
    }
  }, [textRef, task]);

  const getX = () => {
    const width = task.x2 - task.x1;
    const hasChild = task.barChildren.length > 0;
    if (isTextInside) {
      return task.x1 + width * 0.5;
    }
    if (rtl && textRef.current) {
      return (
        task.x1 -
        textRef.current.getBBox().width -
        arrowIndent * +hasChild -
        arrowIndent * 0.2
      );
    } else {
      return task.x1 + width + arrowIndent * +hasChild + arrowIndent * 0.2;
    }
  };

  const Tooltipcontent = () => {
    return (
      <div style={{ textAlign: "left" }}>
        <b>{`${task.name} ,${~~(
          (task.end.getTime() - task.start.getTime()) /
          (1000 * 60 * 60 * 24)
        )} Days`}</b>
        <hr />
        <p>
          {!!task.progress && `Status: ${task.status}`}
        </p>
        <p>
          {!!task.progress && `Person Responsible: ${task.personAccountable}`}
        </p>
        <p>
          Start Date:
          {('0' + task.start.getDate()).slice(-2)}-
          {('0' + (task.start.getMonth() + 1)).slice(-2)}-
          {task.start.getFullYear()}
        </p>
        <p>
          End Date:
          {('0' + task.end.getDate()).slice(-2)}-
          {('0' + (task.end.getMonth() + 1)).slice(-2)}-
          {task.end.getFullYear()}
        </p>
      </div>
    )
  }

  return (
    <OverlayTrigger
      key={'tooltip-' + task.id}
      placement="top"
      overlay={
        <Tooltip id={`tooltip-${task.id}`}>
          <Tooltipcontent />
        </Tooltip>
      }
    >
      <g
        onKeyDown={(e) => {
          switch (e.key) {
            case "Delete": {
              if (isDelete) onEventStart("delete", task, e);
              break;
            }
          }
          e.stopPropagation();
        }}
        // onMouseEnter={(e) => {
        //   onEventStart("mouseenter", task, e);
        // }}
        // onMouseLeave={(e) => {
        //   onEventStart("mouseleave", task, e);
        // }}
        onDoubleClick={(e) => {
          onEventStart("dblclick", task, e);
        }}
        onClick={(e) => {
          onEventStart("click", task, e);
        }}
        onFocus={() => {
          onEventStart("select", task);
        }}
      >
        {taskItem}
        <text
          x={getX()}
          y={task.y + taskHeight * 0.5 + z}
          className={
            isTextInside
              ? style.barLabel
              : style.barLabel && style.barLabelOutside
          }
          ref={textRef}
        >
          {("0" + task.start.getDate()).slice(-2)}-
          {("0" + (task.start.getMonth() + 1)).slice(-2)}-
          {task.start.getFullYear()} , {("0" + task.end.getDate()).slice(-2)}-
          {("0" + (task.end.getMonth() + 1)).slice(-2)}-{task.end.getFullYear()}
        </text>
      </g>
    </OverlayTrigger>
  );
};
