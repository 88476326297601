import React from "react";
import Navbar from "../../shared/navbar";
import Footer from "../../shared/footer";
import { Container, Row, Col, Nav, Table } from "react-bootstrap";
// import { House, FileText, Gear } from "react-bootstrap-icons";
const AdminDashboardLayout = ({ children }) => {
  return (
    <div>
      <Navbar />
      <Container fluid className="pt-5">
        <Row>
          {/* Sidebar */}
          <Col md={2} className="bg-light vh-100 p-3">
            <h4 className="text-center p-3">Dashboard</h4>
            <ul className="flex-column">
              <li href="#home" className="d-flex align-items-center">
                {/* <House className="me-2" /> */}
                Home
              </li>
              <li href="#reports" className="d-flex align-items-center">
                {/* <FileText className="me-2" />  */}
                Reports
              </li>
              <li href="#settings" className="d-flex align-items-center">
                {/* <Gear className="me-2" /> */}
                Settings
              </li>
            </ul>
          </Col>

          {/* Content Area */}
          <Col md={10} className="p-4">
            {children}
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default AdminDashboardLayout;
