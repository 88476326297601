import React, { useEffect, useState, useRef } from "react";
// import { Task } from "gantt-task-react";
import "./Modal.css";
import { Button, Modal } from "react-bootstrap";

const CustomModal = ({ task, showModal, setShowModal, getGanttData }) => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const [startDate, setStartDate] = useState(task.start);
  const [endDate, setEndDate] = useState(task.end);
  const [personAccountable, setPersonAccountable] = useState(
    task?.personAccountable
  );
  const [selectedOption, setSelectedOption] = useState(task?.status);

  const handleSubmit = async () => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    try {
      setShowModal(false);
      const requestBody = JSON.stringify({
        start: startDateObj,
        end: endDateObj,
        status: selectedOption,
        personAccountable: personAccountable,
      });
      // console.log(requestBody);
      let response = await fetch(
        `${apiUrl}/ganttest/update/${task.id}/${task.planName}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: requestBody,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      getGanttData();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setStartDate(task.start);
    setEndDate(task.end);
    setPersonAccountable(task.personAccountable);
    setSelectedOption(task.status);
  }, [task]);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <label htmlFor="startDate">Start Date</label>
        <input
          type="date"
          className="form-control"
          id="startDate"
          value={startDate.toISOString().substring(0, 10)}
          onChange={(e) => setStartDate(new Date(e.target.value))}
          required
        />
        <label htmlFor="endDate">End Date</label>
        <input
          type="date"
          className="form-control"
          id="endDate"
          value={endDate.toISOString().substring(0, 10)}
          onChange={(e) => setEndDate(new Date(e.target.value))}
          required
        />
        <label htmlFor="personAccountable">Person Accountable</label>
        <input
          type="text"
          className="form-control"
          id="personAccountable"
          value={personAccountable}
          onChange={(e) => setPersonAccountable(e.target.value)}
          placeholder="Enter Name"
          required
        />
        <label htmlFor="status">Status</label>
        <select
          className="form-select"
          id="status"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          required
        >
          {/* <option value="">Select Option</option> */}
          <option value="scheduled">Scheduled</option>
          <option value="onTrack">On Track</option>
          <option value="completed">completed</option>
          <option value="wont_be_done">won't be done</option>
          <option value="delayed">Delayed</option>
        </select>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
