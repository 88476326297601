import React, { useEffect, useState } from "react";
import "./Toggle2.scss";



const Toggle2 = ({ questionKey, onToggleChange, setSpecificQuestionKey, foundedAnswer }) => {

  // console.log(questionKey);
  // console.log(onToggleChange);
  // console.log(setSpecificQuestionKey);
  // console.log(foundedAnswer);
  console.log(questionKey)

  const [selectedOption, setSelectedOption] = useState("");
  //const [questionKeys,setQuestionKeys]=useState([]);
  //   useEffect(()=>{
  // setQuestionKeys((prev)=>[...prev,])
  //   },[questionKey])
  console.log("The founded answers are: ", foundedAnswer)
  const check = foundedAnswer?.filter((e) => e.key === questionKey);
  // console.log("The key:", questionKey)
  //console.log("The check: ",check)

  const handleToggle = (option) => {
    setSelectedOption(option);
    onToggleChange(questionKey, option);
    setSpecificQuestionKey((prev) => [...prev, questionKey]);
  };

  const option = () => {
    if (selectedOption === "yes") {
      return 0
    }
    else if (selectedOption === "no") {
      return 1
    }
    //return selectedOption === "yes" ? 0 : 1;
  };

  useEffect(() => {
    if (foundedAnswer && check?.length > 0) {
      setSelectedOption(check[0]?.answer)
    }
  }, [foundedAnswer])
  // console.log("The option is: ", selectedOption)

  //   useEffect(() => {
  //     if (foundedAnswer) {
  //       setSelectedOption(foundedAnswer.answer.answer);
  //     }
  //   }, []);
  // console.log("The option is: ", selectedOption);

  return (
    <div className={`checkbox2 toggle2-state-${option()}`}>
      <label className="slide" htmlFor="checkbox_toggle2">
        <div
          className={`toggle toggle2-color-${option()} toggle2-state-${option()}`}
          onClick={() => handleToggle("yes")}
        ></div>
        <div
          className={`toggle toggle2-color-${option()} toggle2-state-${option()}`}
          onClick={() => handleToggle("no")}
        ></div>
        <div className="text" onClick={() => handleToggle("yes")}>
          {
            questionKey == "csat_score" ? "Strong" : "Yes"
          }
        </div>
        <div className="text" onClick={() => handleToggle("no")}>
          {
            questionKey == "csat_score" ? "Weak" : "No"
          }
        </div>
      </label>
    </div>
  );
};

export default Toggle2;