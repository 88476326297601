import React, { useEffect, useState } from "react";
import KanbanTask from "./KanbanTask";
import "./KanbanColumn.css";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { FaPlus } from "react-icons/fa6";
import axios from "axios";
import { Accordion } from "react-bootstrap";
import KanbanTactics from "./KanbanTactics";

const KanbanColumn = (props) => {
    const [activeKey, setActiveKey] = useState(0);
    const { gantData, pIndex } = props

    const handleAccordionToggle = (key) => {
        setActiveKey(key === activeKey ? null : key);
    };


    return (
        <div className="accordan col-12">

            <Accordion defaultActiveKey={0}
                className="accordianParent "
                onSelect={handleAccordionToggle}>
                <Accordion.Item
                    className="accordianItem1"
                    eventKey={1}
                >
                    <Accordion.Header className="accordianHeader">
                        <div>{gantData.planName}</div>
                        <div className="svgContainer">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="12"
                                viewBox="0 0 19 12"
                                fill="none"
                                className={`accordionIcon ${activeKey === pIndex ? "active" : ""
                                    }`}
                            >
                                <path
                                    d="M2 2L9.5 9L17 2"
                                    stroke={activeKey === pIndex ? "white" : "white"}
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="">
                            <div className="KanbanColumn-main">
                                <div className="kanbanColoumn-header">
                                    {/* priority name */}
                                    <h2 className="KanbanColumn-name">{gantData.planName}</h2>
                                </div>
                                <div className="w-100 ">
                                    {gantData.goals.map((goalData, goalIndex) => {
                                        return (
                                            <div key={goalIndex}>
                                                <div className="kanban-goal-container">
                                                    <span className="kanban-goal">{goalData.goalName.toUpperCase()}</span>
                                                </div>
                                                <KanbanTactics goalId={goalData._id} tacticData={goalData?.tactics} goalIndex={goalIndex} priorityIndex={pIndex} {...props} />
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>

                        </div>
                    </Accordion.Body>

                </Accordion.Item>


            </Accordion>





        </div>
    );
};

export default KanbanColumn;