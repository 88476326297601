import React, { useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Dropdownsmall from "../shared/smalldropdown/Dropdownsmall";
import "../shared/smalldropdown/Dropdownsmall.scss";
import Actiondropdown from "../shared/actiondropdown/Actiondropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addPlan } from "../../features/plan/planSlice";
import axios from "axios";
import { click } from "@testing-library/user-event/dist/click";
import {
  AuthContext,
  PlanContext,
  selectDropdownContext,
} from "../../app/context";
import { Modal } from "react-bootstrap";
const Analysis = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const { user } = useContext(AuthContext);
  const { setPlanData } = useContext(PlanContext);
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [show, setShow] = useState(false);
  const [members, setMembers] = useState(false);
  const [loader, setLoader] = useState(true);
  const location = useLocation();

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await fetch(`${apiUrl}/plan/user/${user?.userId}`);
      const data = await response.json();
      // console.log(data)
      setLoader(false);
      setPlans(data?.plans);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  const handleOnPlanClick = (item) => {
    setPlanData(item);
    navigate("/questionnaire");
  };
  let currentLink = [];
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink.push(crumb);
      return (
        <div className="crumb" key={crumb}>
          <p>{crumb}</p>
        </div>
      );
    });

  return (
    <>
      <div className="analysis">
        <div className="analysis-content">
          <div
            className="analysis-title"
            style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
          >
            <p>{crumbs}</p>
          </div>
          <div className="scrollbar">
            {loader ? (
              <div class="spinner"></div>
            ) : (
              <>
                {/* {console.log(plans)} */}
                {plans?.length === 0 ? (
                  <p>Please created any plan</p>
                ) : (
                  plans?.map((item, index) => (
                    <div className="first-analysis" key={index}>
                      <>
                        <div className="title">
                          <p>
                            Title <span className="dot">:</span>{" "}
                            <span> {item.planName}</span>
                          </p>
                        </div>
                        <div className="title">
                          <p>
                            Assigned to <span className="dot">:</span>{" "}
                            <span> {item.planUser}</span>
                          </p>
                        </div>
                        <div className="title">
                          <Actiondropdown
                            plan={item}
                            id={item._id}
                            setPlans={setPlans}
                            setPlanData={setPlanData}
                          />
                        </div>
                        <div className="">
                          <div
                            className="arrow"
                            onClick={() => {
                              handleOnPlanClick(item);
                            }}
                          >
                            <Icon
                              icon="gridicons:arrow-right"
                              width="22px"
                              height="22px"
                              style={{ color: "#136CB2", marginLeft: "5px" }}
                            />
                          </div>
                        </div>
                      </>
                    </div>
                  ))
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Analysis;
