import React from "react";
import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";
import Result from "../components/questionresult/result";

const Questionresult = () => {
  return (
    <>
      <Navbar />
      <Result />
      <Footer />
    </>
  );
};

export default Questionresult;
