/**
 * Enum defining various view modes.
 */
const ViewMode = {
    Hour: "Hour",
    QuarterDay: "Quarter Day",
    HalfDay: "Half Day",
    Day: "Day",
    Week: "Week",
    Month: "Month",
    QuarterYear: "QuarterYear",
    Year: "Year",
};

/**
 * Type representing different types of tasks.
 */
const TaskType = "task" | "milestone" | "project";

/**
 * Interface representing a task.
 */
const Task = {
    tactic: "",
    status: "",
    personAccountable: "",
    id: "",
    type: TaskType,
    name: "",
    start: new Date(),
    end: new Date(),
    progress: 0,
    styles: {
        backgroundColor: "",
        backgroundSelectedColor: "",
        progressColor: "",
        progressSelectedColor: "",
    },
    isDisabled: false,
    project: "",
    dependencies: [],
    hideChildren: false,
    displayOrder: 0,
};

/**
 * Interface representing options for Gantt chart events.
 */
const EventOption = {
    timeStep: undefined,
    onSelect: (task, isSelected) => { },
    onDoubleClick: (task) => { },
    onClick: (task) => { },
    onDateChange: (task, children) => { },
    onProgressChange: (task, children) => { },
    onDelete: (task) => { },
    onExpanderClick: (task) => { },
};

/**
 * Interface representing display options for the Gantt chart.
 */
const DisplayOption = {
    viewMode: ViewMode.Day,
    viewDate: new Date(),
    preStepsCount: 0,
    locale: "",
    rtl: false,
};

/**
 * Interface representing styling options for the Gantt chart.
 */
const StylingOption = {
    headerHeight: 0,
    columnWidth: 0,
    listCellWidth: "",
    rowHeight: 0,
    ganttHeight: 0,
    barCornerRadius: 0,
    handleWidth: 0,
    fontFamily: "",
    fontSize: "",
    barFill: 0,
    barProgressColor: "",
    barProgressSelectedColor: "",
    barBackgroundColor: "",
    barBackgroundSelectedColor: "",
    projectProgressColor: "",
    projectProgressSelectedColor: "",
    projectBackgroundColor: "",
    projectBackgroundSelectedColor: "",
    milestoneBackgroundColor: "",
    milestoneBackgroundSelectedColor: "",
    arrowColor: "",
    arrowIndent: 0,
    todayColor: "",
    TooltipContent: ({ task, fontSize, fontFamily }) => { },
    TaskListHeader: ({ headerHeight, rowWidth, fontFamily, fontSize }) => { },
    TaskListTable: ({
        rowHeight,
        rowWidth,
        fontFamily,
        fontSize,
        locale,
        tasks,
        selectedTaskId,
        setSelectedTask,
        onExpanderClick,
    }) => { },
};

/**
 * Interface representing props for the Gantt chart component.
 */
const GanttProps = {
    tasks: [],
    ...EventOption,
    ...DisplayOption,
    ...StylingOption,
};

module.exports = { ViewMode, TaskType, Task, EventOption, DisplayOption, StylingOption, GanttProps };
