import React, { useRef, useEffect, useState } from "react";
import Grid from "../grid/grid";
import { Calendar } from "../calander/calendar";
import { TaskGanttContent } from "./task-gantt-content";
import styles from "./gantt.module.css";

export const TaskGantt = ({
    tasks,
    gridProps,
    calendarProps,
    barProps,
    ganttHeight,
    scrollY,
    scrollX,
}) => {

    const ganttSVGRef = useRef(null);
    const horizontalContainerRef = useRef(null);
    const verticalGanttContainerRef = useRef(null);
    const newBarProps = { ...barProps, svg: ganttSVGRef };
    useEffect(() => {
        if (horizontalContainerRef.current) {
            horizontalContainerRef.current.scrollTop = scrollY;
        }
    }, [scrollY]);

    useEffect(() => {
        if (verticalGanttContainerRef.current) {
            verticalGanttContainerRef.current.scrollLeft = scrollX;
        }
    }, [scrollX]);
    // console.log(task);
    // console.log((barProps.rowHeight * barProps.tasks.length) + 500);
    let h = 0
    for (const task of tasks) {
        h += 50
        for (const tas of task.goals) {
            h += 150
            for (const ta of tas.tactics) {
                h += 50
            }
        }
    }
    // console.log(tasks);
    return (
        <div
            className={styles.ganttVerticalContainer}
            ref={verticalGanttContainerRef}
            dir="ltr"
        >

            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={gridProps.svgWidth}
                height={calendarProps.headerHeight}
                fontFamily={barProps.fontFamily}
            >
                <Calendar {...calendarProps} />
            </svg>
            <div
                ref={horizontalContainerRef}
                className={styles.horizontalContainer}
                style={
                    ganttHeight
                        ? { height: ganttHeight, width: gridProps.svgWidth }
                        : { width: gridProps.svgWidth }
                }
            >

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={gridProps.svgWidth}
                    // height={(barProps.rowHeight * barProps.tasks.length)}
                    height={h}
                    fontFamily={barProps.fontFamily}
                    ref={ganttSVGRef}
                // style={{ marginTop: "150px" }}
                >

                    <Grid {...gridProps} />
                    <TaskGanttContent {...newBarProps} />
                </svg>

            </div>
        </div>
    );
};
