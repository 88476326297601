import React from "react";
import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";
import Strategic from "../components/finaltheme/strategic";

const Finaltheme = () => {
  return (
    <div>
      <Navbar />
      <Strategic/>
      <Footer />
    </div>
  );
};

export default Finaltheme;
