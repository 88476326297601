import React, { useState } from "react";
import "./chat.scss";
import Assistancelogo from "../../assets/images/chatassistan-img.svg";
import Chatopen from "../../assets/images/chatopen-logo.svg";
import Chatcross from "../../assets/images/crossimg.svg";
import Voice from "../../assets/images/voice.svg";
import Sendchatimg from "../../assets/images/send.svg";
import Promptlogo from "../../assets/images/prompt-logochat.svg";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { FaTelegramPlane } from "react-icons/fa";

const Chat = ({ priority, index, goals, setData, data, handleAiGenerate }) => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;

  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isCrossVisible, setIsCrossVisible] = useState(false);
  const [chatIndex, setChatIndex] = useState(1);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (index) => {
    setChatIndex(index);
    setShow2(true);
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
    setIsCrossVisible(!isCrossVisible);
  };
  const closeChat = () => {
    setIsOpen(false);
    setIsCrossVisible(false);
  };
  const [userInput, setUserInput] = useState("");

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
    if (event.key === "Enter") {
      if (event.target.value !== "") {
        chatSystem();
        setUserInput("");
      }
    }
  };
  const chatSystem = async () => {
    if (userInput !== "") {
      setMessages((prev) => [...prev, { type: "user", content: userInput }]);

      try {
        const response = await axios.post(
          `${apiUrl}/chatbot/chatsystem`,

          {
            userInput: userInput,
          }
        );

        setUserInput("");

        const parsedResponse = JSON.parse(response.data.chat);
        console.log(parsedResponse);

        const reply = parsedResponse.answer;
        console.log(reply);

        setMessages((prev) => [...prev, { type: "gpt", content: reply }]);

        setUserInput("");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const [selectedGoal, setSelectedGoal] = useState(0);

  const handleSelectChange = (event) => {
    setSelectedGoal(event.target.value);
  };

  return (
    <>
      <div className={`chat-section ${isOpen ? "expanded" : ""}`}>
        <div className="chat-content">
          <div className="chat-section">
            <div className="left-section">
              <img src={Assistancelogo} alt="..." />
              <span>AI Chat Assistance</span>
            </div>
            <div className="right-section">
              {isCrossVisible ? (
                <img src={Chatcross} alt="Close" onClick={closeChat} />
              ) : (
                <img src={Chatopen} alt="..." onClick={toggleChat} />
              )}
            </div>
          </div>
          <div className={`chat-expanded-content ${isOpen ? "setHeight" : ""}`}>
            {isOpen && (
              <div className="chat-container">
                {messages.map((message, index) => (
                  <>
                    {message.type === "user" && (
                      <div className="first-promptanswer">
                        <div className="left-logo">
                          <img src={Promptlogo} alt="..." />
                        </div>
                        <div className="left-question">
                          <span>{message.content}</span>
                        </div>
                      </div>
                    )}
                    {message.type === "gpt" && (
                      <div className="first-prompt">
                        <button
                          className="left-question text-white"
                          style={{ backgroundColor: "#1573be" }}
                          onClick={() => handleShow2(index)}
                        >
                          more
                        </button>

                        <div className="left-question">
                          <div className="left-question">
                            <span>{message.content}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
          </div>
          {isOpen && (
            <div className="bottom-parent">
              <div className="bottom-section">
                <div className="bottom-left ">
                  <input
                    type="text"
                    placeholder="Type your Message..."
                    value={userInput}
                    onChange={handleInputChange}
                    onKeyDown={handleInputChange}
                  />
                </div>
                <div className="bottom-right">
                  {/* <img src={Voice} alt=".." /> */}
                  <FaTelegramPlane
                    size={30}
                    color="#067FB2"
                    onClick={chatSystem}
                  />
                  {/* <img src={Sendchatimg} alt=".." onClick={chatSystem} /> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* model for chat ai */}
      <Modal
        show={show2}
        onHide={handleClose2}
        dialogClassName="modal-90w"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Continue with</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="modalBody">
          <div className="modal-header">
            <div className="titleModal">
              {" "}
              <span>Continue with</span>
            </div>
            <div className="modlclose">
              <span onClick={handleClose2}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="12"
                    fill="url(#paint0_linear_1654_8171)"
                  />
                  <path
                    d="M11.5 12.6711L7.40114 16.77C7.24778 16.9233 7.0526 17 6.81559 17C6.57858 17 6.3834 16.9233 6.23004 16.77C6.07668 16.6166 6 16.4214 6 16.1844C6 15.9474 6.07668 15.7522 6.23004 15.5989L10.3289 11.5L6.23004 7.40114C6.07668 7.24778 6 7.0526 6 6.81559C6 6.57858 6.07668 6.3834 6.23004 6.23004C6.3834 6.07668 6.57858 6 6.81559 6C7.0526 6 7.24778 6.07668 7.40114 6.23004L11.5 10.3289L15.5989 6.23004C15.7522 6.07668 15.9474 6 16.1844 6C16.4214 6 16.6166 6.07668 16.77 6.23004C16.9233 6.3834 17 6.57858 17 6.81559C17 7.0526 16.9233 7.24778 16.77 7.40114L12.6711 11.5L16.77 15.5989C16.9233 15.7522 17 15.9474 17 16.1844C17 16.4214 16.9233 16.6166 16.77 16.77C16.6166 16.9233 16.4214 17 16.1844 17C15.9474 17 15.7522 16.9233 15.5989 16.77L11.5 12.6711Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1654_8171"
                      x1="0"
                      y1="12"
                      x2="24"
                      y2="12"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#00A2D6" />
                      <stop offset="1" stop-color="#0D4D80" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
            </div>
          </div>
          <div className="modalCard">
            <div className="modalContent px-2 py-4">
              <div className="d-flex gap-2">
                <select
                  className="chatGoalSelect"
                  onChange={handleSelectChange}
                >
                  {goals?.map((item, itemIndex) => (
                    <option key={index} value={itemIndex}>
                      {item.goalName}
                    </option>
                  ))}
                </select>
                <div
                  className="goalModelBtn"
                  onClick={() => {
                    handleClose2();
                    handleAiGenerate(
                      priority,
                      index,
                      parseInt(selectedGoal),
                      messages[chatIndex]?.content
                    );
                  }}
                >
                  Generate steps with AI
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Chat;
