import React, { useState, useRef, useContext } from "react";
import Logos from "../assets/images/navlogo.png";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "../app/context";
import { Alert } from "react-bootstrap";

const OtpPage = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const { login } = useContext(AuthContext);
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);
  const [errorMessage, setErrorMessage] = useState("");
  const { email } = useParams();
  const inputRefs = useRef([]);
  const [show, setShow] = useState(false);

  const handleChange = (index, event) => {
    setErrorMessage("");
    setShow(false);
    const value = event.target.value;
    if (/^[0-9]*$/.test(value)) {
      setOtpDigits((prevDigits) => {
        const newDigits = [...prevDigits];
        newDigits[index] = value;
        return newDigits;
      });
      // Move to the next input field
      if (value !== "" && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleContinue = async () => {
    setShow(false);
    const otpCode = otpDigits.join("");
    try {
      if (otpCode.length === 4 && email) {
        const resp = await axios.post(`${apiUrl}/user/verify-otp`, {
          email,
          otp: otpCode,
        });
        if (resp.data.success) {
          const decoded = await jwtDecode(resp.data.token);
          const userObj = {
            email: decoded.email,
            token: resp.data.token,
            userId: decoded.userId,
            userName: decoded.userName,
            role: decoded.role,
          };

          login(userObj);
          navigate("/plan");
        } else {
          setErrorMessage(resp.data.message);
          setShow(true);
        }
      }
    } catch (error) {
      setShow(true);
      // toast.error(error.response.data.message);
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <div className="accountRecovery">
      <div className="signin-content">
        <div className="signup-logo">
          <img src={Logos} alt="signuplogo" className="custom" />
        </div>
        <div className="signup-card">
          <div className="signupcard-content">
            <div className="main-card">
              <div className="cardtitle">
                <div className="leftside">
                  <h6>Email Verification</h6>
                </div>
                <div className="rightside">
                  <h6>
                    To help keep your account safe, we want to <br /> make sure
                    that it’s really you trying to sign Up
                  </h6>
                </div>
              </div>
              <div className="accountRecovery-middle">
                <div className="middleTop">
                  <h6>Enter 4 Digits Code</h6>
                </div>
                <div className="middletext">
                  <h6>
                    Enter the 4 digits code that you <br />
                    received on your email.
                  </h6>
                  <h6 className="pt-2 fw-bold">{email}</h6>
                </div>
                <div className="middle-code">
                  {otpDigits.map((digit, index) => (
                    <div className="box1" key={index}>
                      <input
                        type="text"
                        value={digit}
                        onChange={(event) => handleChange(index, event)}
                        placeholder="0"
                        maxLength={1}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="card-bottom">
                <div className="signin-btn">
                  <button onClick={handleContinue}>Verify</button>
                </div>
              </div>
              {errorMessage && (
                <Alert show={show} variant="danger" className="mt-2">
                  {errorMessage}
                </Alert>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
