import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { dropdownOptions } from "./dropDownOptions";

const AddForm = ({ show, handleClose, headers, sheetName, handleAdd }) => {
  const [formData, setFormData] = useState(
    headers.reduce((acc, header) => {
      acc[header] = "";
      return acc;
    }, {})
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAdd(sheetName, formData);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Row
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <Form onSubmit={handleSubmit}>
            {headers
              .filter((key) => key !== "Item #")
              .map((header, index) => (
                <div className="my-2">
                  <Form.Group as={Row} key={index} controlId={`form${header}`}>
                    <Form.Label column sm="3">
                      {header}
                    </Form.Label>
                    <Col sm="9">
                      {dropdownOptions[header] ? (
                        <Form.Control
                          className="py-2"
                          as="select"
                          name={header}
                          value={formData[header]}
                          onChange={handleChange}
                        >
                          <option value="">Select One</option>
                          {dropdownOptions[header].map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Form.Control>
                      ) : (
                        <Form.Control
                          className="py-3"
                          type={header.includes("Date") ? "date" : "text"}
                          name={header}
                          value={formData[header]}
                          onChange={handleChange}
                        />
                      )}
                    </Col>
                  </Form.Group>
                </div>
              ))}
            <Button variant="primary" type="submit" className="mt-3">
              Add
            </Button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddForm;
