import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Cancel = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      Payment has been cancelled
    </div>
  );
};
export default Cancel;
