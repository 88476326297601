import React, { useContext, useEffect, useState } from "react";
import Dropdownsmall from "../shared/smalldropdown/Dropdownsmall";
import PriorityDropdown from "../shared/priorityDropdown/priorityDropdown";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Theme from "../../page/theme";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Icon } from "@iconify/react";
import axios from "axios";
import { MdAddComment } from "react-icons/md";
import arrow from "../../../src/assets/images/breadcrumbsArrow.png";
import { visitedPath } from "../shared/visitedPath";
import { PlanContext } from "../../app/context";
const Strategic = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const navigate = useNavigate();
  const location = useLocation();
  const { plan } = useContext(PlanContext);
  const [mainPriorityData, setMainPriorityData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDropdownn, setselectedDropdownn] = useState([]);
  const [subSelectedCards, setSubSelectedCards] = useState([]);
  const [finalThemeData, setFinalThemeData] = useState([]);

  //add path to plan visited path
  useEffect(() => {
    visitedPath(plan?._id, { title: "Final Theme", path: "/finaltheme" });
  }, [location.pathname]);

  const handleFinalThemeChanges = (data) => {
    setselectedDropdownn(data);
  };
  const fetchFinalTheme = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/finalTheme/${plan._id}`);
      const data = response.data.finalThemes[0]?.themes;
      setIsLoading(false);
      handleFinalThemeChanges(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchFinalTheme();
  }, []);
  const toggleCardSelection = (cardId) => {
    if (subSelectedCards.includes(cardId)) {
      setSubSelectedCards(subSelectedCards.filter((id) => id !== cardId));
    } else {
      setSubSelectedCards([...subSelectedCards, cardId]);
    }
  };

  const removeItem = (index) => {
    setselectedDropdownn((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
  };
  const PriorityCard = ({ index, data, selectedDropdownn }) => {
    const handlePrioritySelect = (selectedPriority, newIndex, question) => {
      const index = selectedDropdownn.findIndex(
        (item) => item.question === question
      );
      console.log(index);
      if (index !== -1) {
        const updatedIndex = mainPriorityData.findIndex(
          (priority, index) => priority === selectedPriority
        );
        setselectedDropdownn((prevState) => {
          const updatedState = [...prevState];
          updatedState[index].theme = selectedPriority;
          updatedState[index].index = updatedIndex;
          return updatedState;
        });
      }
    };
    const clearPriorityData = () => {
      setselectedDropdownn((prevData) => {
        const filteredData = prevData?.filter((item) => item.theme !== data);

        return filteredData;
      });
    };
    return (
      <>
        {isLoading ? (
          <div class="spinner"></div>
        ) : (
          <div
            key={data}
            className="leftcard1 col-md-6"
            // style={{ border: "2px solid" }}
          >
            <p>{data !== "" ? data : ""}</p>
            <div className="card-btn">
              <button className="additem" onClick={handleShow}>
                Add Items
              </button>
              <button
                className="clear-startegic"
                onClick={() => clearPriorityData()}
              >
                Clear {data}
              </button>
            </div>

            {isLoading ? (
              <div class="spinner"></div>
            ) : (
              selectedDropdownn?.map((item, index) => {
                if (data !== "" && data === item.theme) {
                  // console.log(item);

                  return (
                    <React.Fragment key={index}>
                      <div
                        className="card-footer"
                        style={{
                          background:
                            (item.swot == "Strength" &&
                              "var(--swotStrength)") ||
                            (item.swot == "Weakness" &&
                              "var(--swotWeakness)") ||
                            (item.swot == "Opportunity" &&
                              "var(--swotOpportunity)") ||
                            (item.swot == "Threat" && "var(--swotThreat)"),
                        }}
                      >
                        <span className="card-text">
                          {item?.AnswersData?.AnswersData.swot === "Strength" &&
                            item?.AnswersData?.AnswersData.comment_yes !=
                              "" && (
                              <>
                                {" "}
                                {item?.AnswersData?.AnswersData
                                  ?.questionType === "AddedQuestion" && (
                                  <div className="d-flex justify-content-end">
                                    <MdAddComment color="#20AC27" size={20} />
                                  </div>
                                )}
                                {item?.AnswersData?.AnswersData
                                  ?.questionType === "AddedQuestion" && (
                                  <>
                                    <strong>
                                      {item?.AnswersData?.AnswersData?.question}{" "}
                                      ?
                                    </strong>{" "}
                                    <br />
                                  </>
                                )}
                                {item?.AnswersData?.AnswersData.comment_yes && (
                                  <span>
                                    {item.AnswersData?.AnswersData.comment_yes}
                                    {item.AnswersData?.AnswersData.answer !=
                                      "yes" &&
                                      item.AnswersData?.AnswersData.answer !=
                                        "no" && (
                                        <span>
                                          {
                                            item?.AnswersData?.AnswersData
                                              .answer
                                          }{" "}
                                          {(
                                            item?.inputFields ||
                                            item?.AnswersData?.AnswersData
                                              ?.inputFields
                                          )?.map((item, index) => (
                                            <ul
                                              style={{
                                                listStyle: "Disc",
                                                paddingLeft: "24px",
                                              }}
                                            >
                                              <li>{item?.answer}</li>
                                            </ul>
                                          ))}
                                        </span>
                                      )}
                                  </span>
                                )}
                              </>
                            )}
                        </span>
                        <span className="card-text">
                          {item?.AnswersData?.AnswersData.swot === "Weakness" &&
                            item?.AnswersData?.AnswersData.comment_no != "" && (
                              <>
                                {item?.AnswersData?.AnswersData
                                  ?.questionType === "AddedQuestion" && (
                                  <div className="d-flex justify-content-end">
                                    <MdAddComment color="#F44336" size={20} />
                                  </div>
                                )}
                                {item?.AnswersData?.AnswersData
                                  ?.questionType === "AddedQuestion" && (
                                  <>
                                    <strong>
                                      {item?.AnswersData?.AnswersData?.question}{" "}
                                      ?
                                    </strong>{" "}
                                    <br />
                                  </>
                                )}
                                {item?.AnswersData?.AnswersData.comment_no && (
                                  <span>
                                    {item.AnswersData?.AnswersData.comment_no}
                                    {item.AnswersData?.AnswersData.answer !=
                                      "yes" &&
                                      item.AnswersData?.AnswersData.answer !=
                                        "no" && (
                                        <span>
                                          {
                                            item?.AnswersData?.AnswersData
                                              .answer
                                          }
                                          {(
                                            item?.inputFields ||
                                            item?.AnswersData?.AnswersData
                                              ?.inputFields
                                          )?.map((item, index) => (
                                            <ul
                                              style={{
                                                listStyle: "Disc",
                                                paddingLeft: "24px",
                                              }}
                                            >
                                              <li>{item?.answer}</li>
                                            </ul>
                                          ))}
                                        </span>
                                      )}
                                  </span>
                                )}
                              </>
                            )}
                        </span>
                        <span className="card-text">
                          {item?.AnswersData?.AnswersData.swot ===
                            "Opportunity" &&
                            (item?.AnswersData?.AnswersData?.comment_third !=
                              "" ||
                              item?.AnswersData?.AnswersData?.comment_no !=
                                "") && (
                              <>
                                {item?.AnswersData?.AnswersData
                                  ?.questionType === "AddedQuestion" && (
                                  <div className="d-flex justify-content-end">
                                    <MdAddComment color="#0B5085" size={20} />
                                  </div>
                                )}
                                {item?.AnswersData?.AnswersData
                                  ?.questionType === "AddedQuestion" && (
                                  <>
                                    <strong>
                                      {item?.AnswersData?.AnswersData?.question}{" "}
                                      ?
                                    </strong>{" "}
                                    <br />
                                  </>
                                )}
                                {(item?.AnswersData?.AnswersData
                                  ?.comment_third ||
                                  item?.AnswersData.AnswersData
                                    ?.comment_no) && (
                                  <span>
                                    {item?.AnswersData?.AnswersData
                                      ?.comment_third
                                      ? item?.AnswersData?.AnswersData
                                          ?.comment_third
                                      : item?.AnswersData.AnswersData
                                          .comment_no}
                                    {item?.AnswersData.AnswersData.answer !=
                                      "yes" &&
                                      item?.AnswersData.AnswersData.answer !=
                                        "no" &&
                                      item?.AnswersData.AnswersData.answer !=
                                        "notNeeded" && (
                                        <span>
                                          {
                                            item?.AnswersData?.AnswersData
                                              .answer
                                          }
                                          {(
                                            item?.inputFields ||
                                            item?.AnswersData?.AnswersData
                                              ?.inputFields
                                          )?.map((item, index) => (
                                            <ul
                                              style={{
                                                listStyle: "Disc",
                                                paddingLeft: "24px",
                                              }}
                                            >
                                              <li>{item?.answer}</li>
                                            </ul>
                                          ))}
                                        </span>
                                      )}
                                  </span>
                                )}
                              </>
                            )}
                        </span>
                        <span className="card-text">
                          {item?.AnswersData?.AnswersData.swot === "Threat" &&
                            item?.AnswersData?.AnswersData.comment_fourth !=
                              "" && (
                              <>
                                {item?.AnswersData?.AnswersData
                                  ?.questionType === "AddedQuestion" && (
                                  <div className="d-flex justify-content-end">
                                    <MdAddComment color="#FFC515" size={20} />
                                  </div>
                                )}
                                {item?.AnswersData?.AnswersData
                                  ?.questionType === "AddedQuestion" && (
                                  <>
                                    <strong>
                                      {item?.AnswersData?.AnswersData?.question}{" "}
                                      ?
                                    </strong>{" "}
                                    <br />
                                  </>
                                )}
                                {item?.AnswersData?.AnswersData
                                  .comment_fourth && (
                                  <span>
                                    {
                                      item?.AnswersData?.AnswersData
                                        .comment_fourth
                                    }
                                    {item?.AnswersData?.AnswersData.answer !=
                                      "yes" &&
                                      item?.AnswersData.AnswersData.answer !=
                                        "no" &&
                                      item?.AnswersData.AnswersData.answer !=
                                        "notNeeded" && (
                                        <span>
                                          {
                                            item?.AnswersData?.AnswersData
                                              .answer
                                          }{" "}
                                          {(
                                            item?.inputFields ||
                                            item?.AnswersData?.AnswersData
                                              ?.inputFields
                                          )?.map((item, index) => (
                                            <ul
                                              style={{
                                                listStyle: "Disc",
                                                paddingLeft: "24px",
                                              }}
                                            >
                                              <li>{item?.answer}</li>
                                            </ul>
                                          ))}
                                        </span>
                                      )}
                                  </span>
                                )}
                              </>
                            )}
                        </span>
                      </div>
                      <div className="card-btns">
                        <PriorityDropdown
                          priorities={mainPriorityData}
                          onPrioritySelect={handlePrioritySelect}
                          currentQuestion={item?.question}
                        />
                        <button
                          className="remove-item"
                          onClick={() => removeItem(index)}
                        >
                          Remove Item{" "}
                        </button>
                      </div>
                    </React.Fragment>
                  );
                }
              })
            )}
          </div>
        )}
      </>
    );
  };

  let currentLink = [];
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink.push(crumb);
      return (
        <div className="crumb" key={crumb}>
          <p>{crumb}</p>
        </div>
      );
    });

  const [theme, setTheme] = useState("");
  const handleSubmit = async () => {
    try {
      const FinalTheme = finalThemeData?.priority?.themes;
      const response = await axios.post(`${apiUrl}/finalTheme/`, {
        planId: plan._id,
        themes: selectedDropdownn,
      });
      const data = response.data.themes;
      setTheme(data);
      navigate("/tacticalplan", {
        state: { selectedDropdownn, mainPriorityData, FinalTheme },
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/priority/${plan._id}`);
      const data = response.data;

      setFinalThemeData(data);
      // console.log(data);

      setMainPriorityData([
        data.priority.priority1,
        data.priority.priority2,
        data.priority.priority3,
        data.priority.priority4,
      ]);
      // handleFinalThemeChanges(data?.priority?.finalTheme)
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  // console.log(finalThemeData?.priority?.themes)
  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Theme
            themes={mainPriorityData}
            themeNames={mainPriorityData}
            subSelectedCards={subSelectedCards}
            selectedCards={finalThemeData?.priority?.finalTheme}
            toggleCardSelection={toggleCardSelection}
            selectedDropdownn={selectedDropdownn}
            setselectedDropdownn={setselectedDropdownn}
            FinalTheme={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="strategic-theme">
        <div className="strategic-content">
          <div
            className="main-breadcrumps px-4 py-4"
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/plan")}
            >
              Plans
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/questionnaire ")}
            >
              Questionnaire
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/goal ")}
            >
              Goals
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/swot ")}
            >
              Swot
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/medical ")}
            >
              Theme
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>

            <p className="breadCrumbs">Final Theme</p>
            {/* <a style={{ display: 'flex' }} onClick={() => navigate('/dashboardland')}>
            DashboardLand
            <Icon
              icon="fa6-solid:greater-than"
              color="#0d4d80"
              width="18"
              height="18"

              className="icon"

              style={{
                marginLeft: "5px",
                marginRight: "5px"
              }}
            />
            {crumbs}
          </a> */}
          </div>
          <div className="strategicTitle">
            <h5>Update Strategic Priority</h5>
            <div className="catageryPriority">
              <div className="Strength">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <circle cx="10" cy="10.5" r="10" fill="#9CCC65" />
                </svg>
                <span>Strength</span>
              </div>
              <div className="Weakness">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <circle cx="10" cy="10.5" r="10" fill="#EF5451" />
                </svg>
                <span>Weakness</span>
              </div>
              <div className="Opportunity">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <circle cx="10" cy="10.5" r="10" fill="#0D4D80" />
                </svg>
                <span>Opportunity</span>
              </div>
              <div className="Threat">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <circle cx="10" cy="10.5" r="10" fill="#FFC515" />
                </svg>
                <span>Threat</span>
              </div>
            </div>
          </div>
          <div className="themesection-top">
            <div className="theme-template">
              <div className="theme-header">
                <span>strategic focus</span>
              </div>
              <div className="theme-bottm">
                <div className="primary-section">
                  <button>Primary</button>{" "}
                  <span>
                    {finalThemeData?.priority?.themes[1000] ? (
                      finalThemeData?.priority?.themes[1000] ===
                      "Theme 1000" ? (
                        "Final Theme"
                      ) : (
                        finalThemeData?.priority?.themes[1000]
                      )
                    ) : finalThemeData?.priority?.strategicGoal1 ? (
                      finalThemeData?.priority?.strategicGoal1
                    ) : (
                      <p> No items have been added in this Strategic</p>
                    )}
                  </span>
                </div>
                <hr />
                <div className="seconday-section">
                  <button>Secondary</button>
                  <span>
                    {finalThemeData?.priority?.strategicGoal2 ? (
                      finalThemeData?.priority?.strategicGoal2
                    ) : (
                      <p> No items have been added in this Strategic</p>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="middle-section">
            <div className="middle-heading">
              {/* <h6>Update Strategic Priorities</h6>
              <div className="priorities-tabs">
                <div className="strngth">Strength</div>
                <div className="weakness">Weakness</div>
                <div className="opportunity">Opportunity</div>
                <div className="threat">Threat</div>
              </div> */}
              <div
                className="priorityCard flex-column"
                // style={{ border: " 2px solid lime" }}
              >
                {isLoading ? (
                  <div class="spinner"></div>
                ) : (
                  <div
                    className="leftside-card d-flex w-100 "
                    // style={{ border: "2px solid cyan" }}
                  >
                    {mainPriorityData?.map((data, index) => {
                      // console.log(data);

                      if (data !== "") {
                        return (
                          <>
                            <PriorityCard
                              key={index}
                              index={index}
                              data={data}
                              selectedDropdownn={selectedDropdownn}
                            />
                          </>
                        );
                      }
                    })}

                    <div className="leftcard1 d-none">
                      <p>Strategic Priority Title</p>
                      <div className="card-btn">
                        <button className="additem">Add Items</button>
                      </div>{" "}
                      <div className="card-footer3">
                        <span>
                          No items have been added in this Strategic <br />{" "}
                          Priority yet!
                        </span>
                      </div>
                      <div className="card-btns">
                        <PriorityDropdown priorities={mainPriorityData} />
                        <button className="remove-item">Remove Item</button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="rightSide-card">
                  <div className="leftcard1 d-none">
                    <p>Develop Customer Experience process</p>
                    <div className="card-btn">
                      <button className="additem">Add Items</button>
                      <button className="clear-startegic">
                        Clear Strategic Prioroties
                      </button>
                    </div>{" "}
                    <div className="card-footer">
                      <span className="card-text">
                        By examining the skill/quality of subs/vendors we may be
                        able to improve our delivery & profitability
                      </span>
                    </div>
                    <div className="card-btns">
                      <PriorityDropdown priorities={mainPriorityData} />
                      <button className="remove-item">Remove Item</button>
                    </div>
                  </div>
                  <div className="leftcard1 d-none">
                    <p>Complete the workflow</p>
                    <div className="card-btn">
                      <button className="additem">Add Items</button>
                      <button className="clear-startegic">
                        Clear Strategic Prioroties
                      </button>
                    </div>{" "}
                    <div className="card-footer2">
                      <span className="card-text">
                        By examining the skill/quality of subs/vendors we may be
                        able to improve our delivery & profitability
                      </span>
                    </div>
                    <div className="card-btns">
                      <PriorityDropdown priorities={mainPriorityData} />
                      <button className="remove-item">Remove Item</button>
                    </div>
                  </div>
                  <div className="submitbtn-section">
                    {/* <button className="submit-btn">
                      submit Strategic Priority

                    </button> */}

                    <button
                      className="save-change"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Save & Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Strategic;
