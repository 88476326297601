import React from "react";
import "./status.scss";
import DonutChart from "./components/donutchart";
import { Table } from "react-bootstrap";

const StatusChart = ({ statusCount }) => {
  console.log(statusCount);
  const findStatusPercentage = (totalStatus, status) => {
    if (totalStatus === 0 || status === 0) {
      return 0;
    }
    let statusPercentage = (status / totalStatus) * 100;
    console.log(statusPercentage);
    return statusPercentage;
  };

  let scheduled = findStatusPercentage(
    statusCount[5].count,
    statusCount[0].count
  );
  let wont_be_done = findStatusPercentage(
    statusCount[5].count,
    statusCount[1].count
  );
  let completed = findStatusPercentage(
    statusCount[5].count,
    statusCount[2].count
  );
  let delayed = findStatusPercentage(
    statusCount[5].count,
    statusCount[3].count
  );
  let onTrack = findStatusPercentage(
    statusCount[5].count,
    statusCount[4].count
  );
  // console.log(scheduled);
  // console.log(onTrack);
  // console.log(delayed);
  // console.log(completed);
  // console.log(wont_be_done);
  const totalPercentage =
    scheduled + onTrack + delayed + completed + wont_be_done;

  return (
    <div>
      <div className="status-section">
        <div className="status-content">
          <div className="leftside-status">
            <DonutChart
              scheduled={Number(scheduled.toFixed(2))}
              onTrack={Number(onTrack.toFixed(2))}
              completed={Number(completed.toFixed(2))}
              wont_be_done={Number(wont_be_done.toFixed(2))}
              delayed={Number(delayed.toFixed(2))}
            />
          </div>
          <div className="rightside-status">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {statusCount?.map((status, index) => (
                  <tr key={index}>
                    <td>{status?._id}</td>
                    <td>{status.count}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusChart;
