import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personAccountable: "",
  task: "",
  startDate: "",
  endDate: "",
  status: "",
  id: ""
}

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    addPersonAccountable: (state, action) => {
      state.personAccountable = action.payload;
    },
    addTask: (state, action) => {
      state.task += action.payload;
    },
    addStartDate: (state, action) => {
      state.startDate += action.payload;
    },
    addEndDate: (state, action) => {
      state.endDate += action.payload;
    },
    addStatus: (state, action) => {
      state.status += action.payload;
    },
    addID: (state, action) => {
      state.id += action.payload;
    },
  },
});

export const { addPersonAccountable, addEndDate, addStartDate, addTask, addStatus, addID } = taskSlice.actions;

export default taskSlice.reducer;
