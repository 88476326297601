import React from "react";
// import { GridBody } from "./grid-body";
import GridBody from './grid-body'

const Grid = (props) => {
  return (
    <g className="grid">
      <GridBody {...props} />
    </g>
  );
};

export default Grid;
