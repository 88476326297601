import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../../style/Admindashboard/NavbarNew.css";
import { NavLink } from "react-router-dom";
import logo from "../Admindashboard/images/Business That Matters  FF-01-01-1 3.png";
import Dashboard1 from "../Admindashboard/images/1.svg";
import Dashboard2 from "../Admindashboard/images/2.svg";
import Dashboard3 from "../Admindashboard/images/3.svg";
import Dashboard4 from "../Admindashboard/images/4.svg";
import Dashboard5 from "../Admindashboard/images/logo5.svg";
import { useDispatch } from "react-redux";
import { removeUser } from "../../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import React, { useContext, useRef, useState } from "react";

import "./Profiledropdown.scss";
import Profile from "../../assets/images/profile.svg";
import { AuthContext } from "../../app/context";

function NavbarNew() {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isOtherDropdownOpen, setOtherDropdownOpen] = useState(true);

  const handleSVGClickOther = () => {
    setOtherDropdownOpen(!isOtherDropdownOpen);
    console.log("click other");
  };
  const otherDropdownRef = useRef(null);

  return (
    <Navbar expand="lg" className="bg-body-tertiary  full_navbar ">
      <Container fluid>
        <Navbar.Brand href="#" className="logo_img ">
          <img src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll toggle_button" />
        <Navbar.Collapse id="navbarScroll" className="fir">
          <Nav
            className="me-auto my-2 my-lg-0 left "
            // style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <NavLink to="/admindashboard">
              <img src={Dashboard1} alt="" /> <span>Dashboard</span>
            </NavLink>
            <NavLink to="/coachestable">
              <img src={Dashboard2} alt="" /> <span>Coaches List</span>
            </NavLink>
            <NavLink to="/adminplan">
              <img src={Dashboard3} alt="" /> <span>Security Plan</span>
            </NavLink>
            <NavLink to="#action2">
              <img src={Dashboard4} alt="" /> <span>Issues List</span>
            </NavLink>
          </Nav>
          <div className="d-flex right">
            <NavLink to="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="30"
                viewBox="0 0 32 30"
                fill="none"
              >
                <path
                  d="M11.875 30L11.275 25.2C10.95 25.075 10.6438 24.925 10.3563 24.75C10.0688 24.575 9.78755 24.3875 9.51255 24.1875L5.05005 26.0625L0.925049 18.9375L4.78755 16.0125C4.76255 15.8375 4.75005 15.6688 4.75005 15.5063V14.4938C4.75005 14.3313 4.76255 14.1625 4.78755 13.9875L0.925049 11.0625L5.05005 3.9375L9.51255 5.8125C9.78755 5.6125 10.075 5.425 10.375 5.25C10.675 5.075 10.975 4.925 11.275 4.8L11.875 0H20.125L20.725 4.8C21.05 4.925 21.3563 5.075 21.6438 5.25C21.9313 5.425 22.2125 5.6125 22.4875 5.8125L26.9501 3.9375L31.0751 11.0625L27.2125 13.9875C27.2376 14.1625 27.25 14.3313 27.25 14.4938V15.5063C27.25 15.6688 27.225 15.8375 27.1751 16.0125L31.0375 18.9375L26.9125 26.0625L22.4875 24.1875C22.2125 24.3875 21.925 24.575 21.625 24.75C21.3251 24.925 21.025 25.075 20.725 25.2L20.125 30H11.875ZM16.075 20.25C17.525 20.25 18.7626 19.7375 19.7875 18.7125C20.8125 17.6875 21.3251 16.45 21.3251 15C21.3251 13.55 20.8125 12.3125 19.7875 11.2875C18.7626 10.2625 17.525 9.75 16.075 9.75C14.6 9.75 13.3563 10.2625 12.3438 11.2875C11.3313 12.3125 10.825 13.55 10.825 15C10.825 16.45 11.3313 17.6875 12.3438 18.7125C13.3563 19.7375 14.6 20.25 16.075 20.25Z"
                  fill="white"
                />
              </svg>
            </NavLink>
            {/* <NavLink to="/profiledropdown" className="">
              <img src={Dashboard5} alt="logo" />
            </NavLink> */}
            <div className="dropdown-containers">
              <img src={Profile} alt="" onClick={handleSVGClickOther} />
              {!isOtherDropdownOpen && (
                <>
                  <div className="bottomdiv" ref={otherDropdownRef}>
                    <NavLink to="/profile" className="Notification">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.33333 4.66667C5.33333 3.95942 5.61428 3.28115 6.11438 2.78105C6.61448 2.28095 7.29276 2 8 2C8.70724 2 9.38552 2.28095 9.88562 2.78105C10.3857 3.28115 10.6667 3.95942 10.6667 4.66667C10.6667 5.37391 10.3857 6.05219 9.88562 6.55229C9.38552 7.05238 8.70724 7.33333 8 7.33333C7.29276 7.33333 6.61448 7.05238 6.11438 6.55229C5.61428 6.05219 5.33333 5.37391 5.33333 4.66667ZM5.33333 8.66667C4.44928 8.66667 3.60143 9.01786 2.97631 9.64298C2.35119 10.2681 2 11.1159 2 12C2 12.5304 2.21071 13.0391 2.58579 13.4142C2.96086 13.7893 3.46957 14 4 14H12C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.1159 13.6488 10.2681 13.0237 9.64298C12.3986 9.01786 11.5507 8.66667 10.6667 8.66667H5.33333Z"
                          fill="#0D4D80"
                        />
                      </svg>
                      <span> My Profile</span>
                    </NavLink>

                    <div
                      className="noNotification"
                      onClick={() => {
                        logout();
                        navigate("/");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M11.333 5.16667L10.393 6.10667L12.113 7.83333H5.33301V9.16667H12.113L10.393 10.8867L11.333 11.8333L14.6663 8.5L11.333 5.16667ZM2.66634 3.83333H7.99967V2.5H2.66634C1.93301 2.5 1.33301 3.1 1.33301 3.83333V13.1667C1.33301 13.9 1.93301 14.5 2.66634 14.5H7.99967V13.1667H2.66634V3.83333Z"
                          fill="#0D4D80"
                        />
                      </svg>

                      <span> Logout</span>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setOtherDropdownOpen(true);
                    }}
                    className="closediv"
                  >
                    {" "}
                    ideas{" "}
                  </div>
                </>
              )}
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarNew;
