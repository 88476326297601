import React from "react";

const Strength = ({ title, className }) => {
  return (
    <>
      {" "}
      <div className={className}>
        <span>{title}</span>
      </div>
    </>
  );
};

export default Strength;
