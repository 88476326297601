import React, { useEffect, useState } from "react";
import "./adminplan.scss";
import NavbarNew from "../NavbarNew";
import Footer from "../../shared/footer";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../shared/navbar";

const Adminplan = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API
  const [inputVal, setInputVal] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    username: ""
  })
  const [email, setEmail] = useState('')
  const [data, setData] = useState('')
  const [passwordError, setPasswordError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const id = location.pathname.split("/")[2];
  const role = location.pathname.split('/')[3]


  useEffect(() => {
    const getemail = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/invitetoken/get/${id}`
        );
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getemail();
  }, []);

  const createPassword = async () => {
    if (inputVal.password !== inputVal.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    } else {
      try {
        if (role === "coach") {
          const response = await axios.post(
            `${apiUrl}/invitetoken/create/${id}`,
            {
              email: data.email,
              password: inputVal.password,
            }
          );
          if (response.data.success) toast.success(response.data.message)
        } else if (role === 'member') {
          const resp = await axios.post(`${apiUrl}/members/create/${id}`, {
            email: data.email,
            password: inputVal.password,
            username: inputVal.username
          })
          if (resp.data.success) toast.success(resp.data.message)
        }
        navigate("/signin");
      } catch (error) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  };

  const handleInput = (e) => {
    if (e.target.name === "password") {
      setInputVal(e.target.value);
    } else if (e.target.name === "confirmPassword") {
      setInputVal(e.target.value);
      // if (e.target.value !== password) {
      //   setPasswordError(true);
      // } else {
      //   setPasswordError(false);
      // }
    }
    setEmail(data.email);

    const { name, value } = e.target;
    setInputVal({
      ...inputVal,
      [name]: value
    });
    setEmail(data.email)
  }

  return (
    <>
      <Navbar />
      <div className="adminplan-section">
        <div className="adminplan-content">
          <div className="adminplan-title">
            <h6>{data.email}</h6>
          </div>
          <div className="line-b"></div>
          <div className="line-b"></div>
          <div className="plan-fields">

            <div className="firstField">
              <div className="labels">
                {" "}
                <label> Username</label>
              </div>
              <div className="label-field">
                {" "}
                <input type="text" placeholder="Type your password here" name="username" value={inputVal.username} onChange={handleInput} />
              </div>
            </div>


            <div className="firstField">
              <div className="labels">
                {" "}
                <label> Password</label>
              </div>
              <div className="label-field">
                {" "}
                <input
                  type="text"
                  placeholder="Type your password here"
                  name="password"
                  value={inputVal.password}
                  onChange={handleInput}
                />
                {passwordError && (
                  <p className="error-message">Passwords do not match</p>
                )}
              </div>
            </div>
            <div className="firstField">
              <div className="labels">
                {" "}
                <label>Confirm Password</label>
              </div>
              <div className="label-field">
                {" "}
                <input type="text" placeholder="Type your password here" name="confirmPassword" value={inputVal.confirmPassword} onChange={handleInput} />
              </div>
            </div>
            <div className="submit-btn">
              {" "}
              <button onClick={createPassword}>Submit</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
};

export default Adminplan;
