import React, { useEffect, useState } from "react";
// import "../../style/Admindashboard/CoachesTable.css";
import Footer from "../shared/footer";
import NavbarNew from "./NavbarNew";
import "./NavbarNew";
import "./coach.scss";
import Coachlogo from "../../assets/images/coachlist-logo.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "../shared/navbar";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const CoachesTable = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;

  const [coachData, setCoachData] = useState([]);
  const [userData, setUserData] = useState([]); //
  const [assignCoach, setAssignCoach] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedCoachId, setSelectedCoachId] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState({});

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/addcoach/getcoach`);
      setCoachData(response.data.coaches);
      setUserData(response.data.users);
    } catch (error) {
      console.error("Error fetching coach data:", error);
    }
  };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  const fetchUser = async (coachId, userId) => {
    setSelectedCoachId(coachId);
    setSelectedUserId(userId);
    try {
      const response = await axios.get(
        `${apiUrl}/addcoach/getuser/${coachId}/${userId}`
      );
      setAssignCoach(response.data);
      setSelectedUsers((prevSelectedUsers) => {
        const updatedSelectedUsers = {
          ...prevSelectedUsers,
          [coachId]: userId,
        };
        secureLocalStorage.setItem(
          "selectedUsers",
          JSON.stringify(updatedSelectedUsers)
        );
        return updatedSelectedUsers;
      });
    } catch (error) {
      console.error("Error fetching coach data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const storedSelectedUsers =
      JSON.parse(secureLocalStorage.getItem("selectedUsers")) || {};
    setSelectedUsers(storedSelectedUsers);
  }, []);

  return (
    <>
      <Navbar />

      <div className="coaches-main">
        <div className="coach-content">
          <div className="coach-header">
            <img src={Coachlogo} alt=".." />
            <span>Coaches List</span>
          </div>
          <div className="coach-table">
            <div className="coachtable-main">
              <table>
                <tr className="firstRow">
                  <th className="secondTh">ID</th>
                  <th className="thirdTh">Name</th>
                  <th className="fourthTh">Email</th>
                  <th className="fifthTh">Assigned to</th>
                </tr>
                {coachData && coachData.length > 0 ? (
                  coachData.map((coach, index) => (
                    <tr className="secondRowP">
                      <>
                        {/* <td key={coach._id} /> */}
                        <td className="secondTh">{index + 1}</td>
                        <td className="secondTh">
                          {coach.firstName} {coach.lastName}
                        </td>
                        <td className="secondTh">{coach.email}</td>
                        <td className="secondTh">
                          {" "}
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="dropdowntoggle"
                            >
                              {selectedUsers[coach._id]
                                ? userData.find(
                                    (user) =>
                                      user._id === selectedUsers[coach._id]
                                  ).userName
                                : "Assigned to"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdownMenu">
                              {/* {console.log(userData)} */}

                              {userData.map((user, index) => (
                                <Dropdown.Item
                                  href="#/action"
                                  className="dropdownItem"
                                  onClick={() => fetchUser(coach._id, user._id)}
                                >
                                  {user.userName}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No coaches found</td>
                  </tr>
                )}
                {/* <td className="secondTh">1.</td>
                  <td className="secondTh">John</td>
                  <td className="secondTh">john@gmail.com.</td> */}
                {/* <tr className="secondRowP">
                  <td className="secondTh">1.</td>
                  <td className="secondTh">John</td>
                  <td className="secondTh">john@gmail.com.</td>
                  <td className="secondTh">
                    {" "}
                     
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdowntoggle"
                      >
                        Assigned to
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dropdownMenu">
                        <Dropdown.Item
                          href="#/action-2"
                          className="dropdownItem"
                        >
                          User 1
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-3"
                          className="dropdownItem"
                        >
                          User 2
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr className="secondRowP">
                  <td className="secondTh">1.</td>
                  <td className="secondTh">John</td>
                  <td className="secondTh">john@gmail.com.</td>
                  <td className="secondTh">
                    {" "}
                     
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdowntoggle"
                      >
                        Assigned to
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dropdownMenu">
                        <Dropdown.Item
                          href="#/action-2"
                          className="dropdownItem"
                        >
                          User 1
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-3"
                          className="dropdownItem"
                        >
                          User 2
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr> */}
              </table>
            </div>
          </div>
          {/* </div> */}
        </div>
        {/* </div> */}
      </div>
      <Footer />
    </>
  );
};

export default CoachesTable;
