import React from "react";
import { Pagination } from "react-bootstrap";

const PaginationComponent = ({ currentPage, totalPages, handlePageChange }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPages = 3;

    // Show the first 3 pages
    for (let i = 1; i <= Math.min(maxPages, totalPages); i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Show ellipsis if needed before the current page
    if (currentPage > maxPages + 1) {
      pageNumbers.push(<Pagination.Ellipsis key="start-ellipsis" disabled />);
    }

    // Show the current page and its neighbors if they are not part of the first or last pages
    if (currentPage > maxPages && currentPage <= totalPages - maxPages) {
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        if (!pageNumbers.some((page) => page.key == i)) {
          pageNumbers.push(
            <Pagination.Item
              key={i}
              active={i === currentPage}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
    }

    // Show ellipsis if needed after the current page
    if (currentPage < totalPages - maxPages) {
      pageNumbers.push(<Pagination.Ellipsis key="end-ellipsis" disabled />);
    }

    // Show the last 3 pages
    for (
      let i = Math.max(totalPages - maxPages + 1, maxPages + 1);
      i <= totalPages;
      i++
    ) {
      if (!pageNumbers.some((page) => page.key == i)) {
        pageNumbers.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <Pagination>
      <Pagination.First
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
      />
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {renderPageNumbers()}
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default PaginationComponent;
