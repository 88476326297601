import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Strength from "./sharedbox/strength";
import axios from "axios";
import { toast } from "react-toastify";
import { MdAddComment } from "react-icons/md";
const Genericswot = ({
  selectedCards,
  setSelectedCards,
  activeTab,
  setActiveTab,
  data,
  title,
  answers,
  plan,
  inputValues,
  setIsLoading,
  isLoading,
  searchParams,
  setSearchParams,
  setActiveTabInDb,
  activeTabinDb,
}) => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  // const apiUrl = "http://localhost:3333";

  const [activeKey, setActiveKey] = useState("0");
  let strength = null;
  let weakness = null;
  let Opportunity = null;
  let Threat = null;
  let Consistent = null;

  if (answers) {
    strength = answers.filter(
      (e) => e.swot === "Strength" && e.comment_yes !== ""
    );
    weakness = answers.filter(
      (e) => e.swot === "Weakness" && e.comment_no !== ""
    );
    Opportunity = answers.filter(
      (e) => e.swot === "Opportunity" && e.comment_third !== ""
    );
    Threat = answers.filter(
      (e) => e.swot === "Threat" && e.comment_fourth !== ""
    );
    Consistent = answers.filter(
      (e) => e.swot === "Consistent" && e.comment_yes !== ""
    );
  }
  // console.log(Opportunity, Threat)
  const handleAccordionToggle = (key) => {
    setActiveKey(key);
  };
  const [values, setValues] = useState([]);

  const handleCardClick = (cardData) => {
    // console.log(cardData)
    const updatedCard = { ...cardData };
    setSelectedCards((prevSelected) => {
      prevSelected = Array.isArray(prevSelected) ? prevSelected : [];
      if (prevSelected?.find((card) => card?._id === updatedCard._id)) {
        return prevSelected?.filter((card) => card._id !== updatedCard._id);
      } else {
        return [...prevSelected, updatedCard];
      }
    });
  };
  const handleListCardClick = (cardData, value) => {
    const updatedCard = { ...cardData };
    if (value?.length > 0) {
      updatedCard.inputFields = value;
    }

    setSelectedCards((prevSelected) => {
      prevSelected = Array.isArray(prevSelected) ? prevSelected : [];
      if (prevSelected?.find((card) => card?._id === updatedCard._id)) {
        return prevSelected?.filter((card) => card._id !== updatedCard._id);
      } else {
        return [...prevSelected, updatedCard];
      }
    });
  };

  const isCardSelected = (cardData) => {
    if (selectedCards?.length > 0) {
      return selectedCards?.find((card) => card?._id === cardData?._id);
    }
  };

  const questionareData = async () => {
    try {
      // console.log(selectedCards)
      const response = await axios.post(`${apiUrl}/questionnaire/`, {
        selected: selectedCards,
        planId: plan._id,
        tab: activeTab,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  const nextBtn = () => {
    const currentTabNumber = parseInt(activeTab.slice(3), 10);
    const nextTabNumber = currentTabNumber + 1;
    const nextTab = `tab${nextTabNumber}`;
    if (nextTabNumber <= 11) {
      setSearchParams({ activeTab: nextTab });
      setActiveTab(nextTab);
      console.log(activeTabinDb, nextTab);
      console.log(Number(nextTab.slice(3, nextTab.length)));
      console.log(Number(activeTabinDb.slice(3, activeTabinDb.length)));
      if (
        Number(nextTab.slice(3, nextTab.length)) >
        Number(activeTabinDb.slice(3, activeTabinDb.length))
      ) {
        setActiveTabInDb(nextTab);
      }
      scrollTop();
    }
  };

  const previousBtn = () => {
    const currentTabNumber = parseInt(activeTab.slice(3), 10);
    const nextTabNumber = currentTabNumber - 1;
    const nextTab = `tab${nextTabNumber}`;

    if (nextTabNumber >= 1 && nextTabNumber !== "tab10") {
      setActiveTab(nextTab);
      setSearchParams({ activeTab: nextTab });
    }
  };

  const ListTypeShowArray = [
    "Selling Physical Goods",
    "Purpose",
    "Vision",
    "Mission",
  ];
  const AddInputValuesToInputArray = (e) => {
    if (ListTypeShowArray.includes(e.category)) {
      const inputValue = inputValues?.find(
        (inputValue) =>
          inputValue?.inputvalues[0]?.swot === e?.swot &&
          inputValue?.inputvalues[0]?.category === e?.category &&
          inputValue?.inputvalues[0]?.question === e?.question
      );
      const nonEmptyValue = inputValue?.inputvalues?.filter((val) =>
        val !== "" ? val : null
      );
      setValues(nonEmptyValue); // Update selected value
      handleListCardClick(e, nonEmptyValue);
    } else {
      handleCardClick(e);
    }
  };

  return (
    <>
      <div className="Genericswot">
        <div className="longview-content">
          <div className="longview-title">
            <div className="longview-heading">
              <span>{title}</span>
            </div>
            <div className="accordan">
              <Accordion
                defaultActiveKey="0"
                className="accordianParent"
                onSelect={handleAccordionToggle}
              >
                {isLoading ? (
                  <div class="spinner"></div>
                ) : (
                  data.map((title, index) => (
                    <Accordion.Item
                      eventKey={index.toString()}
                      className={`accordianItem1 ${
                        activeKey === index.toString() ? "active" : ""
                      }`}
                      key={`${index}i`}
                    >
                      <Accordion.Header className="accordianHeader">
                        <div>{title}</div>
                        <div className="svgContainer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="12"
                            viewBox="0 0 19 12"
                            fill="none"
                            className={`accordionIcon ${
                              activeKey === index.toString() ? "active" : ""
                            }`}
                          >
                            <path
                              d="M2 2L9.5 9L17 2"
                              stroke={
                                activeKey === index.toString()
                                  ? "white"
                                  : "black"
                              }
                              strokeWidth="3"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="accordion-content">
                        <div className="accordianContent">
                          <div className="accordiancard">
                            <div className="firstCard">
                              <Strength
                                title="Strengths"
                                className="strength"
                              />
                            </div>
                            <div className="firstCard">
                              <Strength
                                title="Weaknesses"
                                className="Weaknesses"
                              />
                            </div>
                            <div className="firstCard">
                              <Strength
                                title="Opportunities"
                                className="Opportunities"
                              />
                            </div>
                            <div className="firstCard">
                              <Strength title="Threats" className="Threats" />
                            </div>
                            {title === "the final five" && (
                              <div className="firstCard">
                                <Strength
                                  title="Consistent"
                                  className="Consistent"
                                />
                              </div>
                            )}
                          </div>
                          <div className="accordiancard">
                            {/* strength */}
                            <div className="firstCard">
                              {strength?.map((e, answerIndex) => {
                                return (
                                  <React.Fragment>
                                    {title
                                      ?.toLowerCase()
                                      .includes(e.category?.toLowerCase()) && (
                                      <div
                                        className="strengthFcard"
                                        onClick={() => {
                                          handleCardClick(e);
                                        }}
                                        key={answerIndex}
                                      >
                                        <div className="Cardtext">
                                          {e?.questionType ===
                                            "AddedQuestion" && (
                                            <div className="d-flex justify-content-end">
                                              <MdAddComment
                                                color="#20AC27"
                                                size={20}
                                              />
                                            </div>
                                          )}
                                          {e?.questionType ===
                                            "AddedQuestion" &&
                                            e?.input === true && (
                                              <>
                                                <strong>{e?.question} ?</strong>{" "}
                                                <br />
                                              </>
                                            )}
                                          <span>{e?.comment_yes}</span>
                                          {e?.answer != "yes" &&
                                          e?.answer != "no" &&
                                          e?.answer != "notNeeded" ? (
                                            <p>{e?.answer}</p>
                                          ) : null}
                                        </div>

                                        {isCardSelected(e) ? (
                                          <div className="cardBadge">
                                            <div className="cardBadge">
                                              <div
                                                className="redBadge"
                                                style={{
                                                  background: "var(--primary)",
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="17"
                                                  height="17"
                                                  viewBox="0 0 17 17"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="8.69368"
                                                    cy="8.5931"
                                                    r="7.73372"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                    fill="var(--primary)"
                                                  />
                                                </svg>
                                                <span>Marked</span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="cardBadge">
                                            <div className="cardBadge">
                                              <div className="redBadge">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="17"
                                                  height="17"
                                                  viewBox="0 0 17 17"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="8.69368"
                                                    cy="8.5931"
                                                    r="7.73372"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                    fill="#FF0000"
                                                  />
                                                </svg>
                                                <span>Mark</span>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {title
                                      ?.toLowerCase()
                                      .includes(e.category?.toLowerCase()) &&
                                      inputValues?.map((item, itemIndex) => {
                                        return (
                                          <React.Fragment key={itemIndex}>
                                            {item?.inputvalues?.map(
                                              (val, valIndex) => {
                                                if (
                                                  val?.swot == e.swot &&
                                                  val?.category == e.category &&
                                                  val?.question == e.question
                                                )
                                                  return (
                                                    <div
                                                      className="strengthFcard"
                                                      key={valIndex}
                                                      onClick={() => {
                                                        handleCardClick(val);
                                                      }}
                                                    >
                                                      <div className="Cardtext">
                                                        <span>
                                                          {val?.comment_yes}
                                                        </span>
                                                        <p>{val?.answer}</p>
                                                      </div>
                                                      {isCardSelected(val) ? (
                                                        <div className="cardBadge">
                                                          <div className="cardBadge">
                                                            <div
                                                              className="redBadge"
                                                              style={{
                                                                background:
                                                                  "var(--primary)",
                                                              }}
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                              >
                                                                <circle
                                                                  cx="8.69368"
                                                                  cy="8.5931"
                                                                  r="7.73372"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                                  fill="var(--primary)"
                                                                />
                                                              </svg>
                                                              <span>
                                                                Marked
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <div className="cardBadge">
                                                          <div className="cardBadge">
                                                            <div className="redBadge">
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                              >
                                                                <circle
                                                                  cx="8.69368"
                                                                  cy="8.5931"
                                                                  r="7.73372"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                                  fill="#FF0000"
                                                                />
                                                              </svg>
                                                              <span>Mark</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  );
                                              }
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                            {/* Weakness */}
                            <div className="firstCard">
                              {weakness?.map((e, answerIndex) => {
                                return (
                                  <React.Fragment>
                                    {title
                                      ?.toLowerCase()
                                      .includes(e.category?.toLowerCase()) && (
                                      <div
                                        className="strengthFcard2"
                                        onClick={() => {
                                          console.log(e);

                                          AddInputValuesToInputArray(e);
                                        }}
                                        key={answerIndex}
                                      >
                                        <div className="Cardtext">
                                          {e?.questionType ===
                                            "AddedQuestion" && (
                                            <div className="d-flex justify-content-end">
                                              <MdAddComment
                                                color="#F44336"
                                                size={20}
                                              />
                                            </div>
                                          )}
                                          {e?.questionType ===
                                            "AddedQuestion" &&
                                            e?.input === true && (
                                              <>
                                                <strong>{e?.question} ?</strong>{" "}
                                                <br />
                                              </>
                                            )}
                                          <span>
                                            {e?.key == "receives_reports"
                                              ? e?.comment_third
                                              : e?.comment_no}
                                          </span>
                                          {ListTypeShowArray.includes(
                                            e.category
                                          )
                                            ? e?.answer != "yes" &&
                                              e?.answer != "no" &&
                                              e?.answer != "notNeeded" && (
                                                <ul>
                                                  <li>{e?.answer}</li>
                                                </ul>
                                              )
                                            : e?.answer != "yes" &&
                                              e?.answer != "no" &&
                                              e?.answer != "notNeeded" && (
                                                <p>{e?.answer}</p>
                                              )}
                                          {ListTypeShowArray.includes(
                                            e.category
                                          ) &&
                                            inputValues.map(
                                              (inputValue, index) => (
                                                <ul key={index}>
                                                  {inputValue.inputvalues.map(
                                                    (val, idx) => {
                                                      // Check if val and val.value exist before rendering
                                                      if (
                                                        val &&
                                                        val.answer &&
                                                        val.swot === e.swot &&
                                                        val.category ===
                                                          e.category &&
                                                        val.question ===
                                                          e.question
                                                      ) {
                                                        return (
                                                          <li key={idx}>
                                                            {val.answer}
                                                          </li>
                                                        );
                                                      }
                                                      return null; // Return null if condition is not met
                                                    }
                                                  )}
                                                </ul>
                                              )
                                            )}
                                        </div>
                                        {isCardSelected(e) ? (
                                          <div className="cardBadge">
                                            <div className="cardBadge">
                                              <div
                                                className="redBadge"
                                                style={{
                                                  background: "var(--primary)",
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="17"
                                                  height="17"
                                                  viewBox="0 0 17 17"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="8.69368"
                                                    cy="8.5931"
                                                    r="7.73372"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                    fill="var(--primary)"
                                                  />
                                                </svg>
                                                <span>Marked</span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="cardBadge">
                                            <div className="cardBadge">
                                              <div className="redBadge">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="17"
                                                  height="17"
                                                  viewBox="0 0 17 17"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="8.69368"
                                                    cy="8.5931"
                                                    r="7.73372"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                    fill="#FF0000"
                                                  />
                                                </svg>
                                                <span>Mark</span>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {!ListTypeShowArray.includes(e.category) &&
                                      title
                                        ?.toLowerCase()
                                        .includes(e.category?.toLowerCase()) &&
                                      inputValues?.map((item, itemIndex) => {
                                        return (
                                          <React.Fragment key={itemIndex}>
                                            {item?.inputvalues?.map(
                                              (val, valIndex) => {
                                                if (
                                                  val?.swot == e.swot &&
                                                  val?.category == e.category &&
                                                  val?.question == e.question
                                                )
                                                  return (
                                                    <div
                                                      className="strengthFcard2"
                                                      key={valIndex}
                                                      onClick={() => {
                                                        handleCardClick(val);
                                                      }}
                                                    >
                                                      <div className="Cardtext">
                                                        <span>
                                                          {val?.comment_no}
                                                        </span>
                                                        <p>{val?.answer}</p>
                                                      </div>
                                                      {isCardSelected(val) ? (
                                                        <div className="cardBadge">
                                                          <div className="cardBadge">
                                                            <div
                                                              className="redBadge"
                                                              style={{
                                                                background:
                                                                  "var(--primary)",
                                                              }}
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                              >
                                                                <circle
                                                                  cx="8.69368"
                                                                  cy="8.5931"
                                                                  r="7.73372"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                                  fill="var(--primary)"
                                                                />
                                                              </svg>
                                                              <span>
                                                                Marked
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <div className="cardBadge">
                                                          <div className="cardBadge">
                                                            <div className="redBadge">
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                              >
                                                                <circle
                                                                  cx="8.69368"
                                                                  cy="8.5931"
                                                                  r="7.73372"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                                  fill="#FF0000"
                                                                />
                                                              </svg>
                                                              <span>Mark</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  );
                                              }
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                            {/* Opportunity */}
                            <div className="firstCard">
                              {Opportunity?.map((e, answerIndex) => {
                                // console.log(e.input)
                                return (
                                  <React.Fragment key={answerIndex}>
                                    {title
                                      ?.toLowerCase()
                                      .includes(e.category?.toLowerCase()) && (
                                      <div
                                        className="strengthFcard3"
                                        onClick={() => {
                                          handleCardClick(e);
                                        }}
                                        key={answerIndex}
                                      >
                                        <div className="Cardtext">
                                          {e?.questionType ===
                                            "AddedQuestion" && (
                                            <div className="d-flex justify-content-end">
                                              <MdAddComment
                                                color="#0B5085"
                                                size={20}
                                              />
                                            </div>
                                          )}
                                          {e?.questionType ===
                                            "AddedQuestion" &&
                                            e?.input === true && (
                                              <>
                                                <strong>{e?.question} ?</strong>{" "}
                                                <br />
                                              </>
                                            )}
                                          <span>{e?.comment_third}</span>
                                          {e?.answer != "yes" &&
                                          e?.answer != "no" &&
                                          e?.answer != "notNeeded" ? (
                                            <p>{e?.answer}</p>
                                          ) : null}
                                        </div>
                                        {isCardSelected(e) ? (
                                          <div className="cardBadge">
                                            <div className="cardBadge">
                                              <div
                                                className="redBadge"
                                                style={{
                                                  background: "var(--primary)",
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="17"
                                                  height="17"
                                                  viewBox="0 0 17 17"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="8.69368"
                                                    cy="8.5931"
                                                    r="7.73372"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                    fill="var(--primary)"
                                                  />
                                                </svg>
                                                <span>Marked</span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="cardBadge">
                                            <div className="cardBadge">
                                              <div className="redBadge">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="17"
                                                  height="17"
                                                  viewBox="0 0 17 17"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="8.69368"
                                                    cy="8.5931"
                                                    r="7.73372"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                    fill="#FF0000"
                                                  />
                                                </svg>
                                                <span>Mark</span>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {title
                                      ?.toLowerCase()
                                      .includes(e.category?.toLowerCase()) &&
                                      inputValues?.map((item, itemIndex) => {
                                        console.log(item.inputvalues);
                                        return (
                                          <React.Fragment key={itemIndex}>
                                            {item?.inputvalues?.map(
                                              (val, valIndex) => {
                                                if (
                                                  val?.swot == e.swot &&
                                                  val?.category == e.category &&
                                                  val?.question == e.question
                                                )
                                                  return (
                                                    <div
                                                      className="strengthFcard3"
                                                      key={valIndex}
                                                      onClick={() => {
                                                        handleCardClick(val);
                                                      }}
                                                    >
                                                      <div className="Cardtext">
                                                        <span>
                                                          {val?.comment_third}
                                                        </span>
                                                        <p>{val?.answer}</p>
                                                      </div>
                                                      {isCardSelected(val) ? (
                                                        <div className="cardBadge">
                                                          <div className="cardBadge">
                                                            <div
                                                              className="redBadge"
                                                              style={{
                                                                background:
                                                                  "var(--primary)",
                                                              }}
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                              >
                                                                <circle
                                                                  cx="8.69368"
                                                                  cy="8.5931"
                                                                  r="7.73372"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                                  fill="var(--primary)"
                                                                />
                                                              </svg>
                                                              <span>
                                                                Marked
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <div className="cardBadge">
                                                          <div className="cardBadge">
                                                            <div className="redBadge">
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                              >
                                                                <circle
                                                                  cx="8.69368"
                                                                  cy="8.5931"
                                                                  r="7.73372"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                                  fill="#FF0000"
                                                                />
                                                              </svg>
                                                              <span>Mark</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  );
                                              }
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                            {/* Threat */}
                            <div className="firstCard">
                              {Threat?.map((e, answerIndex) => {
                                return (
                                  <React.Fragment key={answerIndex}>
                                    {title
                                      ?.toLowerCase()
                                      .includes(e.category?.toLowerCase()) && (
                                      <div
                                        className="strengthFcard4"
                                        onClick={() => {
                                          handleCardClick(e);
                                        }}
                                        key={answerIndex}
                                      >
                                        <div className="Cardtext">
                                          {e?.questionType ===
                                            "AddedQuestion" && (
                                            <div className="d-flex justify-content-end">
                                              <MdAddComment
                                                color="#FFC515"
                                                size={20}
                                              />
                                            </div>
                                          )}
                                          {e?.questionType ===
                                            "AddedQuestion" &&
                                            e?.input === true && (
                                              <>
                                                <strong>{e?.question} ?</strong>{" "}
                                                <br />
                                              </>
                                            )}
                                          <span>{e?.comment_fourth}</span>
                                          {e?.answer != "yes" &&
                                          e?.answer != "no" &&
                                          e?.answer != "notNeeded" ? (
                                            <p>{e?.answer}</p>
                                          ) : null}
                                        </div>
                                        {isCardSelected(e) ? (
                                          <div className="cardBadge">
                                            <div className="cardBadge">
                                              <div
                                                className="redBadge"
                                                style={{
                                                  background: "var(--primary)",
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="17"
                                                  height="17"
                                                  viewBox="0 0 17 17"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="8.69368"
                                                    cy="8.5931"
                                                    r="7.73372"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                    fill="var(--primary)"
                                                  />
                                                </svg>
                                                <span>Marked</span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="cardBadge">
                                            <div className="cardBadge">
                                              <div className="redBadge">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="17"
                                                  height="17"
                                                  viewBox="0 0 17 17"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="8.69368"
                                                    cy="8.5931"
                                                    r="7.73372"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                    fill="#FF0000"
                                                  />
                                                </svg>
                                                <span>Mark</span>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {title
                                      ?.toLowerCase()
                                      .includes(e.category?.toLowerCase()) &&
                                      inputValues?.map((item, itemIndex) => {
                                        // console.log(item.inputvalues)
                                        return (
                                          <React.Fragment key={itemIndex}>
                                            {item?.inputvalues?.map(
                                              (val, valIndex) => {
                                                if (
                                                  val?.swot == e.swot &&
                                                  val?.category == e.category &&
                                                  val?.question == e.question
                                                )
                                                  return (
                                                    <div
                                                      className="strengthFcard4"
                                                      key={valIndex}
                                                      onClick={() => {
                                                        handleCardClick(val);
                                                      }}
                                                    >
                                                      <div className="Cardtext">
                                                        <span>
                                                          {val?.comment_fourth}
                                                        </span>
                                                        <p>{val?.answer}</p>
                                                      </div>
                                                      {isCardSelected(val) ? (
                                                        <div className="cardBadge">
                                                          <div className="cardBadge">
                                                            <div
                                                              className="redBadge"
                                                              style={{
                                                                background:
                                                                  "var(--primary)",
                                                              }}
                                                            >
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                              >
                                                                <circle
                                                                  cx="8.69368"
                                                                  cy="8.5931"
                                                                  r="7.73372"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                                  fill="var(--primary)"
                                                                />
                                                              </svg>
                                                              <span>
                                                                Marked
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <div className="cardBadge">
                                                          <div className="cardBadge">
                                                            <div className="redBadge">
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                              >
                                                                <circle
                                                                  cx="8.69368"
                                                                  cy="8.5931"
                                                                  r="7.73372"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                                  fill="#FF0000"
                                                                />
                                                              </svg>
                                                              <span>Mark</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  );
                                              }
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                            {/* Consistent */}

                            {title === "the final five" && (
                              <div className="firstCard">
                                {Consistent?.map((e, answerIndex) => {
                                  console.log(e);

                                  return (
                                    <React.Fragment key={answerIndex}>
                                      {title
                                        ?.toLowerCase()
                                        .includes(
                                          e.category?.toLowerCase()
                                        ) && (
                                        <div
                                          className="consistentFcard"
                                          onClick={() => {
                                            handleCardClick(e);
                                          }}
                                          key={answerIndex}
                                        >
                                          <div className="Cardtext">
                                            <span>{e?.comment_yes}</span>
                                            {e?.answer != "yes" &&
                                            e?.answer != "no" &&
                                            e?.answer != "notNeeded" ? (
                                              <p>{e?.answer}</p>
                                            ) : null}
                                          </div>

                                          {isCardSelected(e) ? (
                                            <div className="cardBadge">
                                              <div className="cardBadge">
                                                <div
                                                  className="redBadge"
                                                  style={{
                                                    background:
                                                      "var(--primary)",
                                                  }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="17"
                                                    height="17"
                                                    viewBox="0 0 17 17"
                                                    fill="none"
                                                  >
                                                    <circle
                                                      cx="8.69368"
                                                      cy="8.5931"
                                                      r="7.73372"
                                                      fill="white"
                                                    />
                                                    <path
                                                      d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                      fill="var(--primary)"
                                                    />
                                                  </svg>
                                                  <span>Marked</span>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="cardBadge">
                                              <div className="cardBadge">
                                                <div className="redBadge">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="17"
                                                    height="17"
                                                    viewBox="0 0 17 17"
                                                    fill="none"
                                                  >
                                                    <circle
                                                      cx="8.69368"
                                                      cy="8.5931"
                                                      r="7.73372"
                                                      fill="white"
                                                    />
                                                    <path
                                                      d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                      fill="#FF0000"
                                                    />
                                                  </svg>
                                                  <span>Mark</span>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {title
                                        ?.toLowerCase()
                                        .includes(e.category?.toLowerCase()) &&
                                        inputValues?.map((item, itemIndex) => {
                                          // console.log(item.inputvalues)

                                          return (
                                            <React.Fragment key={itemIndex}>
                                              {item?.inputvalues?.map(
                                                (val, valIndex) => {
                                                  if (
                                                    val?.swot == e.swot &&
                                                    val?.category ==
                                                      e.category &&
                                                    val?.question == e.question
                                                  )
                                                    return (
                                                      <div
                                                        className="consistentFcard"
                                                        key={valIndex}
                                                        onClick={() => {
                                                          handleCardClick(val);
                                                        }}
                                                      >
                                                        <div className="Cardtext">
                                                          <span>
                                                            {val?.comment_yes}
                                                          </span>
                                                          <p>{val?.answer}</p>
                                                        </div>
                                                        {isCardSelected(val) ? (
                                                          <div className="cardBadge">
                                                            <div className="cardBadge">
                                                              <div
                                                                className="redBadge"
                                                                style={{
                                                                  background:
                                                                    "var(--primary)",
                                                                }}
                                                              >
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="17"
                                                                  height="17"
                                                                  viewBox="0 0 17 17"
                                                                  fill="none"
                                                                >
                                                                  <circle
                                                                    cx="8.69368"
                                                                    cy="8.5931"
                                                                    r="7.73372"
                                                                    fill="white"
                                                                  />
                                                                  <path
                                                                    d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                                    fill="var(--primary)"
                                                                  />
                                                                </svg>
                                                                <span>
                                                                  Marked
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div className="cardBadge">
                                                            <div className="cardBadge">
                                                              <div className="redBadge">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="17"
                                                                  height="17"
                                                                  viewBox="0 0 17 17"
                                                                  fill="none"
                                                                >
                                                                  <circle
                                                                    cx="8.69368"
                                                                    cy="8.5931"
                                                                    r="7.73372"
                                                                    fill="white"
                                                                  />
                                                                  <path
                                                                    d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                                    fill="#FF0000"
                                                                  />
                                                                </svg>
                                                                <span>
                                                                  Mark
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    );
                                                }
                                              )}
                                            </React.Fragment>
                                          );
                                        })}
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))
                )}
              </Accordion>
            </div>
          </div>
          <div className="accordianSection"></div>

          {/* {
            activeTab === 'tab10' &&
            <div className="d-flex gap-3 flex-wrap">
              {
                addedQuestion.length > 0 && addedQuestion?.map((item, index) => (
                  <div class="card" style={{ width: "18rem" }}>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item text-white list-question">{item?.question} <br />category ({item.category})</li>
                      {addedAnswer?.map((answer, ansIndex) => {
                        if (answer?.key === item?.key) {
                          // console.log(answer)
                          return <li key={ansIndex} className="list-group-item">{answer?.answer}</li>;
                        }
                      })}

                      {/* <div className="firstCard">
                        {
                          addedAnswer?.map((answer, ansIndex) => {
                            if (answer?.key === item?.key) {
                              console.log(answer);

                              // return <li key={ansIndex} className="list-group-item">{answer?.answer}</li>;
                              return (
                                <React.Fragment>
                                  {
                                    // title?.toLowerCase().includes(answer.category?.toLowerCase()) &&
                                    <div
                                      className="strengthFcard"
                                      onClick={() => {
                                        handleCardClick(answer);
                                      }}
                                    // key={answerIndex}
                                    >

                                      <div className="Cardtext">
                                        
                                      <span>{e?.comment_yes}</span>
                                      {(e?.answer != "yes" && e?.answer != "no" && e?.answer != "notNeeded") ? <p>{e?.answer}</p> : null}
                                    </div> 
                                        {
                                          addedInput?.map((input, inputIndex) => {
                                            if (input?.key === item?._id) {
                                              return <li key={inputIndex} className="list-group-item">
                                                {
                                                  input?.inputvalues.map((i, ind) => (
                                                    <li key={ind}>{i?.answer}</li>
                                                  ))
                                                }
                                              </li>;
                                            } else {
                                              return null; // Return null if there's no match
                                            }
                                          })
                                        }
                                      </div>

                                      {isCardSelected(answer) ? (
                                        <div className="cardBadge">
                                          <div className="cardBadge">
                                            <div
                                              className="redBadge"
                                              style={{
                                                background: "var(--primary)",
                                              }}

                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="17"
                                                height="17"
                                                viewBox="0 0 17 17"
                                                fill="none"
                                              >
                                                <circle
                                                  cx="8.69368"
                                                  cy="8.5931"
                                                  r="7.73372"
                                                  fill="white"
                                                />
                                                <path
                                                  d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                  fill="var(--primary)"
                                                />
                                              </svg>
                                              <span>Marked</span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="cardBadge">
                                          <div className="cardBadge">
                                            <div className="redBadge" >

                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="17"
                                                height="17"
                                                viewBox="0 0 17 17"
                                                fill="none"
                                              >
                                                <circle
                                                  cx="8.69368"
                                                  cy="8.5931"
                                                  r="7.73372"
                                                  fill="white"
                                                />
                                                <path
                                                  d="M5.18021 7.06583C5.40395 6.89232 5.61238 6.72718 5.82444 6.56671C6.1504 6.31995 6.46861 6.06343 6.80766 5.83571C7.16865 5.59323 7.57187 5.4519 8.01391 5.43866C8.54105 5.42272 9.06415 5.37341 9.57085 5.21457C10.0133 5.07581 10.4165 4.87033 10.7116 4.4997C10.8026 4.3869 10.8795 4.26357 10.9408 4.1324C11.0263 3.94538 11.2282 3.96114 11.3328 4.09605C11.3503 4.12075 11.3662 4.14659 11.3802 4.17341C11.9948 5.23778 12.6093 6.30215 13.2238 7.36652C13.2533 7.41525 13.275 7.4683 13.2881 7.52375C13.3069 7.61275 13.2732 7.68732 13.2016 7.74681C12.8207 8.06382 12.4409 8.38248 12.0602 8.69984C11.7401 8.96654 11.4126 9.22073 11.0051 9.34701C10.758 9.42365 10.5086 9.42975 10.2546 9.39463C9.85903 9.33996 9.46679 9.35711 9.08228 9.47364C8.47634 9.65735 8.01673 10.0422 7.63672 10.5336C7.56878 10.6216 7.50961 10.7167 7.44083 10.8028C7.39259 10.8629 7.39923 10.9097 7.43629 10.9735C8.01249 11.9666 8.58694 12.9608 9.15963 13.956C9.30867 14.2141 9.22144 14.5218 8.96756 14.6488C8.7329 14.7661 8.45897 14.6762 8.31757 14.4321C7.92969 13.7619 7.54238 13.091 7.15564 12.4196C6.20952 10.7808 5.26333 9.14198 4.31708 7.50302C4.18259 7.27009 4.20978 7.02713 4.3868 6.86682C4.43919 6.81879 4.50151 6.78285 4.56932 6.76156C4.63713 6.74027 4.70876 6.73416 4.77911 6.74366C4.84946 6.75316 4.91679 6.77804 4.97631 6.81652C5.03583 6.855 5.08607 6.90614 5.12345 6.96629C5.13885 6.99217 5.15283 7.0184 5.18021 7.06583Z"
                                                  fill="#FF0000"
                                                />
                                              </svg>
                                              <span>Mark</span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  }

                                </React.Fragment>
                              )
                            } else {
                              return null; // Return null if there's no match
                            }
                          })
                        }
                      </div> 


                    </ul>
                  </div>
                ))
              }
            </div>
          } */}

          <div className="accordianSubmission">
            <button className="Previous" onClick={previousBtn}>
              Previous
            </button>
            <button
              className="save"
              onClick={() => {
                nextBtn();
                questionareData();
              }}
            >
              Save & Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Genericswot;
