import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthContext, PlanContext } from "../app/context";

const Plan = ({}) => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  // const apiUrl = "http://localhost:3333";
  const { user } = useContext(AuthContext);
  const { setPlanData } = useContext(PlanContext);
  const navigate = useNavigate();
  const [steps, setSteps] = useState(0);
  const [planName, setPlan] = useState("");
  const [person, setPerson] = useState(user?.userName);
  const [inputError, setInputError] = useState(false);
  const [component1, setComponent1] = useState({
    title: "Title of the Plan",
    hint: "(To differentiate between plans)",
  });
  const [component3, setComponent3] = useState({
    title: "Questionnaire will be filled by",
  });
  const [component4, setComponent4] = useState({
    title: "Let’s fill up the questionnaire now!",
  });
  const handlePersonSubmit = () => {
    setSteps((prev) => prev + 1);
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (steps === 0) {
        if (planName.trim() !== "") {
          handlePersonSubmit();
          setInputError(false);
        } else {
          setInputError(true);
        }
      } else if (steps === 1) {
        handlePersonSubmit();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEnterKey);
    return () => {
      document.removeEventListener("keydown", handleEnterKey);
    };
  }, []);

  useEffect(() => {
    if (steps === 2) {
      if (planName && person) {
        async function fetchData() {
          try {
            const response = await axios.post(
              `${apiUrl}/plan`,
              {
                planName: planName,
                planUser: person,
                user: user.userId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (response.data.success) {
              const data = response.data;
              setSteps(steps + 1);
              setPlanData(data.plan);
              setTimeout(() => {
                navigate("/questionnaire");
              }, [2000]);
            }
          } catch (err) {
            console.log(err);
            toast.error(err.response.data.message);
            setSteps(0);
          }
        }
        fetchData();
      }
    }
  }, [steps]);
  return (
    <>
      <Navbar />

      <div className="box1">
        <h1 className="text-lg">Strategic Plan</h1>
        <h3 className="text-sm">
          Start the process of filling up the business questionnaire
        </h3>
        {steps == 0 && (
          <>
            <div className="box2">
              <h1 className="text-lg">{component1.title}</h1>
              <h1 className="text-hint">{component1.hint}</h1>
            </div>
            <div className="div">
              <input
                placeholder="Type here"
                class="custom-input"
                value={planName}
                onChange={(e) => {
                  setInputError(false);
                  setPlan(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleEnterKey(e);
                  }
                }}
                required

                // onClick={()=>{navigate('/dashboard')}}
              />
              <button onClick={handlePersonSubmit}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="30"
                  viewBox="0 0 16 30"
                  fill="none"
                >
                  <path
                    d="M11.8229 14.5623L0.556285 3.29568C0.173067 2.91246 -0.0121559 2.45899 0.000618095 1.93525C0.0133921 1.41152 0.211388 0.958047 0.594607 0.574828C0.977826 0.191609 1.4313 0 1.95503 0C2.47877 0 2.93224 0.191609 3.31546 0.574828L15.0803 12.378C15.3869 12.6845 15.6168 13.0294 15.7701 13.4127C15.9234 13.7959 16 14.1791 16 14.5623C16 14.9455 15.9234 15.3287 15.7701 15.712C15.6168 16.0952 15.3869 16.4401 15.0803 16.7467L3.27714 28.5498C2.89392 28.933 2.44683 29.1182 1.93587 29.1055C1.42491 29.0927 0.977826 28.8947 0.594607 28.5115C0.211388 28.1283 0.019779 27.6748 0.019779 27.151C0.019779 26.6273 0.211388 26.1738 0.594607 25.7906L11.8229 14.5623Z"
                    fill="#8D8D8D"
                  />
                </svg>
              </button>
            </div>
            <small style={{ color: "red" }}>
              {inputError && "Plan Name is Required"}
            </small>
            <div className="steps">{steps + 1}/2</div>
          </>
        )}

        {steps == 1 && (
          <>
            <div className="box2">
              <h1 className="text-lg">{component3.title}</h1>
              <h1 className="text-sm">{component3.hint}</h1>
            </div>
            <div className="div">
              <select
                className="custom-input"
                autoFocus
                value={person}
                onChange={(e) => setPerson(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleEnterKey(e);
                  }
                }}
              >
                <option value={person}>Me</option>
              </select>
              <button onClick={handlePersonSubmit}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="30"
                  viewBox="0 0 16 30"
                  fill="none"
                >
                  <path
                    d="M11.8229 14.5623L0.556285 3.29568C0.173067 2.91246 -0.0121559 2.45899 0.000618095 1.93525C0.0133921 1.41152 0.211388 0.958047 0.594607 0.574828C0.977826 0.191609 1.4313 0 1.95503 0C2.47877 0 2.93224 0.191609 3.31546 0.574828L15.0803 12.378C15.3869 12.6845 15.6168 13.0294 15.7701 13.4127C15.9234 13.7959 16 14.1791 16 14.5623C16 14.9455 15.9234 15.3287 15.7701 15.712C15.6168 16.0952 15.3869 16.4401 15.0803 16.7467L3.27714 28.5498C2.89392 28.933 2.44683 29.1182 1.93587 29.1055C1.42491 29.0927 0.977826 28.8947 0.594607 28.5115C0.211388 28.1283 0.019779 27.6748 0.019779 27.151C0.019779 26.6273 0.211388 26.1738 0.594607 25.7906L11.8229 14.5623Z"
                    fill="#8D8D8D"
                  />
                </svg>
              </button>
            </div>
            <div className="steps">{steps + 1}/2</div>
          </>
        )}
        {steps == 3 && (
          <div className="box2">
            <h1 className="text-lg">{component4.title}</h1>
            <h1 className="text-sm">{component4.hint}</h1>
          </div>
        )}
        {/* {steps !== 3 && (
        
      )} */}
      </div>
      <Footer />
    </>
  );
};

export default Plan;
