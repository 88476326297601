import React from "react";
import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";
import Subscriptionplan from "../components/subscription/subscriptionplan";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(
  "pk_test_51OchyyBEDS9LMi4zMtKyJh8dCHqSjRwyDCAfZUrVE2rdIanHzZsmcdIId2aBHF0JE5SdR8Y3hDFixRa8fbHFNIoz00qh3n5jgx"
);

const Subscription = () => {
  return (
    <>
      <Navbar />
      <Elements stripe={stripePromise}>
        <Subscriptionplan />
      </Elements>
      <Footer />
    </>
  );
};

export default Subscription;
