import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AccessDenied = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center min-vh-100 bg-light"
    >
      <Row>
        <Col md={12} className="text-center">
          <h1 className="display-3 text-danger">Access Denied</h1>
          <p className="lead">This page is only for admin users.</p>
          <Button variant="primary" onClick={handleGoBack}>
            Go to Home
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AccessDenied;
