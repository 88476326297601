import React, { useState, useEffect, useRef, useContext } from "react";
import "./Dropdownsmall.scss";
import { Icon } from "@iconify/react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { PlanContext, selectDropdownContext } from "../../../app/context";

const Dropdownsmall = ({
  data,
  swot,
  selectedDropdownn,
  setselectedDropdownn,
  combinedArray,
}) => {
  // const { title, clrClass, inputtextgreen } = props;
  const { selectDropDownData, setSelectDropDownData } = useContext(
    selectDropdownContext
  );
  const { plan } = useContext(PlanContext);

  useEffect(() => {
    if (setselectedDropdownn) {
      setSelectDropDownData(selectedDropdownn);
    }
  }, [setselectedDropdownn]);
  const [isDropdownVisible, setDropdownVisibility] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState("Add in");

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisibility(!isDropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisibility(false);
  };
  const handleThemeSelect = (theme) => {
    console.log(theme);
    setSelectedTheme(theme.name == "Theme 1000" ? "Final Theme" : theme.name);
    const newEntry = {
      _id: data?._id,
      question: data?.question,
      swot: data?.swot,
      theme: theme?.theme,
      AnswersData: data,
      // themeName:   theme.name
      themeName: theme?.name == "Theme 1000" ? "Final Theme" : theme.name,
    };

    setselectedDropdownn((current) => {
      console.log(current);
      // Provide a fallback value to ensure current is an array
      const currentArray = current || [];
      const updatedArray = currentArray.map((item) => {
        // If the question is the same, update the theme
        // console.log(item)
        if (item?._id === newEntry?._id) {
          return {
            ...item,
            theme: newEntry.theme,
            themeName: newEntry.themeName,
          };
        }
        return item;
      });

      // Check if the question already exists
      const isQuestionExist = currentArray.some(
        (item) => item?._id === newEntry?._id
      );

      // If question doesn't exist, add it as a new entry
      if (!isQuestionExist) {
        return [...updatedArray, newEntry];
      } else {
        return updatedArray;
      }
    });
    closeDropdown();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {" "}
      <div className="first-goal">
        <div
          className="inputbox"
          onClick={toggleDropdown}
          ref={dropdownRef}
          style={{
            background:
              selectedTheme === "Add in"
                ? "linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%)"
                : "#E43A15",
          }}
        >
          <div className="leftside">{selectedTheme}</div>
          <div className="rightside">
            {isDropdownVisible ? (
              <Icon
                icon="fe:arrow-down"
                color="white"
                width="18"
                height="18"
                rotate={2}
              />
            ) : (
              <Icon icon="fe:arrow-down" color="white" width="18" height="18" />
            )}
          </div>

          {isDropdownVisible && (
            <div className="dropdown" onClick={closeDropdown}>
              {combinedArray?.map((data, index) => {
                // console.log(data)
                return (
                  // <p> {data.name} </p>

                  <div
                    className="firstdropdown"
                    onClick={() => handleThemeSelect(data)}
                  >
                    {data.name == "Theme 1000" ? (
                      <span>Final Theme</span>
                    ) : (
                      <span> {data.name} </span>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dropdownsmall;
