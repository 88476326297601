import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../app/context";

const Success = () => {
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState("");
  const { user } = useContext(AuthContext);
  //   const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  useEffect(() => {
    if (user) {
      setUserData(user);
      setUserId(user.userId);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      ConfirmPayment();
    }
  }, [userId]);
  const navigate = useNavigate();

  async function ConfirmPayment() {
    const body = {
      id: userData.userId,
    };

    const response = await fetch(`${apiUrl}/payment/confirm-payment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const session = await response.json();
    console.log("Session", session);

    if (session.sessionDetails.status === "complete") {
      toast("Payment has been made successfully");
      navigate("/questionnaire");
    }
  }

  return (
    <div
      style={{
        textAlign: "center",
        padding: "20px",
        border: "1px solid white",
      }}
    >
      Payment has been made successfully. Your Account is been upgrading...
      <br></br>You will automatically be redirected. <br></br>Do not close this
      window
    </div>
  );
};

export default Success;
