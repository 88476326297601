import React, { useContext, useEffect, useState } from "react";
import Strength from "../questionnaireComp/sharedbox/strength";
import { Icon } from "@iconify/react";
import Dropdownsmall from "../shared/smalldropdown/Dropdownsmall";
import axios from "axios";
import { useDispatch } from "react-redux";
import { selectDropdownContext } from "../../app/context";
import { MdAddComment } from "react-icons/md";

const Itemtheme = (props) => {
  const { selectDropDownData, setSelectDropDownData } = useContext(selectDropdownContext)
  const {
    selectedCards,
    themes,
    themeNames,
    subSelectedCards,
    toggleCardSelection,
    selectedDropdownn,
    setselectedDropdownn,
  } = props

  const combinedArray = themes?.map((theme, index) => {
    // console.log(theme);

    // Return null if theme is an empty string to avoid including it in the combined array
    if (theme === '') {
      return null;
    }

    // Return the structured object if theme is not empty
    return {
      theme: theme,
      name: themeNames[theme] || `Theme ${theme}`
    };
  }).filter(item => item !== null)



  // console.log(selectedCards)
  // console.log(selectDropDownData)
  return (
    <div>
      <div className="theme">


        <div className="theme-content ">
          <div className="theme-card">
            <div className="header-section">
              <div className="leftsection">
                <span>
                  flagged items from swot review - you can choose which item
                  goes to which theme{" "}
                </span>
              </div>
              <div className="right-section d-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="35"
                  viewBox="0 0 24 35"
                  fill="none"
                >
                  <path
                    d="M12 19.0498L15.19 22.2398C15.3917 22.4414 15.6483 22.5423 15.96 22.5423C16.2717 22.5423 16.5283 22.4414 16.73 22.2398C16.9317 22.0381 17.0325 21.7814 17.0325 21.4698C17.0325 21.1581 16.9317 20.9014 16.73 20.6998L13.54 17.5098L16.73 14.3198C16.9317 14.1181 17.0325 13.8614 17.0325 13.5498C17.0325 13.2381 16.9317 12.9814 16.73 12.7798C16.5283 12.5781 16.2717 12.4773 15.96 12.4773C15.6483 12.4773 15.3917 12.5781 15.19 12.7798L12 15.9698L8.81 12.7798C8.60833 12.5781 8.35167 12.4773 8.04 12.4773C7.72833 12.4773 7.47167 12.5781 7.27 12.7798C7.06833 12.9814 6.9675 13.2381 6.9675 13.5498C6.9675 13.8614 7.06833 14.1181 7.27 14.3198L10.46 17.5098L7.27 20.6998C7.06833 20.9014 6.9675 21.1581 6.9675 21.4698C6.9675 21.7814 7.06833 22.0381 7.27 22.2398C7.47167 22.4414 7.72833 22.5423 8.04 22.5423C8.35167 22.5423 8.60833 22.4414 8.81 22.2398L12 19.0498ZM12 28.5098C10.4783 28.5098 9.04833 28.221 7.71 27.6435C6.37167 27.066 5.2075 26.2823 4.2175 25.2923C3.2275 24.3023 2.44375 23.1381 1.86625 21.7998C1.28875 20.4614 1 19.0314 1 17.5098C1 15.9881 1.28875 14.5581 1.86625 13.2198C2.44375 11.8814 3.2275 10.7173 4.2175 9.72727C5.2075 8.73727 6.37167 7.95352 7.71 7.37602C9.04833 6.79852 10.4783 6.50977 12 6.50977C13.5217 6.50977 14.9517 6.79852 16.29 7.37602C17.6283 7.95352 18.7925 8.73727 19.7825 9.72727C20.7725 10.7173 21.5562 11.8814 22.1337 13.2198C22.7113 14.5581 23 15.9881 23 17.5098C23 19.0314 22.7113 20.4614 22.1337 21.7998C21.5562 23.1381 20.7725 24.3023 19.7825 25.2923C18.7925 26.2823 17.6283 27.066 16.29 27.6435C14.9517 28.221 13.5217 28.5098 12 28.5098ZM12 26.3098C14.4567 26.3098 16.5375 25.4573 18.2425 23.7523C19.9475 22.0473 20.8 19.9664 20.8 17.5098C20.8 15.0531 19.9475 12.9723 18.2425 11.2673C16.5375 9.56227 14.4567 8.70977 12 8.70977C9.54333 8.70977 7.4625 9.56227 5.7575 11.2673C4.0525 12.9723 3.2 15.0531 3.2 17.5098C3.2 19.9664 4.0525 22.0473 5.7575 23.7523C7.4625 25.4573 9.54333 26.3098 12 26.3098Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div className="innerContent">
              {/* Strengths */}
              <div className="s">
                <Strength title="Strengths" className="strength" />

                {selectedCards?.length > 0 ? selectedCards?.map((data, index) => {
                  // console.log(data);

                  if (data?.swot == "Strength") {
                    return (
                      <div className="first-card">


                        <span>
                          {
                            (data?.questionType === "AddedQuestion" || data?.AnswersData?.questionType === "AddedQuestion") &&
                            <div className="d-flex justify-content-end">
                              <MdAddComment color="#20AC27" size={20} />
                            </div>
                          }
                          {
                            (data?.questionType === "AddedQuestion" || data?.AnswersData?.questionType === "AddedQuestion") &&
                            <>
                              <strong>{data?.question} ?</strong> <br />
                            </>
                          }
                          {(data?.comment_yes !== '' || data?.AnswersData?.comment_yes !== '') ? (
                            data?.comment_yes || data?.AnswersData?.comment_yes
                          ) : null}

                          {(data?.answer !== "yes" && data?.AnswersData?.answer !== "yes" && data?.AnswersData?.answer != "notNeeded") && (
                            <p>{data?.answer || data?.AnswersData?.answer}</p>
                          )}
                        </span>



                        <div className="first-goal d-flex justify-content-between">
                          <p className="category"  >{data?.AnswersData?.category ? data?.AnswersData?.category : data?.category}</p>
                          <Dropdownsmall
                            data={data}
                            swot={data.swot}
                            selectedDropdownn={selectedDropdownn}
                            setselectedDropdownn={setselectedDropdownn}
                            combinedArray={combinedArray} />
                        </div>
                      </div>
                    )
                  }
                  return null
                }) : (
                  selectDropDownData && selectDropDownData.length > 0 && (
                    selectDropDownData?.map((data, index) => {
                      // console.log(data)
                      if (data.swot == "Strength") {
                        return (
                          <div className="first-card">

                            <span>
                              {
                                data?.AnswersData?.questionType === "AddedQuestion" &&
                                <div className="d-flex justify-content-end">
                                  <MdAddComment color="#FFC515" size={20} />
                                </div>
                              }
                              {(data?.comment_yes !== '' || data?.AnswersData?.comment_yes !== '') ? (
                                data?.comment_yes || data?.AnswersData?.comment_yes
                              ) : null}

                              {(data?.answer !== "yes" && data?.AnswersData?.answer !== "yes" && data?.answer !== "no" && data?.AnswersData?.answer !== "no") && (
                                <p>{data?.answer || data?.AnswersData?.answer}

                                  {/* {(data?.inputFields || data?.AnswersData?.inputFields)?.map((item, index) => (
                                  // item.inputFields != '' &&
                                  <ul style={{ listStyle: "Disc", paddingLeft: "24px" }}>
                                    <li>
                                      {item}

                                    </li>
                                  </ul>
                                  // console.log(item)

                                ))} */}
                                </p>
                              )}
                            </span>

                            <div className="first-goal">
                              <p className="category"  >{data?.AnswersData?.category ? data?.AnswersData?.category : data?.category}</p>
                              <Dropdownsmall
                                data={data}
                                swot={data.swot}
                                selectedDropdownn={selectedDropdownn}
                                setselectedDropdownn={setselectedDropdownn}
                                combinedArray={combinedArray} />
                            </div>
                          </div>
                        )
                      }
                    })
                  )
                )}
              </div>
              {/* Weaknesses */}
              <div className="s">
                <Strength title="Weaknesses" className="Weaknesses" />
                {
                  selectedCards?.length === 0 ? (
                    selectDropDownData && selectDropDownData?.length > 0 && (
                      selectDropDownData?.map((data, index) => {
                        // console.log(data);

                        if (data.swot === "Weakness") {
                          return (
                            <div key={index} className="first-card2">
                              {/* <span>{data.question}<br />  <strong>Answer:</strong>{data.comment_yes}</span> */}

                              {(data?.comment_no !== '' || data?.AnswersData?.comment_no !== '') ? (
                                data?.comment_no || data?.AnswersData?.comment_no
                              ) : null}
                              <span>{data?.answer === 'no' && data?.comment_no != '' ? data?.comment_no : ""
                              }{(data?.AnswersData?.answer === 'no' && data?.answer === 'no' && data?.AnswersData?.answer === 'yes' && data?.answer === 'yes' && data?.AnswersData?.answer != "notNeeded") && data?.AnswersData?.answer ? data?.AnswersData?.answer : data.answer}
                              </span>
                              <span>

                                {/* {data?.answer === 'no' && data?.comment_no != '' ? data?.comment_no : data?.AnswersData?.answer === 'no' ? data?.AnswersData?.comment_no : data?.AnswersData?.answer} */}
                              </span>


                              <div className="first-goal">
                                <p className="category"  >{data?.AnswersData?.category ? data?.AnswersData?.category : data?.category}</p>
                                {/* <p className="category"  >{data?.category}</p> */}
                                <Dropdownsmall
                                  data={data}
                                  swot={data.swot}
                                  selectedDropdownn={selectedDropdownn}
                                  setselectedDropdownn={setselectedDropdownn}
                                  combinedArray={combinedArray}
                                />
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })
                    )
                  ) : (
                    selectedCards?.map((data, index) => {
                      if (data.swot === "Weakness") {
                        // console.log(data)
                        return (

                          <div key={index} className="first-card2">
                            {/* <span>{data.question}<br />  <strong>Answer:</strong>{data.comment_yes}</span> */}
                            {/* <span> {data?.answer === 'no' && data?.comment_no != '' ? data?.comment_no : data?.AnswersData?.answer === 'no' ? data?.AnswersData?.comment_no : data?.AnswersData?.answer}</span> */}

                            {/* <span>{data?.answer === 'no' && data?.comment_no != '' ? data?.comment_no : ""
                          }{(data?.AnswersData?.answer === 'no' || data?.answer === 'no') && data?.AnswersData?.answer ? data?.AnswersData?.answer : data.answer}</span> */}
                            <span>
                              {
                                (data?.questionType === "AddedQuestion" || data?.AnswersData?.questionType === "AddedQuestion") &&
                                <div className="d-flex justify-content-end">
                                  <MdAddComment color="#F44336" size={20} />
                                </div>
                              }
                              {
                                (data?.questionType === "AddedQuestion" || data?.AnswersData?.questionType === "AddedQuestion") &&
                                <>
                                  <strong>{data?.question} ?</strong> <br />
                                </>
                              }
                              {(data?.comment_no !== '' || data?.AnswersData?.comment_no !== '') ? (
                                data?.comment_no || data?.AnswersData?.comment_no
                              ) : null}

                              {((data?.AnswersData?.answer != "no" && data.answer != "no") || (data?.AnswersData?.answer === 'yes' && data?.answer === 'yes')) && (
                                <p>{data?.AnswersData?.answer ? data?.AnswersData?.answer : data?.answer}
                                  {(data?.inputFields || data?.AnswersData?.inputFields)?.map((val, index) => (
                                    // item.inputFields != '' &&
                                    <ul style={{ listStyle: "Disc", paddingLeft: "24px" }}>
                                      <li>
                                        {val?.answer}
                                      </li>
                                    </ul>
                                    // console.log(item)

                                  ))}
                                </p>
                              )}
                            </span>


                            <div className="first-goal">
                              <p className="category"  >{data?.AnswersData?.category ? data?.AnswersData?.category : data?.category}</p>
                              <Dropdownsmall
                                data={data}
                                swot={data.swot}
                                selectedDropdownn={selectedDropdownn}
                                setselectedDropdownn={setselectedDropdownn}
                                combinedArray={combinedArray}
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })
                  )}

              </div>
              {/* Opportunities */}

              <div className="s">
                <Strength title="Opportunities" className="Opportunities" />
                {!selectedCards?.length === 0 ? (
                  selectDropDownData && selectDropDownData?.length > 0 && (
                    selectDropDownData?.map((data, index) => {
                      // console.log(data)

                      if (data.swot === "Opportunity") {
                        return (
                          <div key={index} className="first-card3">

                            <span>
                              {(data?.comment_third !== '' || data?.AnswersData?.comment_third !== '') ? (
                                data?.comment_third || data?.AnswersData?.comment_third
                              ) : null}
                              {(data?.AnswersData?.answer != "yes" && data?.AnswersData?.answer != "no" && data?.AnswersData?.answer != "notNeeded") && (
                                <p>{data?.AnswersData?.answer}</p>
                              )}
                            </span>

                            <div className="first-goal3 d-flex justify-content-between">
                              <p className="category" >{data?.AnswersData?.category ? data?.AnswersData?.category : data?.category}</p>
                              <Dropdownsmall
                                data={data}
                                swot={data.swot}
                                selectedDropdownn={selectedDropdownn}
                                setselectedDropdownn={setselectedDropdownn}
                                combinedArray={combinedArray}
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })
                  )
                ) : (
                  selectedCards?.map((data, index) => {
                    // console.log(data);

                    if (data.swot === "Opportunity" || data.swot_yes === "Opportunity") {

                      // console.log(data?.AnswersData)
                      // console.log(data)
                      return (
                        <div key={index} className="first-card3 ">

                          <span>

                            {
                              (data?.questionType === "AddedQuestion" || data?.AnswersData?.questionType === "AddedQuestion") &&

                              <div className="d-flex justify-content-end">
                                <MdAddComment color="#0B5085" size={20} />
                              </div>
                            }
                            {
                              (data?.questionType === "AddedQuestion" || data?.AnswersData?.questionType === "AddedQuestion") &&
                              <>
                                <strong>{data?.question} ?</strong> <br />
                              </>
                            }
                            {(data?.comment_third !== '' || data?.AnswersData?.comment_third !== '' || data?.comment_no !== '' || data?.AnswersData?.comment_no !== '') ? (
                              data?.comment_third || data?.AnswersData?.comment_third || data?.AnswersData?.comment_no || data?.comment_no
                            ) : null}
                            {(data?.AnswersData?.answer != "yes" && data.answer != "yes" && data?.AnswersData?.answer != "no" && data.answer != "no" && data?.AnswersData?.answer != "notNeeded" && data.answer != "notNeeded") && (
                              <p>{data?.AnswersData?.answer || data?.answer}</p>
                            )}
                          </span>

                          <div className="first-goal3 d-flex justify-content-between">
                            <p className="category" >{data?.AnswersData?.category ? data?.AnswersData?.category : data?.category}</p>
                            <Dropdownsmall
                              data={data}
                              swot={data.swot}
                              selectedDropdownn={selectedDropdownn}
                              setselectedDropdownn={setselectedDropdownn}
                              combinedArray={combinedArray}
                            />
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
                )}

              </div>
              {/* Threats */}
              <div className="s">
                <Strength title="Threats" className="Threats" />
                {!selectedCards?.length === 0 ? (
                  selectDropDownData && selectDropDownData?.length > 0 && (
                    selectDropDownData?.map((data, index) => {
                      // console.log(data);

                      if (data.swot === "Threat") {
                        return (
                          <div key={index} className="first-card4">
                            <span>
                              {data?.comment_fourth != '' ? data?.comment_fourth :
                                (data?.AnswersData?.answer != "yes" && data?.AnswersData?.answer != "no" && data?.AnswersData?.answer != "notNeeded") && data?.AnswersData?.answer}
                            </span>
                            <div className="first-goal4">
                              <p className="category" >{data?.AnswersData?.category ? data?.AnswersData?.category : data?.category}</p>

                              <Dropdownsmall
                                data={data}
                                swot={data.swot}
                                selectedDropdownn={selectedDropdownn}
                                setselectedDropdownn={setselectedDropdownn}
                                combinedArray={combinedArray}
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })
                  )
                ) : (
                  selectedCards?.map((data, index) => {
                    // console.log(data);

                    if (data.swot === "Threat") {
                      return (
                        <div key={index} className="first-card4">

                          <span>
                            {
                              (data?.questionType === "AddedQuestion" || data?.AnswersData?.questionType === "AddedQuestion") &&

                              <div className="d-flex justify-content-end">
                                <MdAddComment color="#FFC515" size={20} />
                              </div>
                            }
                            {
                              (data?.questionType === "AddedQuestion" || data?.AnswersData?.questionType === "AddedQuestion") &&
                              <>
                                <strong>{data?.question} ?</strong> <br />
                              </>
                            }
                            {(data?.comment_fourth !== '' || data?.AnswersData?.comment_fourth !== '') ? (
                              data?.comment_fourth || data?.AnswersData?.comment_fourth
                            ) : null}
                            {((data?.AnswersData?.answer != "yes" && data.answer != "yes" && data?.AnswersData?.answer != "no" && data.answer != "no" && data?.AnswersData?.answer != "notNeeded") || (data?.answer != "yes" && data?.answer != "no" && data?.answer != "notNeeded")) && (
                              <p>{data?.AnswersData?.answer ? data?.AnswersData?.answer : data?.answer}</p>
                            )}
                          </span>

                          {/* <span>{(data?.AnswersData?.answer != "yes" && data?.AnswersData?.answer != "no") ? <p>{data?.AnswersData?.answer}</p> : null}</span> */}
                          <div className="first-goal">
                            <p className="category" >{data?.AnswersData?.category ? data?.AnswersData?.category : data?.category}</p>

                            <Dropdownsmall
                              data={data}
                              swot={data.swot}
                              selectedDropdownn={selectedDropdownn}
                              setselectedDropdownn={setselectedDropdownn}
                              combinedArray={combinedArray}
                            />
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
                )}

              </div>

            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Itemtheme;
