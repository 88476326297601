import React from "react";
import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";
import Analysis from "../components/plan/analysis";

const Plans = () => {
  return (
    <>
      {" "}
      <Navbar />
      <Analysis />
      <Footer />
    </>
  );
};

export default Plans;
