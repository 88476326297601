import React, { useContext, useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { AuthContext } from "../../app/context";

const Subscriptionplan = () => {
  const stripe = useStripe();
  const { user } = useContext(AuthContext);
  // console.log(user);
  const [selectedCard, setSelectedCard] = useState(1);
  const [userData, setUserData] = useState({});
  const apiUrl = process.env.REACT_APP_BACKEND_API;

  async function StripePayment() {
    let plan;
    let price = 0;
    if (selectedCard === 0) {
      plan = "BASIC";
      price = 9;
    }
    if (selectedCard === 1) {
      plan = "BUSINESS";
      price = 29;
    }
    if (selectedCard === 2) {
      plan = "ULTIMATE";
      price = 99;
    }

    const body = {
      id: userData.userId,
      price: price,
      plan: plan,
    };

    const response = await fetch(`${apiUrl}/payment/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log("Error", result.error.message);
    }
  }

  useEffect(() => {
    setUserData(user);
  }, []);

  const handleCardClick = (index) => {
    if (index === 0) {
      // setSelectedCard(0);
      if (userData?.userId) {
        StripePayment();
      }
    }
    if (index === 1) {
      // setSelectedCard(1);
      if (userData?.userId) {
        StripePayment();
      }
    }
    if (index === 2) {
      // setSelectedCard(2);
      if (userData?.userId) {
        StripePayment();
      }
    }
  };

  return (
    <>
      <div className="subscriptionplan">
        <div className="subscriptionContent">
          <div className="subscriptionCard">
            <div
              className={`firstCard ${
                selectedCard === 0 ? "selectedCard" : ""
              }`}
              onClick={() => setSelectedCard(0)}
            >
              <div className="cardContent">
                <div className="cardTitle">
                  <span>BASIC</span>
                </div>
                <div className="cardContents">
                  <div className="contentTitle">
                    <div className="userdiv">
                      {" "}
                      <span>1 User</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="diskdiv">
                      <span className="disk">5 Gb Disk Space</span>
                    </div>

                    <div className="emailSupport">
                      <span>Email Support</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="emailSupport">
                      <span>24/7 Tech Support</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="techsupport">
                      <span>24/7 Tech Support</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="month">
                      <span className="dollar">$09</span>
                      <span className="months">MONTH</span>
                    </div>
                    <div className="purchaseButton">
                      <div
                        className="buttondiv"
                        onClick={() => handleCardClick(0)}
                      >
                        <span className="purchase">Purchase</span>
                      </div>
                      <div className="svgdiv">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="4"
                          height="6"
                          viewBox="0 0 4 6"
                          fill="none"
                        >
                          <path
                            d="M0.743164 1.3396L2.72216 3.3186L0.743164 5.29759"
                            stroke="#0D4D80"
                            stroke-width="0.989498"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`firstCard ${
                selectedCard === 1 ? "selectedCard" : ""
              }`}
              onClick={() => setSelectedCard(1)}
            >
              <div className="cardContent">
                <div className="cardTitle">
                  <span>BUSINESS</span>
                </div>
                <div className="cardContents">
                  <div className="contentTitle">
                    <div className="userdiv">
                      {" "}
                      <span>10 User</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="diskdiv">
                      <span className="disk">100 Gb Disk Space</span>
                    </div>

                    <div className="emailSupport">
                      <span>Email Support</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="emailSupport">
                      <span>24/7 Tech Support</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="techsupport">
                      <span>24/7 Tech Support</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="month">
                      <span className="dollar">$29</span>
                      <span className="months">MONTH</span>
                    </div>
                    <div className="purchaseButton">
                      <div
                        className="buttondiv"
                        onClick={() => handleCardClick(1)}
                      >
                        <span className="purchase">Purchase</span>
                      </div>
                      <div className="svgdiv">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="4"
                          height="6"
                          viewBox="0 0 4 6"
                          fill="none"
                        >
                          <path
                            d="M0.743164 1.3396L2.72216 3.3186L0.743164 5.29759"
                            stroke="#0D4D80"
                            stroke-width="0.989498"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`firstCard ${
                selectedCard === 2 ? "selectedCard" : ""
              }`}
              onClick={() => setSelectedCard(2)}
            >
              <div className="cardContent">
                <div className="cardTitle">
                  <span>ULTIMATE</span>
                </div>
                <div className="cardContents">
                  <div className="contentTitle">
                    <div className="userdiv">
                      {" "}
                      <span>100 User</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="diskdiv">
                      <span className="disk">5 Tb Disk Space</span>
                    </div>

                    <div className="emailSupport">
                      <span>Email Support</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="emailSupport">
                      <span>24/7 Tech Support</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="techsupport">
                      <span>24/7 Tech Support</span>
                      <div className="linearbg"></div>
                    </div>
                    <div className="month">
                      <span className="dollar">$99</span>
                      <span className="months">MONTH</span>
                    </div>
                    <div className="purchaseButton">
                      <div
                        className="buttondiv"
                        onClick={() => handleCardClick(2)}
                      >
                        <span className="purchase">Purchase</span>
                      </div>
                      <div className="svgdiv">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="4"
                          height="6"
                          viewBox="0 0 4 6"
                          fill="none"
                        >
                          <path
                            d="M0.743164 1.3396L2.72216 3.3186L0.743164 5.29759"
                            stroke="#0D4D80"
                            stroke-width="0.989498"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscriptionplan;
