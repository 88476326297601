import { createContext, useEffect, useState } from "react";

export const selectDropdownContext = createContext("");

//User Auth context
const AuthContext = createContext();
//plan context
const PlanContext = createContext();

//auth context provider
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("User")));

  // Initialize user state from localStorage
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("User"));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  // Function to handle login
  const login = (userData) => {
    // localStorage.setItem("role", JSON.stringify(userData.role));
    localStorage.setItem("User", JSON.stringify(userData));
    setUser(userData);
  };

  // Function to handle logout
  const logout = () => {
    // localStorage.removeItem("role");
    localStorage.removeItem("User");
    localStorage.removeItem("plan");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

//plan context provider
const PlanProvider = ({ children }) => {
  const [plan, setPlan] = useState(JSON.parse(localStorage.getItem("plan")));

  useEffect(() => {
    setPlan(JSON.parse(localStorage.getItem("plan")));
  }, []);

  const setPlanData = (planData) => {
    localStorage.setItem("plan", JSON.stringify(planData));
    setPlan(planData);
  };
  return (
    <PlanContext.Provider value={{ plan, setPlanData }}>
      {children}
    </PlanContext.Provider>
  );
};

export { AuthContext, AuthProvider, PlanContext, PlanProvider };
// export default selectDropdownContext;
