import React, { useEffect, useState } from "react";
import styles from "./task-list-table.module.css";
import { toast } from "react-toastify";
import axios from "axios";

const TaskForm = ({ pId, gId, setForm, setGantData }) => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;

  const [inputValues, setInputValues] = useState({
    name: "Q1",
    tactic: "",
    start: Date.now(),
    end: Date.now(),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleClose = (e) => {
    e.preventDefault();
    setForm("");
    setInputValues({
      name: "Q1",
      tactic: "",
      start: Date.now(),
      end: Date.now(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const startDateObj = new Date(inputValues.start);
    const endDateObj = new Date(inputValues.end);
    // console.log(inputValues.start, inputValues.end)
    const reqBody = {
      ...inputValues,
      start: startDateObj.toISOString(),
      end: endDateObj.toISOString(),
      gId,
    };
    // console.log(reqBody)
    try {
      // console.log("sdfsdf sdfsdf")
      const resp = await axios.post(
        `${apiUrl}/ganttest/addTactics/${pId}`,
        reqBody
      );
      // console.log("sdfsdf sdfsdf")
      // console.log(resp)
      setGantData(resp.data);
      if (resp.data.success) {
        toast.success("Added successfully");
      }
      setForm("");
    } catch (error) {
      toast.error("some thing went wrong");
      setInputValues({
        name: "Q1",
        tactic: "",
        start: Date.now(),
        end: Date.now(),
      });
      setForm("");
    }
  };
  return (
    <form className={styles.formcontaier}>
      <input
        type="text"
        name="tactic"
        required
        value={inputValues.tactic}
        onChange={handleInputChange}
        placeholder="Enter Tactics"
      />
      <br />
      <select
        value={inputValues.name}
        name="name"
        style={{ width: "100%", padding: "10px" }}
        onChange={handleInputChange}
        required
      >
        <option value="Q1">Quarter 1</option>
        <option value="Q2">Quarter 2</option>
        <option value="Q3">Quarter 3 </option>
        <option value="Q4">Quarter 4 </option>
      </select>
      {/* <input type="text" name="personAccountable" value={inputValues.personAccountable} onChange={handleInputChange} placeholder="Person Accountable" /><br /> */}
      <label>start Date</label>
      <input
        type="date"
        name="start"
        value={inputValues.start}
        onChange={handleInputChange}
        placeholder="start data"
        min={new Date().toISOString().split("T")[0]}
      />
      <br />
      <label>End Date</label>
      <input
        type="date"
        name="end"
        value={inputValues.end}
        onChange={handleInputChange}
        placeholder="end date"
        min={new Date().toISOString().split("T")[0]}
      />
      <br />
      <div className={styles.addtask}>
        <button onClick={handleSubmit}>save</button>
        <button onClick={handleClose}>Close</button>
      </div>
    </form>
  );
};

export default TaskForm;
