import React from "react";
import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";
import TestGoal from "../components/goal/TestGoal";

const Goals = () => {
  return (
    <>
      <Navbar />
      <TestGoal />
      <Footer />

    </>
  );
};

export default Goals;
