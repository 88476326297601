import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const Congratsswot = (selectedCards) => {
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className="congratsswot">
        <div className="congratswot-content">
          <div className="congrats-title">
            <div className="heading">
              <h4>Congratulations!</h4>
            </div>
            <div className="subheading">
              <p>
                On Completing the SWOT review of business <br /> health
                questionnaire
              </p>
            </div>
          </div>
          {submitted === false ? (
            <>
              <div
                className="submitSwot"
                onClick={() =>
                  navigate("/medical", { state: { selectedCards } })
                }
              >
                <h6>Submit SWOT Review </h6>
                <p>You will be able to update it after this!</p>
              </div>
              {/* <div className="submitBtn">
                <button className="Previous">Previous</button>
                <button
                  className="Save"
                  onClick={() => {
                    setSubmitted(true);
                  }}
                >
                  Save{" "}
                </button>
              </div> */}
            </>
          ) : (
            <p className="successMessage">SWOT Review has been Submitted.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Congratsswot;
