import React, { useContext, useEffect, useState } from "react";
// import { ViewMode, Gantt } from "gantt-task-react";
import { ViewMode } from "../types/public-types";
import { Gantt } from "../components/gantt/gantt";
import ViewSwitcher from "./components/view-switcher";
import { getStartEndDateForProject } from "./helper";
import "./index.css";
import "gantt-task-react/dist/index.css";
import TaskForm from "../components/task-list/TaskForm";
import CustomModal from "./components/Modal";
import { PlanContext } from "../../app/context";

//@ts-ignore
// import Modal from "./components/Modal";

// Init
const GanttMain = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const { plan } = useContext(PlanContext);
  const [view, setView] = useState(ViewMode.Month);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [gantData, setGanttData] = useState([]);
  //@ts-ignore
  // const [selectedTask, setSelectedTask] = useState();
  const [isChecked, setIsChecked] = useState(true);
  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const getGanttData = async () => {
    try {
      let response = await fetch(`${apiUrl}/ganttest/${plan._id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      let data = await response.json();
      // Transform dates to Date objects
      data.forEach((t) => {
        t.goals?.forEach((ta) => {
          ta.tactics?.forEach((task) => {
            task.start = new Date(
              new Date(task.start).getFullYear(),
              new Date(task.start).getMonth(),
              new Date(task.start).getDate()
            );
            task.end = new Date(
              new Date(task.end).getFullYear(),
              new Date(task.end).getMonth(),
              new Date(task.end).getDate()
            );
            task.planName = t?._id;
            task.goalName = ta?.goalName;
          });
        });
      });

      // data.forEach((t) => {
      //   t.goals?.forEach((ta) => {
      //     ta.tactics?.forEach((task) => {
      //       // Parse date from string representation
      //       const startDate = new Date(task.start);
      //       const endDate = new Date(task.end);

      //       // Set time zone to UTC
      //       startDate.setTime(startDate.getTime() + startDate.getTimezoneOffset() * 60 * 1000);
      //       endDate.setTime(endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000);

      //       // Set time components to zero
      //       startDate.setUTCHours(0, 0, 0, 0);
      //       endDate.setUTCHours(0, 0, 0, 0);

      //       // Assign adjusted dates
      //       task.start = startDate;
      //       task.end = endDate;

      //       // Assign planName and goalName properties
      //       task.planName = t?._id;
      //       task.goalName = ta?.goalName;
      //     });
      //   });
      // });

      setTasks(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      console.error("Error fetching data:", error);
      // Handle error
    }
  };

  useEffect(() => {
    getGanttData();
  }, [gantData]);
  console.log(tasks[0]?.goals[0]?.tactics[0]);

  const handleTaskChange = async (task) => {
    if (task) {
      // console.log("hi hih ih");

      console.log(task);
      console.log(task.planName);
      try {
        const requestBody = JSON.stringify({
          start: task.start, // Assuming startDate is a Date object
          end: task.end, // Assuming endDate is a Date object
          status: task.status,
          personAccountable: task.personAccountable,
        });
        let response = await fetch(
          `${apiUrl}/ganttest/update/${task.id}/${task.planName}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: requestBody,
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        getGanttData();
      } catch (error) {}
    }
  };

  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task) => {
    // alert("On Double Click event Id:" + task.id);
    setSelectedTask(task);
    setshowModal(true);
  };

  const handleClick = (task) => {
    console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task, isSelected) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };
  const closeModal = () => {
    setSelectedTask(null);
  };

  return (
    <div className="Wrapper">
      <ViewSwitcher
        onViewModeChange={(viewMode) => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
        viewMode={view}
      />
      {/* <h3>Gantt With Unlimited Height</h3> */}
      <br />
      {tasks.length > 0 && (
        <div>
          {/* <TaskForm /> */}
          <Gantt
            setTasks={setTasks}
            setGantData={setGanttData}
            tasks={tasks}
            viewMode={view}
            onDateChange={handleTaskChange}
            onDelete={handleTaskDelete}
            onProgressChange={handleProgressChange}
            onDoubleClick={handleDblClick}
            onClick={handleClick}
            onSelect={handleSelect}
            onExpanderClick={handleExpanderClick}
            listCellWidth={isChecked ? "155px" : ""}
            columnWidth={columnWidth}
          />
          {selectedTask && (
            <CustomModal
              task={selectedTask}
              onClose={closeModal}
              showModal={showModal}
              setShowModal={setshowModal}
              getGanttData={getGanttData}
            />
          )}
          <br />
        </div>
      )}
      {/* {selectedTask &&
        <Modal task={selectedTask} onClose={closeModal} showModal={showModal} setShowModal={setshowModal} getGanttData={getGanttData} />
      } */}
    </div>
  );
};

export default GanttMain;
