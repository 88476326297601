import React, { useState } from "react";
import Footer from "../shared/footer";
import "../../style/Admindashboard/Addcoach.css";
import NavbarNew from "./NavbarNew";
import axios from "axios";
import { toast } from "react-toastify";
import Navbar from "../shared/navbar";

const Addcoach = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API

  const [data, Setdata] = useState({
    firstName: "",
    lastName: "",
    email: "",
    title: ""
  });
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes({ ...checkboxes, [name]: checked });
  };

  // var apiUrl = process.env.VITE_API_BASE_URL
  const InputEvent = (event) => {
    const { name, value } = event.target;

    Setdata((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };


  const formSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${apiUrl}/addcoach/create`,
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          title: data.title,
          AssignPlan: checkboxes
        }
      );

      const dataUser = response.data;

      if (response.data) {
        toast.success("Coach added successfully!");
      } else {
        toast.error("Failed to add coach.");
      }
    } catch (err) {
      console.log(err);
      console.error("Error:", err);
      if (err.response) {
        toast.error(`Error: ${err.response.data.message}`);
      }
    }
  };
  return (
    <>
      <Navbar />
      <div className="container-fluid ">
        <div className="row ">
          <div className="col-lg-12 col-md-12 col-sm-12 first">
            <div className="admindashboard d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.9999 17.7564C12.6467 17.7564 13.1717 17.2314 13.1717 16.5845V13.172H16.5795C17.2264 13.172 17.7514 12.647 17.7514 12.0002C17.7514 11.3533 17.2264 10.8283 16.5795 10.8283H13.1717V7.42047C13.1717 6.7736 12.6467 6.2486 11.9999 6.2486C11.353 6.2486 10.828 6.7736 10.828 7.42047V10.8283H7.42017C6.77329 10.8283 6.24829 11.3533 6.24829 12.0002C6.24829 12.647 6.77329 13.172 7.42017 13.172H10.828V16.5798C10.828 17.2314 11.353 17.7564 11.9999 17.7564Z"
                  fill="#0D4D80"
                />
                <path
                  d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM2.39982 12C2.39982 17.302 6.69797 21.6002 12 21.6002C17.302 21.6002 21.6002 17.302 21.6002 12C21.6002 6.69797 17.302 2.39982 12 2.39982C6.69797 2.39982 2.39982 6.69797 2.39982 12Z"
                  fill="#0D4D80"
                />
              </svg>
              <span>Add Coach</span>
            </div>
          </div>
          <div className="col-lg-12 d-flex formm justify-content-center my-5">
            <div className="second w-40">
              {/* <h2>Let’s bring coach on board</h2> */}
              <div className="my-4">
                <h3 className="text-center"> Let’s bring coach on board</h3>
              </div>
              <div className="container contact_div">
                <div className="row">
                  <div className="col-12 ">
                    <form action="" onSubmit={formSubmit}>
                      <div className="mb-3 form ">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          FirstName
                        </label>
                        <input
                          type="name"
                          className="form-control"
                          id="exampleFormControlInput1"
                          name="firstName"
                          value={data.firstName}
                          onChange={InputEvent}
                          placeholder="Enter Your First Name"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          LastName
                        </label>
                        <input
                          type="LastName"
                          className="form-control"
                          id="exampleFormControlInput1"
                          name="lastName"
                          value={data.lastName}
                          onChange={InputEvent}
                          placeholder="Enter Last Name"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          name="email"
                          value={data.email}
                          onChange={InputEvent}
                          placeholder="@gmail.com"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Title
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Assistant coach"
                          id="exampleFormControlInput1"
                          rows="1"
                          name="title"
                          value={data.title}
                          onChange={InputEvent}
                        />
                      </div>

                      <div className="last_div d-flex w-100">
                        {/* <h3>Permissions</h3>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox1"
                            value="90 days plan"
                            name="checkbox1"
                            checked={checkboxes.checkbox1}
                            onChange={handleCheckboxChange}
                          />
                          <label class="form-check-label" for="inlineCheckbox1">
                            90 days plan
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox2"
                            value="Kanban Board"
                            name="checkbox2"
                            checked={checkboxes.checkbox2}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            for="inlineCheckbox2"
                          >
                            Kanban Board
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox3"
                            value="One page Plan"
                            name="checkbox3"
                            checked={checkboxes.checkbox3}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            for="inlineCheckbox3"
                          >
                            One Page Plan{" "}
                          </label>
                        </div> */}
                        <div className="buttons d-flex justify-content-between">
                          <div className="forgradientwrap">
                            <button
                              className="btn  first_btn"
                              type="submit"
                            // onClick={handleAddCoach}
                            >
                              Save
                            </button>
                          </div>
                          <button className=" second_btn " type="cancel">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {/* <Footer/> */}
      </div>
    </>
  );
};

export default Addcoach;
