

import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import './loader.css'
import { AuthContext } from "../app/context";
const ProtectedRoute = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  // const apiUrl = "http://localhost:3333";
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(AuthContext);

  const verifyToken = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${apiUrl}/user/authUser`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Cache-Control": "no-store",
        },
      });
      const data = await response.json();
      if (response.ok) {
        return;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (user) {
      verifyToken();
    }
  }, []);
  return isLoading ? (
    <div className="loader_container">
      <div class="loader"></div>
    </div>
  ) : (
    <>
      <Outlet />
    </>
  );
};
export default ProtectedRoute;
