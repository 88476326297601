import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { dropdownOptions } from "./dropDownOptions";

const EditForm = ({
  show,
  handleClose,
  initialData,
  sheetName,
  handleUpdate,
}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleUpdate(sheetName, formData);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <Form onSubmit={handleFormSubmit}>
            {Object.keys(initialData)
              .filter((key) => key !== "Item #")
              .map((key) => (
                <Form.Group key={key} controlId={key}>
                  <Form.Label>{key}</Form.Label>
                  {dropdownOptions[key] ? (
                    <Form.Control
                      as="select"
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                    >
                      <option value="">Select One</option>
                      {dropdownOptions[key].map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Control>
                  ) : (
                    <Form.Control
                      type={key.includes("Date") ? "date" : "text"}
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                    />
                  )}
                </Form.Group>
              ))}
            <Button variant="primary" type="submit" className="mt-3">
              Save Changes
            </Button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditForm;
