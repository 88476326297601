import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FaPlus } from "react-icons/fa6";
import KanbanTask from "./KanbanTask";
import axios from "axios";
import { toast } from "react-toastify";

const KanbanTactics = (props) => {
  const { tacticData, goalIndex, priorityIndex, goalId, pId, setIds } = props;
  const [tactics, setTactics] = useState(tacticData);

  useEffect(() => {
    setTactics(tacticData);
  }, [tacticData?.length]);

  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const statuses = [
    "scheduled",
    "onTrack",
    "completed",
    "wont_be_done",
    "delayed",
  ];
  const organizedTactics = statuses.map((status) => ({
    status: status,
    tactics: tactics.filter((tactic) => tactic.status === status),
  }));

  const onDragEnd = async (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    const sourceColumnIndex = statuses.indexOf(source.droppableId);
    const destinationColumnIndex = statuses.indexOf(destination.droppableId);

    const sourceTacticIndex = organizedTactics[sourceColumnIndex].tactics.findIndex(
      (tactic) => tactic._id === draggableId
    );
    const movedTactic = organizedTactics[sourceColumnIndex].tactics[sourceTacticIndex];

    if (source.droppableId !== destination.droppableId) {
      movedTactic.status = destination.droppableId;
    }

    organizedTactics[sourceColumnIndex].tactics.splice(sourceTacticIndex, 1);
    organizedTactics[destinationColumnIndex].tactics.splice(destination.index, 0, movedTactic);

    const updatedTactics = [...tactics];
    const originalTacticIndex = updatedTactics.findIndex((tactic) => tactic._id === draggableId);
    updatedTactics[originalTacticIndex] = movedTactic;

    // setTactics(updatedTactics);

    try {
      const resp = await axios.post(`${apiUrl}/ganttest/update/goalTactic`, {
        tacticId: movedTactic._id,
        priorityId: pId,
        goalId: goalId,
        status: destination.droppableId,
      });
      if (resp?.data?.success) {
        toast.success("Changed successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleAddTactic = (status) => {
    setIds({ pId: pId, gId: goalId, status });
    props.openModal(goalId);
  };

  return (
    <div className="">
      <div className="">
        <div className="kanban-tasks-container w-100">
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="kanban-column w-100">
              <div className="kanban-columns-container">
                {organizedTactics.map((column, index) => (
                  <div className="" key={index}>
                    <div className="KanbanTask d-flex justify-content-between align-items-center gap-2">
                      <h4 className="text-center">
                        {column.status === "wont_be_done"
                          ? "Won't Be Done"
                          : column.status.charAt(0).toUpperCase() +
                            column.status.slice(1)}
                      </h4>
                      <Droppable droppableId={column.status}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className=""
                          >
                            {column.tactics.map((tactic, tIndex) => (
                              <Draggable
                                key={tactic._id}
                                draggableId={tactic._id}
                                index={tIndex}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="max-card"
                                  >
                                    <KanbanTask
                                      tactics={tactics}
                                      setTactics={setTactics}
                                      {...props}
                                      key={tactic.id}
                                      task={tactic}
                                      id={tactic.id}
                                      goalIndex={goalIndex}
                                      tIndex={tIndex}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      <div className="kanban-column-footer w-100">
                        <button
                          className="kanban-column-btn-add"
                          onClick={() => handleAddTactic(column.status)}
                        >
                          <FaPlus />
                          Add task
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default KanbanTactics;
