import { configureStore } from '@reduxjs/toolkit'
import planReducer from "../features/plan/planSlice"
import userReducer from "../features/user/userSlice"
import taskReducer from '../features/kanban-task/taskSlice'
export const store = configureStore({
  reducer: {
    plan: planReducer,
    user: userReducer,
    task: taskReducer,
    // selectDropdown:
  },
})