import React, { useContext, useEffect, useState } from "react";
import { columnsRawData } from "../components/kanban/KanbanData";
import KanbanColumn from "../components/kanban/KanbanColumn";
import "./kanban.css";
import KanbanModal from "../components/kanban/KanbanModal";
import { DragDropContext } from "react-beautiful-dnd";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext, PlanContext } from "../app/context";

const Kanban = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const [columns, setColumns] = useState([]);
  const [modal, setModal] = useState(false);
  const [fetch, setFetch] = useState(1);
  const [Ids, setIds] = useState({ pId: "", gId: "", status: "" });
  const navigate = useNavigate();
  const location = useLocation();
  const strategicData = location.state?.buttonMessages;
  const userGoals = location?.state?.userGoals;
  const StrategicFocus = location?.state?.userFocus;
  const userPriorities = location?.state?.userPriorities;
  const { plan } = useContext(PlanContext);
  const { user } = useContext(AuthContext);
  const handleGetData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/ganttest/${plan._id}`);
      setColumns(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleGetData();
  }, [fetch]);
  const incrementFetch = () => {
    setFetch(fetch + 1);
  };

  const openModal = (goalId) => {
    setModal(goalId);
  };

  const closeModal = () => {
    setModal(false);
  };

  // console.log(fetch)
  return (
    <>
      <div className="Kanban">
        {modal && (
          <KanbanModal
            Ids={Ids}
            closeModal={closeModal}
            columnData={modal}
            setColumns={setColumns}
            columns={columns}
            incrementFetch={incrementFetch}
          />
        )}
        <div className="strategicpriorities" style={{ padding: "0px" }}>
          <div className="prioritiesContent">
            <div className="prioritiesHeader">
              <span>Strategic Priorities</span>
            </div>
          </div>
        </div>
        <div className="Kanban-columns-container">
          {columns?.map((c, pIndex) => {
            return (
              <KanbanColumn
                pId={c._id}
                gantData={c}
                key={c.name}
                openModal={openModal}
                incrementFetch={incrementFetch}
                setColumns={setColumns}
                setIds={setIds}
                columns={columns}
                pIndex={pIndex}
              />
            );
          })}
        </div>
      </div>
      <div className="planButton-next">
        {/* {console.log())} */}

        {user.role === "user" || user.role === "coach" ? (
          <button
            onClick={() => {
              navigate("/onepageplan", {
                state: {
                  strategicData,
                  userGoals,
                  StrategicFocus,
                  userPriorities,
                },
              });
            }}
          >
            1 Page plan
          </button>
        ) : (
          JSON.parse(localStorage.getItem("Member"))?.permissions?.includes(
            "One Page Plan"
          ) && (
            <button
              onClick={() => {
                navigate("/onepageplan", {
                  state: {
                    strategicData,
                    userGoals,
                    StrategicFocus,
                    userPriorities,
                  },
                });
              }}
            >
              1 Page plan
            </button>
          )
        )}
      </div>
    </>
  );
};

export default Kanban;
