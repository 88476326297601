import React from "react";
import style from "./bar.module.css";

export const BarDisplay = ({
  tasks,
  task,
  x,
  y,
  width,
  height,
  isSelected,
  progressX,
  progressWidth,
  barCornerRadius,
  styles,
  onMouseDown,
}) => {
  const getProcessColor = () => {
    switch (task?.status) {
      case "onTrack":
        return "green";
      case "completed":
        return "blue"; // Green color for completed tasks
      case "scheduled":
        return "purple"; // Purple color for waiting tasks
      case "wont_be_done":
        return "red"; // Blue color for delayed tasks
      case "delayed":
        return "#ffce08";
      default:
        return "#808080"; // Gray color for any other status
    }
  };

  const getBarColor = () => {
    return isSelected ? styles.backgroundSelectedColor : styles.backgroundColor;
  };

  return (
    <g onMouseDown={onMouseDown}>
      <rect
        x={x}
        width={width}
        y={y}
        height={height}
        ry={barCornerRadius}
        rx={barCornerRadius}
        fill={getBarColor()}
        className={style.barBackground}
      />
      <rect
        x={progressX}
        width={progressWidth}
        y={y}
        height={height}
        ry={barCornerRadius}
        rx={barCornerRadius}
        fill={getProcessColor()}
      />
    </g>
  );
};
