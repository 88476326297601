import { useNavigate } from "react-router-dom";

const Result = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="result">
        <div className="resultContent">
          <div className="mainContent">
            <div className="title">
              <h5>Congratulations!</h5>
              <h6>On Completing your business health questionnaire</h6>
            </div>
            <div className="resultCard">
              <div className="rightCard">
                <div
                  className="cardContent"
                  onClick={() => {
                    navigate("/goal");
                  }}
                >
                  <h6>Skip Review, Mark as Final Selection</h6>
                  <span>You will not be able to update it after this!</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Result;
