import React, { useEffect, useState } from "react";
import "./KanbanTask.css";
import { Draggable } from "react-beautiful-dnd";
import useToggle from "./useToggleState";
import KanbanEditForm from "./KanbanEditForm";
import { FiEdit } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { toast } from "react-toastify";

const KanbanTask = (props) => {
    const apiUrl = process.env.REACT_APP_BACKEND_API
    const [isEditing, toggle] = useToggle(false);
    const [status, setStatus] = useState(props.task.status)
    const [task, setTask] = useState(props.task)
    const { setColumns, columns, pId, pIndex, goalIndex } = props
    useEffect(() => {
        setTask(props?.task)
    }, [props?.task])

    const handleStatusChange = async (newStatus, tacticId) => {
        try {
            await axios.put(`${apiUrl}/ganttest/update/${tacticId}/${props.pId}`, { status: newStatus });

        } catch (error) {
            console.log("Error updating status:", error);
        }
    };

    const handleStatusChangeWrapper = (e, pIndex, goalIndex, tId) => {

        const newStatus = e.target.value;

        setStatus(newStatus); // Update status state first

        const newColumn = [...columns];

        let tIndex = newColumn[pIndex].goals[goalIndex].tactics?.findIndex((tac) => tac._id === tId)

        newColumn[pIndex].goals[goalIndex].tactics[tIndex].status = newStatus;
        let tacticId = newColumn[pIndex].goals[goalIndex].tactics[tIndex]?.id
        console.log(tacticId)
        setColumns(newColumn);
        const updatedTactics = props?.tactics?.map((item) => {
            if (item?.id === tacticId) {
                // Return a new object with the updated status
                return { ...item, status: newStatus };
            }
            // Return the original item if it's not the one to update
            return item;
        });

        // Update the state with the new tactics array
        props.setTactics(updatedTactics);
        handleStatusChange(newStatus, tacticId)
    };
    const removeTactic = async (priorityIndex, goalIndex, tacticId) => {
        const newData = [...columns];
        console.log(priorityIndex, goalIndex, tacticId)
        let tIndex = newData[priorityIndex].goals[goalIndex].tactics?.findIndex((tac) => tac._id === tacticId)
        const pId = newData[priorityIndex]._id
        const gId = newData[priorityIndex].goals[goalIndex]._id
        const tId = newData[priorityIndex].goals[goalIndex].tactics[tIndex]._id
        newData[priorityIndex].goals[goalIndex].tactics.splice(tIndex, 1);
        setColumns(newData);
        if (columns.length > 0) {
            try {
                const resp = await axios.post(`${apiUrl}/ganttest/deleteTactic`, { priorityId: pId, goalId: gId, tacticId: tId })
                if (resp.data.success) {
                    toast.success("removed successfully")
                }
            } catch (error) {
                toast.error(error.response.data.message)
            }
        } else {
            toast.error("Please fill any tactic")
        }

    };
    return (
        <div
            className="KanbanTask"
        >
            {isEditing ? (
                <KanbanEditForm
                    {...props}
                    setTask={setTask}
                    taskTactic={task.tactic}
                    taskId={task.id}
                    toggle={toggle}
                    startText={task.name}
                    status={task.status}
                    startDate={task.start}
                    endDate={task.end}
                    personAccountable={task.personAccountable}
                />
            ) : (
                <>
                    <div className={`kanban-card-container w-100`}>
                        <div className={`kanban-card-header ${task.status}`}>
                            {/* <div>
                                        <span className="kanban-header-title">{task.text}</span>
                                    </div> */}
                            {/* <div>edit</div> */}
                            <RxCross2 className="KanbanTask-btn-delete mt-4 border"
                                style={{
                                    color: "red",
                                }}
                                onClick={() =>
                                    removeTactic(pIndex, goalIndex, task?._id)
                                }
                            />
                            <FiEdit className="KanbanTask-btn-edit border mt-3"
                                style={{
                                    color: "black",
                                }}
                                onClick={toggle} />
                        </div>
                        <div className="kanban-card-data-container mt-2">

                            <div>
                                <h6>
                                    tactic: {task.tactic}
                                </h6>
                            </div>

                            <div>
                                <h6 >
                                    Quarter: {task.name}
                                </h6>
                            </div>
                            <div>
                                <h6 >
                                    Person Accountable: {task.personAccountable}
                                </h6>
                            </div>
                            <div>
                                <h6 >
                                    Start Data: {new Date(task.start).toLocaleDateString()}
                                </h6>
                            </div>
                            <div>
                                <h6 >
                                    End Data: {new Date(task.end).toLocaleDateString()}
                                </h6>
                            </div>
                            <div className="kanban-select-status">
                                <select className="kanban-status-select" value={status} onChange={(e) => handleStatusChangeWrapper(e, pIndex, goalIndex, task?._id)}>
                                    <option value="scheduled">Scheduled</option>
                                    <option value="onTrack">on Track</option>
                                    <option value="completed">completed </option>
                                    <option value="wont_be_done">won't be done </option>
                                    <option value="delayed">Delayed</option>
                                </select>
                            </div>

                        </div>
                    </div>

                </>
            )}
        </div>
    );
};

export default KanbanTask;