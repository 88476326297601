import React from "react";
import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";
import Updatemedical from "../components/medical/updatemedical";
import {useNavigate } from 'react-router-dom';
const Medical = () => {
  return (
    <>
      <Navbar />
      <Updatemedical />
      <Footer />
    </>
  );
};

export default Medical;
