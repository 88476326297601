import React from "react";
// import Navbar from "../components/shared/navbar";
// import Footer from "../components/shared/footer";
import Itemtheme from "../components/itemtheme/itemtheme";

const Theme = (props) => {
  const {
    selectedCards,
    themes,
    themeNames,
    subSelectedCards,
    toggleCardSelection,
    selectedDropdownn,
    setselectedDropdownn,

  } = props
  // console.log(selectedCards)
  return (
    <>
      {/* <Navbar /> */}
      <Itemtheme
        themes={themes}
        themeNames={themeNames}
        subSelectedCards={subSelectedCards}
        selectedCards={selectedCards}
        toggleCardSelection={toggleCardSelection}
        selectedDropdownn={selectedDropdownn}
        setselectedDropdownn={setselectedDropdownn}
      />
      {/* <Footer /> */}
    </>
  );
};

export default Theme;

