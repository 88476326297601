import React, { useEffect, useState } from "react";
import useInputState from "./useInputState";
import "./KanbanEditForm.css";
import axios from "axios";
import { toast } from "react-toastify";

const KanbanEditForm = (props) => {
    // console.log(props)
    const apiUrl = process.env.REACT_APP_BACKEND_API
    const [quarter, setQuarter] = useState(props.startText);
    const [user, handleChangeUser] = useState(props.startUser);
    const [status, setStatus] = useState(props.status);
    const { setColumns, columns, pIndex, goalIndex } = props
    const [startDate, setStartDate] = useState(new Date(
        new Date(props.startDate).getFullYear(),
        new Date(props.startDate).getMonth(),
        new Date(props.startDate).getDate()
    ));
    const [endDate, setEndDate] = useState(new Date(
        new Date(props.endDate).getFullYear(),
        new Date(props.endDate).getMonth(),
        new Date(props.endDate).getDate()
    ));
    const [personAccountable, setPersonAccountable] = useInputState(props.personAccountable);
    const [tactic, setTactic] = useState(props.taskTactic)

    const handleClose = (e) => {
        e.preventDefault();
        props.toggle();
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        props.toggle()
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        const data = { status, personAccountable, tactic, start: startDateObj.toISOString(), end: endDateObj.toISOString(), name: quarter }
        const reqBody = JSON.stringify({ status, personAccountable, tactic, start: startDateObj.toISOString(), end: endDateObj.toISOString(), name: quarter })

        const updatedColumns = [...columns];
        let tIndex = updatedColumns[pIndex].goals[goalIndex].tactics?.findIndex((tac) => tac.id === props?.taskId)
        let tacticData = updatedColumns[pIndex].goals[goalIndex].tactics?.find((tac) => tac.id === props?.taskId)
        updatedColumns[pIndex].goals[goalIndex].tactics[tIndex] = { ...tacticData, ...data }
        setColumns(updatedColumns);
        const filterTactic = props?.tactics?.filter((item) => item?.id !== props.taskId)
        props.setTactics([...filterTactic, { ...tacticData, ...data }])
        try {
            const resp = await fetch(`${apiUrl}/ganttest/update/${props.taskId}/${props.pId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: reqBody
                }
            )
            let data = await resp.json();
            if (data.success) {

                toast.success(data?.message)
            } else {
                toast.error("something went wrong")
            }
        } catch (error) {
            toast.error(error.message)
        }
    }
    console.log(props.tactics)
    return (
        <form
            onSubmit={handleSubmit}
            className="KanbanEditForm"
        >
            <div className="KanbanEditForm-input-container">
                <label htmlFor="task" className="KanbanEditForm-label">Tactics: </label>
                <textarea
                    className="KanbanEditForm-input-textarea kanban-input"
                    type="text"
                    cols="15"
                    // rows="5"
                    value={tactic}
                    onChange={(e) => setTactic(e.target.value)}
                    name="task"
                    id="task"
                    required
                ></textarea>
            </div>

            <div className="KanbanEditForm-input-container">
                <label htmlFor="task" className="KanbanEditForm-label">Quarter: </label>
                <select
                    className="form-control KanbanEditForm-input-textarea kanban-input"
                    value={quarter}
                    onChange={(e) => setQuarter(e.target.value)}
                >
                    <option value="Q1">Quarter 1</option>
                    <option value="Q2">Quarter 2</option>
                    <option value="Q3">Quarter 3</option>
                    <option value="Q4">Quarter 4</option>
                </select>
            </div>
            <div className="KanbanEditForm-input-container">
                <label htmlFor="task" className="KanbanEditForm-label">Person Accountable: </label>
                <textarea
                    className="KanbanEditForm-input-textarea kanban-input"
                    type="text"
                    value={personAccountable}
                    onChange={setPersonAccountable}
                    name="person"
                    id="person"
                    required
                ></textarea>
            </div>

            <div className="KanbanEditForm-input-container">
                <label htmlFor="start" className="KanbanEditForm-label">Start Date: </label>
                <input
                    className="kanban-input"
                    type="date"
                    id="start"
                    name="start"
                    value={startDate.toISOString().substring(0, 10)}
                    onChange={(e) => setStartDate(new Date(e.target.value))}
                />
            </div>
            <div className="KanbanEditForm-input-container">
                <label htmlFor="end" className="KanbanEditForm-label">End Date: </label>
                <input
                    className="kanban-input"
                    type="date"
                    id="end"
                    name="end"
                    value={endDate.toISOString().substring(0, 10)}
                    onChange={(e) => setEndDate(new Date(e.target.value))}
                />
            </div>
            <div className=" w-full ">
                <div className="">
                    Status:
                    <select className="status-select KanbanModal-input" value={status} onChange={(e) => setStatus(e.target.value)}>
                        {/* <option value="">Select Option</option> */}
                        <option value="scheduled">Scheduled</option>
                        <option value="onTrack">on Track</option>
                        <option value="completed">completed </option>
                        <option value="wont_be_done">won't be done </option>
                        <option value="delayed">Delayed</option>
                    </select>
                </div>
            </div>
            <div className="w-100 mt-3" style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                    className="btn btn-danger"
                    // style={{ backgroundColor: `${props.color}` }}
                    onClick={handleClose}
                >
                    close
                </button>
                <button
                    className="btn btn-primary"
                // style={{ backgroundColor: `${props.color}` }}
                >
                    Save
                </button>
            </div>

        </form>
    );
};

export default KanbanEditForm;